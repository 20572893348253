import React from "react"

export default () => (
  <>
    {/* TFL Rail */}
    <g id="tfl-rail" className="line">
      {/* <rect
        id="tfl-rail_910gmryland_2_"
        x="863.2"
        y="322.9"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 24.1639 705.7413)"
        fill="#1C3F94"
        width="1.5"
        height="1.6"
        className="blue-fill"
      />
      <rect
        id="tfl-rail_910gmanrpk_2_"
        x="894.7"
        y="291.4"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 55.6705 718.8038)"
        fill="#1C3F94"
        width="1.5"
        height="1.6"
        className="blue-fill"
      />
      <rect
        id="tfl-rail_910gilford_2_"
        x="904.6"
        y="281.6"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 65.534 722.8774)"
        fill="#1C3F94"
        width="1.5"
        height="1.6"
        className="blue-fill"
      />
      <rect
        id="tfl-rail_910gsvnkngs_2_"
        x="913.9"
        y="272.3"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 74.8251 726.726)"
        fill="#1C3F94"
        width="1.5"
        height="1.6"
        className="blue-fill"
      />
      <rect
        id="tfl-rail_910ggodmays_2_"
        x="923.4"
        y="262.8"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 84.3353 730.6578)"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="tfl-rail_910ggideapk_2_"
        x="969.5"
        y="216.6"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 130.4693 749.7701)"
        fill="#1C3F94"
        width="1.5"
        height="1.6"
        className="blue-fill"
      />
      <rect
        id="tfl-rail_910ghrldwod_2_"
        x="979.4"
        y="206.7"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 140.3602 753.8604)"
        fill="#1C3F94"
        width="1.5"
        height="1.6"
        className="blue-fill"
      /> */}
      <path
        id="tfl-rail_910gstfd_910glivst_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M845,340c-38.2,38.2-67.7,67.7-68.4,68.4c-1.2,1.2-3.7,2.3-5.4,2.3c-2.9,0-111.7,0-111.7,0"
      />
      <line
        id="tfl-rail_910gmryland_910gstfd_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="863.2"
        y1="321.8"
        x2="845"
        y2="340"
      />
      <path
        id="tfl-rail_910gfrstgt_910gmryland_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M880.4,304.6c-6.5,6.5-18.3,18.3-18.3,18.3"
      />
      <line
        id="tfl-rail_910gmanrpk_910gfrstgt_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="894.7"
        y1="290.3"
        x2="880.4"
        y2="304.6"
      />
      <line
        id="tfl-rail_910gilford_910gmanrpk_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="904.5"
        y1="280.5"
        x2="893.6"
        y2="291.4"
      />
      <line
        id="tfl-rail_910gsvnkngs_910gilford_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="913.8"
        y1="271.2"
        x2="903.5"
        y2="281.5"
      />
      <line
        id="tfl-rail_910ggodmays_910gsvnkngs_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="923.4"
        y1="261.6"
        x2="912.8"
        y2="272.2"
      />
      <path
        id="tfl-rail_910gchdwlht_910ggodmays_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M939.4,245.6c-6.5,6.5-17.1,17.1-17.1,17.1"
      />
      <path
        id="tfl-rail_910gromford_910gchdwlht_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M954.6,230.4c-4.9,4.9-15.2,15.2-15.2,15.2"
      />
      <line
        id="tfl-rail_910ggideapk_910gromford_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="969.5"
        y1="215.5"
        x2="954.6"
        y2="230.4"
      />
      <path
        id="tfl-rail_910ghrldwod_910ggideapk_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M979.4,205.6c-3.2,3.2-11,11-11,11"
      />
      <line
        id="tfl-rail_910gbrtwood_910ghrldwod_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="1002"
        y1="183"
        x2="978.4"
        y2="206.6"
      />
      <line
        id="tfl-rail_910gshenfld_910gbrtwood_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="1019.7"
        y1="165.3"
        x2="1002"
        y2="183"
      />
      <path
        id="tfl-rail_white_line_2_"
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="0.7783"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M1019.5,165.5C783.1,401.8,783.1,401.8,776.5,408.4c-1.2,1.2-3.7,2.2-5.4,2.2c-2.9,0-111.6,0-111.6,0"
      />
      {/* <rect
        id="tfl-rail_910gactonml_x0a_"
        x="260.1"
        y="417.6"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -219.433 307.0432)"
        fill="#1C3F94"
        width="1.6"
        height="1.6"
        className="blue-fill"
      />
      <rect
        id="tfl-rail_910ghanwell"
        x="172.7"
        y="442.1"
        fill="#1C3F94"
        width="1.6"
        height="1.6"
        className="blue-fill"
      />
      <rect
        id="tfl-rail_910gsthall"
        x="156"
        y="453.7"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -275.459 244.025)"
        fill="#1C3F94"
        width="1.6"
        height="1.6"
        className="blue-fill"
      /> */}
      <line
        id="tfl-rail_910ghtrwapt_910ghtrwtm4"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.3442"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="129.3"
        y1="573.6"
        x2="129.3"
        y2="617.1"
      />
      <path
        id="tfl-rail_910ghayesah_910ghtrwapt"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.3442"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M139.2,469.3l-7.6,7.6c-1.2,1.2-2.3,3.7-2.3,5.4h0c0,1.9,0,91.2,0,91.2"
      />
      <line
        fill="none"
        stroke="#E6E7E8"
        strokeWidth="2.9476"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="127"
        y1="587"
        x2="131.8"
        y2="582.2"
      />
      <line
        id="tfl-rail_910gsthall_910ghayesah"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.3442"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="156"
        y1="452.6"
        x2="139.2"
        y2="469.3"
      />
      <path
        id="tfl-rail_910ghanwell_910gsthall"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.3442"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M174.2,444.8l-7.3,0c-1.8,0-4.2,1-5.4,2.3l-6.6,6.6"
      />
      <line
        id="tfl-rail_910gwealing_910ghanwell"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.3442"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="196.9"
        y1="444.8"
        x2="172.7"
        y2="444.8"
      />
      <path
        id="tfl-rail_910gealingb_910gwealing_xa0_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.3442"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M214.6,444.8c-9,0-17.7,0-17.7,0"
      />
      <path
        id="tfl-rail_910gactonml_910gealingb_xa0_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.3442"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M262.8,419.2c-13,13-23.3,23.3-23.3,23.3c-1.2,1.2-3.7,2.3-5.4,2.3c-0.4,0-10.1,0-19.5,0"
      />
      <path
        id="tfl-rail_910gpadton_910gactonml"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.3442"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M376.4,387.3l-78.4,0c-1.8,0-4.2,1-5.4,2.3c-0.6,0.6-30.8,30.8-30.8,30.8"
      />
      <g id="tfl-rail_white_line">
        <line
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.8042"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          x1="129.3"
          y1="573.6"
          x2="129.3"
          y2="617.1"
        />
        <path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.8042"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d="M139.2,469.3 l-7.6,7.6c-1.2,1.2-2.3,3.7-2.3,5.4h0c0,1.9,0,91.2,0,91.2"
        />
        <line
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.8042"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          x1="156"
          y1="452.6"
          x2="139.2"
          y2="469.3"
        />
        <path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.8042"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d="M174.2,444.8 l-7.3,0c-1.8,0-4.2,1-5.4,2.3l-6.6,6.6"
        />
        <line
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.8042"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          x1="196.9"
          y1="444.8"
          x2="172.7"
          y2="444.8"
        />
        <path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.8042"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d="M214.6,444.8 c-9,0-17.7,0-17.7,0"
        />
        <path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.8042"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d="M262.8,419.2 c-13,13-23.3,23.3-23.3,23.3c-1.2,1.2-3.7,2.3-5.4,2.3c-0.4,0-10.1,0-19.5,0"
        />
        <path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.8042"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d="M376.4,387.3 l-78.4,0c-1.8,0-4.2,1-5.4,2.3c-0.6,0.6-30.8,30.8-30.8,30.8"
        />
      </g>
    </g>
    {/* Northern */}
    <g id="lul-northern" className="line">
      <path
        id="lul-northern_940gzzlueus_940gzzluwrr"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M536.7,391.6v-3.3c0-1.8,1-4.2,2.3-5.4l7.3-7.3c1.2-1.2,2.3-3.6,2.3-5.3"
      />
      <line
        id="lul-northern_940gzzluegw_940gzzlubtk"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="453.5"
        y1="225"
        x2="437.7"
        y2="209.2"
      />
      <line
        id="lul-northern_940gzzlubtk_940gzzlucnd"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="464.4"
        y1="236"
        x2="452.4"
        y2="223.9"
      />
      <line
        id="lul-northern_940gzzlucnd_940gzzluhcl"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="473.6"
        y1="245.1"
        x2="463.4"
        y2="234.9"
      />
      <line
        id="lul-northern_940gzzluhcl_940gzzlubtx"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="486.3"
        y1="257.9"
        x2="473.6"
        y2="245.1"
      />
      <line
        id="lul-northern_940gzzlubtx_940gzzluggn"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="496"
        y1="267.6"
        x2="485.3"
        y2="256.8"
      />
      <line
        id="lul-northern_940gzzluggn_940gzzluhtd"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="507.3"
        y1="278.8"
        x2="496"
        y2="267.6"
      />
      <line
        id="lul-northern_940gzzluhtd_940gzzlubzp"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="530.9"
        y1="302.5"
        x2="506.2"
        y2="277.8"
      />
      <line
        id="lul-northern_940gzzlubzp_940gzzlucfm"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="544.2"
        y1="315.7"
        x2="529.8"
        y2="301.4"
      />
      <path
        id="lul-northern_940gzzlucfm_940gzzluctn"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M553.3,328.1c0-1.8-1-4.2-2.3-5.4c-0.2-0.2-7.9-7.9-7.9-7.9"
      />
      <path
        id="lul-northern_940gzzlumhl_940gzzlufyc"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M568.7,198.1c13.1,13.1,13.2,13.2,14.1,14.1c1.2,1.2,2.3,3.7,2.3,5.4c0,1.4,0,5.7,0,5.7"
      />
      <line
        id="lul-northern_940gzzluhbt_940gzzlutaw"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="585"
        y1="180.2"
        x2="585"
        y2="167.6"
      />
      <line
        id="lul-northern_940gzzlutaw_940gzzluwop"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="585"
        y1="191.3"
        x2="585"
        y2="178.7"
      />
      <line
        id="lul-northern_940gzzluwop_940gzzluwfn"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="585"
        y1="202.9"
        x2="585"
        y2="191.3"
      />
      <line
        id="lul-northern_940gzzluwfn_940gzzlufyc"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="585"
        y1="221.6"
        x2="585"
        y2="202.9"
      />
      <path
        id="lul-northern_940gzzluctn_940gzzlueus"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M553.3,331v3.6c0,1.8,1,4.2,2.3,5.4l0.3,0.3c1.2,1.2,2.3,3.7,2.3,5.4l0,23.8"
      />
      <path
        id="lul-northern_940gzzluksh_940gzzluctn"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M579.7,298.5l-24.1,24.1c-1.2,1.2-2.3,3.7-2.3,5.4v4.4"
      />
      <path
        id="lul-northern_940gzzlutfp_940gzzluksh"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M585,288.4v1.6c0,1.8-1.3,4.5-2.5,5.7l-2.8,2.8"
      />
      <line
        id="lul-northern_940gzzluacy_940gzzlutfp"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="585"
        y1="264"
        x2="585"
        y2="289.9"
      />
      <line
        id="lul-northern_940gzzluhgt_940gzzluacy"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="585"
        y1="246.8"
        x2="585"
        y2="264"
      />
      <line
        id="lul-northern_940gzzluefy_940gzzluhgt"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="585"
        y1="234.3"
        x2="585"
        y2="248.3"
      />
      <line
        id="lul-northern_940gzzlufyc_940gzzluefy"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="585"
        y1="221.6"
        x2="585"
        y2="235.8"
      />
      <path
        id="lul-northern_940gzzluctn_940gzzlumtc"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M548.6,348.6l0-2.8c0-1.8,1-4.2,2.3-5.4l0.3-0.3c1.2-1.2,2.3-3.7,2.3-5.4V331"
      />
      <line
        id="lul-northern_940gzzlumtc_940gzzlueus"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="548.6"
        y1="368.9"
        x2="548.6"
        y2="347.1"
      />
      <path
        id="lul-northern_940gzzlueus_940gzzluksx"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M578,374.1h-12.2c-3.4,0-6.3-2.3-7.3-5.4"
      />
      <line
        id="lul-northern_940gzzluksx_940gzzluagl"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="611.9"
        y1="374.1"
        x2="578"
        y2="374.1"
      />
      <path
        id="lul-northern_940gzzluagl_940gzzluods"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M636.7,386.8l0-4.9c0-2.1-0.9-4.1-2.3-5.5c-1.4-1.4-3.3-2.3-5.5-2.3h-18.5"
      />
      <line
        id="lul-northern_940gzzluods_940gzzlumgt"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="636.7"
        y1="423"
        x2="636.7"
        y2="386.8"
      />
      <line
        id="lul-northern_940gzzlumgt_940gzzlubnk"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="636.7"
        y1="461.9"
        x2="636.7"
        y2="423"
      />
      <line
        id="lul-northern_940gzzlubnk_940gzzlulnb"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="636.7"
        y1="513.4"
        x2="636.7"
        y2="461.9"
      />
      <path
        id="lul-northern_940gzzlulnb_940gzzlubor"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M597.4,562l37.1-37.1c1.2-1.2,2.3-3.7,2.3-5.4l0-6.1"
      />
      <line
        id="lul-northern_940gzzlubor_940gzzlueac"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="549.2"
        y1="610.2"
        x2="598.4"
        y2="561"
      />
      <line
        id="lul-northern_940gzzlueac_940gzzlukng"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="532"
        y1="627.3"
        x2="549.2"
        y2="610.2"
      />
      <line
        id="lul-northern_940gzzlukng_940gzzluovl"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="526"
        y1="633.3"
        x2="535"
        y2="624.3"
      />
      <line
        id="lul-northern_940gzzluovl_940gzzluskw"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="514.5"
        y1="644.9"
        x2="527.1"
        y2="632.3"
      />
      <line
        id="lul-northern_940gzzluskw_940gzzlucpn"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="500.4"
        y1="658.9"
        x2="514.5"
        y2="644.9"
      />
      <line
        id="lul-northern_940gzzlucpn_940gzzlucpc_2_"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="488.2"
        y1="671.1"
        x2="500.4"
        y2="658.9"
      />
      <line
        id="lul-northern_940gzzlucpc_940gzzlucps_2_"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="479.6"
        y1="679.7"
        x2="489.3"
        y2="670.1"
      />
      <line
        id="lul-northern_940gzzlucps_940gzzlublm_2_"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="470.8"
        y1="688.6"
        x2="480.7"
        y2="678.7"
      />
      <line
        id="lul-northern_940gzzlublm_940gzzlutbc_2_"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="462.4"
        y1="697"
        x2="470.7"
        y2="688.7"
      />
      <line
        id="lul-northern_940gzzlutbc_940gzzlutby_2_"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="453.6"
        y1="705.8"
        x2="463.5"
        y2="695.9"
      />
      <line
        id="lul-northern_940gzzlutby_940gzzlucsd_2_"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="444.9"
        y1="714.4"
        x2="454.7"
        y2="704.7"
      />
      <line
        id="lul-northern_940gzzlucsd_940gzzluswn_2_"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="436.3"
        y1="723.1"
        x2="446"
        y2="713.4"
      />
      <line
        id="lul-northern_940gzzluswn_940gzzlumdn_2_"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="412.4"
        y1="746.9"
        x2="437.3"
        y2="722"
      />
      <line
        id="lul-northern_940gzzluwrr_940gzzlugdg"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="536.7"
        y1="412.7"
        x2="536.7"
        y2="391.6"
      />
      <line
        id="lul-northern_940gzzlugdg_940gzzlutcr"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="536.7"
        y1="426.7"
        x2="536.7"
        y2="411.3"
      />
      <line
        id="lul-northern_940gzzlutcr_940gzzlulsq"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="536.7"
        y1="457.4"
        x2="536.7"
        y2="426.7"
      />
      <line
        id="lul-northern_940gzzlulsq_940gzzluchx"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="536.7"
        y1="486"
        x2="536.7"
        y2="457.4"
      />
      <line
        id="lul-northern_940gzzluchx_940gzzluemb"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="536.7"
        y1="505.1"
        x2="536.7"
        y2="486"
      />
      <line
        id="lul-northern_940gzzluemb_940gzzluwlo"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="536.7"
        y1="535.8"
        x2="536.7"
        y2="505.1"
      />
      <path
        id="lul-northern_940gzzluwlo_940gzzlukng"
        fill="none"
        stroke="#000000"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M536.7,535.8l0.1,84.2c0,1.4-0.8,3.3-1.7,4.2c-0.2,0.2-1.1,1.1-1.1,1.1"
      />
      {/* <rect
        id="lul-northern_940gzzlumhl"
        x="566"
        y="197.4"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 26.4764 460.1843)"
        width="5.4"
        height="1.5"
      />
      <polyline
        id="lul-northern_940gzzlutaw"
        points="587.1,178.7 587.6,178.7 587.6,180.2 586.1,180.2 586.1,178.7 587.1,178.7 "
      />
      <rect
        id="lul-northern_940gzzluefy"
        x="586.1"
        y="234.3"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzluhgt"
        x="586.1"
        y="246.8"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzlutfp"
        x="586.1"
        y="288.4"
        width="1.5"
        height="1.5"
      />
      <polyline
        id="lul-northern_940gzzlubtk"
        points="451.2,225.1 451.6,224.7 452.7,225.8 451.6,226.8 450.6,225.8 451.2,225.1 "
      />
      <polyline
        id="lul-northern_940gzzlucnd"
        points="462.2,236.1 462.6,235.7 463.7,236.7 462.6,237.8 461.5,236.7 462.2,236.1 "
      />
      <polyline
        id="lul-northern_940gzzlubtx"
        points="484.1,258 484.5,257.6 485.6,258.7 484.5,259.7 483.4,258.7 484.1,258 "
      />
      <polyline
        id="lul-northern_940gzzluhtd"
        points="505,279 505.4,278.6 506.5,279.6 505.4,280.7 504.4,279.6 505,279 "
      />
      <polyline
        id="lul-northern_940gzzlubzp"
        points="528.7,302.6 529.1,302.2 530.1,303.3 529.1,304.3 528,303.3 528.7,302.6 "
      />
      <polyline
        id="lul-northern_940gzzlucfm"
        points="541.9,315.9 542.3,315.5 543.4,316.5 542.3,317.6 541.3,316.5 541.9,315.9 "
      />
      <rect
        id="lul-northern_940gzzluctn"
        x="550.7"
        y="331"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzlumtc"
        x="545.9"
        y="347.1"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzlugdg"
        x="537.9"
        y="411.3"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzlubor"
        x="598.5"
        y="562.1"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -222.4562 588.5145)"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzluagl"
        x="610.4"
        y="375.2"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzlukng"
        x="535.1"
        y="625.4"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -285.8417 562.2389)"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzluovl"
        x="527.1"
        y="633.4"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -293.789 558.9876)"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzlucpc"
        x="486.7"
        y="668.5"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -330.4763 540.7175)"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzlucps"
        x="478.1"
        y="677.1"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -339.094 537.1474)"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzlutbc"
        x="463.5"
        y="697"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -357.402 532.5894)"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzlutby"
        x="454.7"
        y="705.8"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -366.1924 528.9883)"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzlucsd"
        x="446"
        y="714.5"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -374.8713 525.3951)"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-northern_940gzzluswn"
        x="437.4"
        y="723.2"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -383.5593 521.8141)"
        width="1.5"
        height="1.5"
      /> */}
    </g>
    // Overground
    <g id="raillo-overground" className="line">
      <line
        id="raillo-overground_910ggosplok_910guprhlwy"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="594.3"
        y1="273.2"
        x2="555.1"
        y2="273.2"
      />
      <path
        id="raillo-overground_910guprhlwy_910gcrouchh"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M633.7,258.1l-10.6,10.6l-2.3,2.3c-1.2,1.2-3.7,2.3-5.4,2.3h-21.1"
      />
      <path
        id="raillo-overground_910gcrouchh_910ghrgygl"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M696.3,245.1h-46.4c-1.8,0-4.2,1-5.4,2.3l-11.8,11.8"
      />
      <line
        id="raillo-overground_910ghrgygl_910gstotnhm"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="735.1"
        y1="245.1"
        x2="696.3"
        y2="245.1"
      />
      <path
        id="raillo-overground_910gstotnhm_910gblchsrd"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M761.9,263.8L746,247.3c-1.2-1.2-3.7-2.3-5.4-2.3h-5.5"
      />
      <line
        id="raillo-overground_910gblchsrd_910gwlthqrd"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="777.5"
        y1="279.9"
        x2="761.9"
        y2="263.8"
      />
      <path
        id="raillo-overground_910gwlthqrd_910gleytnmr"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M805.9,294.5h-11.2c-1.7,0-4.2-1-5.4-2.3l-11.8-12.3"
      />
      <line
        id="raillo-overground_910gleytnmr_910glytnshr"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="828.4"
        y1="294.5"
        x2="804.4"
        y2="294.5"
      />
      <line
        id="raillo-overground_910glytnshr_910gwnstdpk"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="870.2"
        y1="294.5"
        x2="826.9"
        y2="294.5"
      />
      <path
        id="raillo-overground_910gwnstdpk_910gwdgrnpk"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M899.6,313.6l-16.8-16.8c-1.2-1.2-3.7-2.3-5.4-2.3h-7.2"
      />
      <path
        id="raillo-overground_910gwdgrnpk_910gbarking"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M903.7,351.7l0-30.9c0-1.8-1-4.2-2.3-5.4l-3.1-3.1"
      />
      <path
        id="raillo-overground_910gdalskld_910ghacknyc"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M753.1,339.9h-22.2c-1.8,0-4.2-1-5.4-2.3l-10.9-10.9c-1.7-1.7-4.1-2.8-6.8-2.8h-1.5"
      />
      <path
        id="raillo-overground_910gshmpstd_910geuston"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M409.3,358.9l117.4,0c1.8,0,4.2,1,5.4,2.3c1.2,1.2,7.8,7.8,7.8,7.8"
      />
      <line
        id="raillo-overground_910gklbrnhr_910gshmpstd"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="371.2"
        y1="358.9"
        x2="410.8"
        y2="358.9"
      />
      <path
        id="raillo-overground_910gqprk_910gklbrnhr"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M321.7,348.8l0,3.5v1.2c0,1.3,0.6,2.9,1.6,3.7l0,0l0,0c0.9,0.9,2.5,1.6,3.7,1.6l45.7,0"
      />
      <line
        id="raillo-overground_910gkenslg_910gqprk"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="338.6"
        x2="321.7"
        y2="348.8"
      />
      <line
        id="raillo-overground_910gwlsdjhl_910gkenslg"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="329.1"
        x2="321.7"
        y2="340.1"
      />
      <line
        id="raillo-overground_910gharlsdn_910gwlsdjhl"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="314.6"
        x2="321.7"
        y2="329.1"
      />
      <line
        id="raillo-overground_910gstnbgpk_910gharlsdn"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="305.5"
        x2="321.7"
        y2="316.1"
      />
      <line
        id="raillo-overground_910gwmby_910gstnbgpk"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="297"
        x2="321.7"
        y2="307"
      />
      <line
        id="raillo-overground_910gnwembly_910gwmby"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="287.2"
        x2="321.7"
        y2="297"
      />
      <line
        id="raillo-overground_910gskenton_910gnwembly"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="277.7"
        x2="321.7"
        y2="288.7"
      />
      <line
        id="raillo-overground_910gkton_910gskenton"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="241"
        x2="321.7"
        y2="279.2"
      />
      <line
        id="raillo-overground_910ghrow_910gkton"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="226.4"
        x2="321.7"
        y2="242.5"
      />
      <line
        id="raillo-overground_910ghedstnl_910ghrow"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="214.7"
        x2="321.7"
        y2="226.4"
      />
      <line
        id="raillo-overground_910ghtchend_910ghedstnl"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="201.5"
        x2="321.7"
        y2="216.2"
      />
      <line
        id="raillo-overground_910gcrpndpk_910ghtchend"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="189.1"
        x2="321.7"
        y2="203"
      />
      <line
        id="raillo-overground_910gbushey_910gcrpndpk"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="175.8"
        x2="321.7"
        y2="189.1"
      />
      <line
        id="raillo-overground_910gwatfdhs_910gbushey"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="160.3"
        x2="321.7"
        y2="175.8"
      />
      <line
        id="raillo-overground_910gwatfdj_910gwatfdhs"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="321.7"
        y1="147.7"
        x2="321.7"
        y2="161.8"
      />
      <line
        id="raillo-overground_910gcnnb_910gdalskld"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="707.8"
        y1="323.9"
        x2="691.7"
        y2="323.9"
      />
      <line
        id="raillo-overground_910grichmnd_910gkewgrdn"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="252.6"
        y1="564.7"
        x2="252.6"
        y2="578"
      />
      <line
        id="raillo-overground_910gkewgrdn_910ggnrsbry"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="252.6"
        y1="537.5"
        x2="252.6"
        y2="564.7"
      />
      <path
        id="raillo-overground_910ggnrsbry_910gsacton"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M271.4,488.1v6.8c0,1.8-1,4.2-2.3,5.4l-13.6,13.6l0,0c-1.8,1.8-3,4.4-3,7.2c0,1,0,16.4,0,16.4"
      />
      <line
        id="raillo-overground_910gsacton_910gactnctl"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="271.4"
        y1="475"
        x2="271.4"
        y2="488.1"
      />
      <path
        id="raillo-overground_910gactnctl_910gwlsdjhl"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2706"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M320.7,329.1H296c-1.8,0-4.2,1-5.4,2.3l-16.9,16.9c-1.2,1.2-2.3,3.7-2.3,5.4V475"
      />
      <line
        id="raillo-overground_910gwlsdjhl_910gkenr"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="343.7"
        y1="329.1"
        x2="320.7"
        y2="329.1"
      />
      <line
        id="raillo-overground_910gkenr_910gbrbypk"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="373.6"
        y1="329.1"
        x2="342.2"
        y2="329.1"
      />
      <line
        id="raillo-overground_910gbrbypk_910gbrby"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="400.1"
        y1="329.1"
        x2="372.1"
        y2="329.1"
      />
      <line
        id="raillo-overground_910gbrby_910gwhmdstd"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="439.9"
        y1="329.1"
        x2="398.6"
        y2="329.1"
      />
      <path
        id="raillo-overground_910gwhmdstd_910gfnchlyr"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M492.9,310.2l-16.6,16.6c-1.2,1.2-3.7,2.3-5.4,2.3h-31"
      />
      <path
        id="raillo-overground_910gfnchlyr_910ghmpstdh"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M525.7,283.1h-2.5c-1.8,0-4.2,1-5.4,2.3l-26,26"
      />
      <line
        id="raillo-overground_910ghmpstdh_910ggosplok"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="546.6"
        y1="283.1"
        x2="525.7"
        y2="283.1"
      />
      <path
        id="raillo-overground_910ggosplok_910gkntshtw"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M566.4,296l-10.6-10.6c-1.2-1.2-3.7-2.3-5.4-2.3h-3.8"
      />
      <line
        id="raillo-overground_910gkntshtw_910gcmdnrd"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="585.6"
        y1="315.1"
        x2="565.4"
        y2="294.9"
      />
      <path
        id="raillo-overground_910gcmdnrd_910gcldnnrb"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M647.9,323.9h-50.3c-1.8,0-4.2-1-5.4-2.3l-6.6-6.6"
      />
      <line
        id="raillo-overground_910gcldnnrb_910ghghi"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="667.1"
        y1="323.9"
        x2="647.9"
        y2="323.9"
      />
      <line
        id="raillo-overground_910ghghi_910gcnnb"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="691.7"
        y1="323.9"
        x2="667.1"
        y2="323.9"
      />
      <line
        id="raillo-overground_910ghacknyc_910ghomrton"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="781.6"
        y1="339.9"
        x2="753.1"
        y2="339.9"
      />
      <line
        id="raillo-overground_910ghomrton_910ghacknyw"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="810.4"
        y1="339.9"
        x2="781.6"
        y2="339.9"
      />
      <line
        id="raillo-overground_910ghacknyw_910gstfd"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="835.6"
        y1="339.9"
        x2="810.4"
        y2="339.9"
      />
      <line
        id="raillo-overground_910gpckhmqd_910gpckhmry"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="644.6"
        y1="629.2"
        x2="666.2"
        y2="607.5"
      />
      <path
        id="raillo-overground_910gpckhmry_910gdenmrkh"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M579.8,655.9c0,0,33.3,0,34.8,0c2.1,0,4.1-0.9,5.5-2.3l24.5-24.5"
      />
      <path
        id="raillo-overground_910gdenmrkh_910gclphhs"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M500.5,648.4l5.3,5.3c1.5,1.5,3.5,2.3,5.5,2.3l68.5,0"
      />
      <path
        id="raillo-overground_910gclphhs_910gwndswrd"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M462,629.2l16.2,0c2,0,4,0.8,5.5,2.3c1.8,1.8,10.9,10.9,16.8,16.8"
      />
      <path
        id="raillo-overground_910gwndswrd_910gclphmj1"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M424.9,629.2c2.6,0,38.5,0,38.5,0"
      />
      <path
        id="raillo-overground_910gclphmj1_910gcseah"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M401.3,586.5l14.6,14.6c1.2,1.2,2.3,4.5,2.3,6.3c0,2.7,0,14,0,14"
      />
      <path
        id="raillo-overground_910gcseah_910gwbrmptn"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M369.4,542.7v8.7c0,1.8,1,4.2,2.3,5.4l29.6,29.6"
      />
      <line
        id="raillo-overground_910gwbrmptn_910gkenolym"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="369.4"
        y1="482.4"
        x2="369.4"
        y2="542.7"
      />
      <path
        id="raillo-overground_910gkenolym_910gshpdsb"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M355.1,459.2l12.2,12.2c1.2,1.2,2.2,3.6,2.2,5.3v5.6"
      />
      <path
        id="raillo-overground_910gshpdsb_910gwlsdjhl"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M320.7,329.1H296c-1.8,0-4.2,1-5.4,2.3l-3,3c-1.2,1.2-2.3,3.7-2.3,5.4l-0.1,45.4c0,2.3,0.6,4.9,2.2,6.5l67.6,67.6"
      />
      <path
        id="raillo-overground_910gemrspkh_910gupmnstr"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M974.6,250.2l10.8,10.8c1.2,1.2,3.7,2.3,5.4,2.3c2.5,0,5.7,0,5.7,0"
      />
      <line
        id="raillo-overground_910gromford_910gemrspkh"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="954.9"
        y1="230.5"
        x2="974.6"
        y2="250.2"
      />
      {/* <rect
        id="raillo-overground_910gwatfdhs"
        x="322.8"
        y="160.3"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910ghtchend"
        x="322.8"
        y="201.5"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910ghedstnl"
        x="322.8"
        y="214.7"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <g id="lul-bakerloo_940gzzluken_raillo-overground_910gkton">
        <rect
          id="lul-bakerloo_940gzzluken_2_"
          x="320.5"
          y="241"
          fill="#B06010"
          width="1.5"
          height="1.5"
        />
        <g id="raillo-overground_910gkton">
          <rect
            id="raillo-overground_910gkton"
            x="322.8"
            y="241"
            fill="#F58025"
            width="1.5"
            height="1.5"
          />
        </g>
      </g>
      <rect
        id="raillo-overground_910gklbrnhr_2_"
        x="371.2"
        y="356.3"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gshmpstd_2_"
        x="409.3"
        y="356.3"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gbrbypk"
        x="372.1"
        y="326.4"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gbrby"
        x="398.6"
        y="330.1"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gfnchlyr"
        x="490.3"
        y="308.7"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -74.9959 437.807)"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gkntshtw"
        x="563.8"
        y="296"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -44.4571 486.132)"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gdalskld"
        x="706.3"
        y="321.3"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gwndswrd"
        x="462"
        y="630.4"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gleytnmr"
        x="804.4"
        y="291.8"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910glytnshr"
        x="826.9"
        y="295.6"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gwdgrnpk"
        x="899.4"
        y="310.7"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 43.4011 727.7626)"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gcrouchh"
        x="631.1"
        y="256.5"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 3.1382 522.1234)"
        fill="#F58025"
        width="1.5"
        height="1.5"
      /> */}
      <path
        id="raillo-overground_910gsurreyq_910gpckhmqd_2_"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2706"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M723.9,556.2c0,0,0,4.9,0,6.9c0,2-0.8,4-2.3,5.5c-1.4,1.4-4.8,4.8-30.2,30.2c-1.4,1.4-3.3,2.3-5.5,2.3H676c-2.1,0-4.1,0.9-5.5,2.3 c-1,1-4.3,4.3-4.3,4.3"
      />
      <path
        id="raillo-overground_910gsurreyq_910gnwcrell"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M723.9,556.2c0,0,0,4.9,0,6.9c0,2,0.8,4,2.3,5.5c1.4,1.4,13.5,13.5,38.8,38.8"
      />
      <path
        id="raillo-overground_white_line_7_"
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="0.7795"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M321.7,147.7l0,205.8c0,1.3,0.6,2.9,1.6,3.7l0,0l0,0c0.9,0.9,2.5,1.6,3.7,1.6l199.7,0c1.8,0,4.2,1,5.4,2.3c1.2,1.2,7.8,7.8,7.8,7.8 "
      />
      <g id="raillo-overground_white_line_14_">
        <path
          id="raillo-overground_white_line_8_"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M903.7,351.7l0-30.9c0-1.8-1-4.2-2.3-5.4l-18.7-18.7c-1.2-1.2-3.7-2.3-5.4-2.3h-82.6c-1.7,0-4.2-1-5.4-2.3L746,247.3 c-1.2-1.2-3.7-2.3-5.4-2.3h-90.7c-1.8,0-4.2,1-5.4,2.3L620.8,271c-1.2,1.2-3.7,2.3-5.4,2.3h-60.3"
        />
        <path
          id="raillo-overground_white_line_6_"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M835.6,339.9H730.9c-1.8,0-4.2-1-5.4-2.3l-10.9-10.9c-1.7-1.7-4.1-2.8-6.8-2.8H597.6c-1.8,0-4.2-1-5.4-2.3l-36.4-36.4 c-1.2-1.2-3.7-2.3-5.4-2.3l-27.1,0c-1.8,0-4.2,1-5.4,2.3l-41.5,41.5c-1.2,1.2-3.7,2.3-5.4,2.3H296c-1.8,0-4.2,1-5.4,2.3 l-16.9,16.9c-1.2,1.2-2.3,3.7-2.3,5.4v141.3c0,1.8-1,4.2-2.3,5.4l-13.6,13.6l0,0c-1.8,1.8-3,4.4-3,7.2c0,1,0,56.9,0,56.9"
        />
        <path
          id="raillo-overground_white_line_5_"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M287.5,334.3c-1.2,1.2-2.3,3.7-2.3,5.4l-0.1,45.4c0,2.3,0.6,4.9,2.2,6.5l79.8,79.8c1.2,1.2,2.2,3.6,2.2,5.3v65.9v8.7 c0,1.8,1,4.2,2.3,5.4l44.2,44.2c1.2,1.2,2.3,4.5,2.3,6.3c0,2.7,0,14,0,14"
        />
      </g>
      <path
        id="raillo-overground_910gsbury_910gedmngrn"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M724.5,174.1l-8.1,8.1c-1.2,1.2-2.3,3.7-2.3,5.4c0,1.6,0,6.2,0,6.2"
      />
      <line
        id="raillo-overground_910gturkyst_910gsbury"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="737.5"
        y1="161.1"
        x2="723.4"
        y2="175.1"
      />
      <line
        id="raillo-overground_910gthbldsg_910gturkyst"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="749.9"
        y1="148.7"
        x2="736.4"
        y2="162.2"
      />
      <line
        id="raillo-overground_910gcheshnt_910gthbldsg"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="760.9"
        y1="137.6"
        x2="748.8"
        y2="149.7"
      />
      {/* <rect
        id="raillo-overground_910gbthnlgr"
        x="736"
        y="372.1"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -47.909 630.1472)"
        fill="#F58025"
        width="1.5"
        height="1.6"
      />
      <rect
        id="raillo-overground_910gcamhth"
        x="745.3"
        y="362.7"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -38.5087 634.0317)"
        fill="#F58025"
        width="1.5"
        height="1.6"
      />
      <rect
        id="raillo-overground_910glonflds"
        x="749.1"
        y="354.3"
        fill="#F58025"
        width="1.6"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gwdst"
        x="787.9"
        y="228.5"
        fill="#F58025"
        width="1.6"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gbrucgrv"
        x="715.2"
        y="228.3"
        fill="#F58025"
        width="1.6"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gwhhrtla"
        x="715.2"
        y="217.1"
        fill="#F58025"
        width="1.6"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gsivrst"
        x="715.2"
        y="205"
        fill="#F58025"
        width="1.6"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gstmfdhl"
        x="715.2"
        y="279.7"
        fill="#F58025"
        width="1.6"
        height="1.5"
      /> */}
      <path
        id="raillo-overground_910genfldtn_910gbhillpk"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M714.1,162.9c0-9.6,0-15.6,0-15.6"
      />
      <path
        id="raillo-overground_910gbhillpk_910gedmngrn"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M714.1,194.1c0-12,0-22.9,0-31.2"
      />
      <path
        id="raillo-overground_910gedmngrn_910gsivrst"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M714.1,206.5c0,0,0-8.2,0-12.4"
      />
      <line
        id="raillo-overground_910gsivrst_910gwhhrtla"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="714.1"
        y1="218.6"
        x2="714.1"
        y2="205"
      />
      <line
        id="raillo-overground_910gwhhrtla_910gbrucgrv"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="714.1"
        y1="229.8"
        x2="714.1"
        y2="217.1"
      />
      <path
        id="raillo-overground_910gbrucgrv_910gsevnsis"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M714.1,263.6c0-15.8,0-35.3,0-35.3"
      />
      <path
        id="raillo-overground_910gsevnsis_910gstmfdhl"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M714.1,281.2c0,0,0-10.3,0-17.6"
      />
      <path
        id="raillo-overground_910gstmfdhl_910gstknwng"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M725.1,298.4l-8.8-8.8c-1.2-1.2-2.3-3.7-2.3-5.4c0-0.2,0-4.5,0-4.5"
      />
      <line
        id="raillo-overground_910gstknwng_910grctryrd"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="738.7"
        y1="312"
        x2="724.1"
        y2="297.3"
      />
      <path
        id="raillo-overground_910grctryrd_910ghaknynm"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M747.9,328.6c0-2.6,0-4.2,0-4.2c0-1.8-1-4.2-2.3-5.4l-8-8"
      />
      <line
        id="raillo-overground_910ghaknynm_910glonflds"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="747.9"
        y1="355.8"
        x2="747.9"
        y2="330.6"
      />
      <path
        id="raillo-overground_910glonflds_910gcamhth_2_"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M744.2,362.7l1.5-1.5c1.2-1.2,2.3-3.7,2.3-5.4c0-0.3,0-1.5,0-1.5"
      />
      <line
        id="raillo-overground_910gcamhth_910gbthnlgr"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="734.8"
        y1="372.1"
        x2="745.2"
        y2="361.7"
      />
      <path
        id="raillo-overground_910gbthnlgr_910glivst"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M659.6,412.2l23-23.1c3.1-3.1,6.3-4.6,10.4-4.6h26.1c1.8,0,4.2-1,5.4-2.3l11.2-11.2"
      />
      <path
        id="raillo-overground_910gchingfd_910ghghmspk"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M786.8,204.9c0-13.9,0-24.2,0-24.2"
      />
      <line
        id="raillo-overground_910ghghmspk_910gwdst"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="786.8"
        y1="230"
        x2="786.8"
        y2="204.9"
      />
      <line
        id="raillo-overground_910gwdst_910gwltwcen"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="786.8"
        y1="268.9"
        x2="786.8"
        y2="228.5"
      />
      <path
        id="raillo-overground_910gwltwcen_910gstjmsst"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M771.7,297.5l12.9-12.9c1.2-1.2,2.3-3.7,2.3-5.4c0-0.5,0-10.2,0-10.2"
      />
      <line
        id="raillo-overground_910gstjmsst_910gclapton"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="763.2"
        y1="306"
        x2="772.8"
        y2="296.3"
      />
      <path
        id="raillo-overground_910gclapton_910ghaknynm"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M764.3,304.9l-13.9,13.9c-1.4,1.4-2.4,3.8-2.4,5.6c0,1.5,0,6.2,0,6.2"
      />
      {/* <rect
        id="raillo-overground_910gsbury"
        x="724.6"
        y="175.2"
        transform="matrix(0.707 -0.7072 0.7072 0.707 88.0782 564.4648)"
        fill="#F58025"
        width="1.5"
        height="1.6"
      />
      <rect
        id="raillo-overground_910gturkyst"
        x="737.5"
        y="162.2"
        transform="matrix(0.707 -0.7072 0.7072 0.707 101.0484 569.8392)"
        fill="#F58025"
        width="1.5"
        height="1.6"
      />
      <rect
        id="raillo-overground_910gthbldsg"
        x="750"
        y="149.7"
        transform="matrix(0.707 -0.7072 0.7072 0.707 113.4861 574.9827)"
        fill="#F58025"
        width="1.5"
        height="1.6"
      />
      <rect
        id="raillo-overground_910gclapton"
        x="764.3"
        y="306"
        transform="matrix(0.707 -0.7072 0.7072 0.707 7.1738 630.911)"
        fill="#F58025"
        width="1.5"
        height="1.6"
      />
      <rect
        id="raillo-overground_910gstjmsst"
        x="772.8"
        y="297.5"
        transform="matrix(0.707 -0.7072 0.7072 0.707 15.7345 634.463)"
        fill="#F58025"
        width="1.5"
        height="1.6"
      />
      <rect
        id="raillo-overground_910grctryrd"
        x="738.8"
        y="309.3"
        transform="matrix(0.707 -0.7072 0.7072 0.707 -2.6298 613.8484)"
        fill="#F58025"
        width="1.6"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gstknwng"
        x="725.2"
        y="295.8"
        transform="matrix(0.707 -0.7072 0.7072 0.707 2.9835 600.245)"
        fill="#F58025"
        width="1.6"
        height="1.5"
      /> */}
      <g id="raillo-overground_white_line_15_">
        <path
          id="raillo-overground_white_line_4_"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M954.9,230.5l30.5,30.5c1.2,1.2,3.7,2.3,5.4,2.3c2.5,0,5.7,0,5.7,0"
        />
        <g id="raillo-overground_white_line_10_">
          <path
            id="raillo-overground_white_line_13_"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="0.7795"
            strokeLinejoin="round"
            strokeMiterlimit="3.9938"
            d=" M760.9,137.6l-44.6,44.6c-1.2,1.2-2.3,3.7-2.3,5.4"
          />
          <path
            id="raillo-overground_white_line_12_"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="0.7795"
            strokeLinejoin="round"
            strokeMiterlimit="3.9938"
            d=" M659.6,412.2l23-23.1c3.1-3.1,6.3-4.6,10.4-4.6h26.1c1.8,0,4.2-1,5.4-2.3l21-21.1c1.2-1.2,2.3-3.7,2.3-5.4c0-0.3,0,0,0,0v-31.4 c0-1.8-1-4.2-2.3-5.4l-29.3-29.3c-1.2-1.2-2.3-3.7-2.3-5.4c0-0.2,0-136.9,0-136.9"
          />
          <path
            id="raillo-overground_white_line_11_"
            fill="none"
            stroke="#FFFFFF"
            strokeWidth="0.7795"
            strokeLinejoin="round"
            strokeMiterlimit="3.9938"
            d=" M747.9,324.4c0-1.8,1-4.2,2.4-5.6l34.2-34.2c1.2-1.2,2.3-3.7,2.3-5.4c0-0.5,0-98.5,0-98.5"
          />
        </g>
      </g>
      <path
        id="raillo-overground_910gsydenhm_910gcrystlp"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M700.7,688.8c0,0,13.4-13.4,20.9-20.9c1.5-1.5,2.3-3.5,2.3-5.5c0-1.6,0-4.1,0-4.1"
      />
      <line
        id="raillo-overground_910gnorwdj_910gwcroydn"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="723.9"
        y1="691.1"
        x2="723.9"
        y2="717.1"
      />
      <line
        id="raillo-overground_910ganerley_910gnorwdj"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="723.9"
        y1="680.8"
        x2="723.9"
        y2="691.1"
      />
      <line
        id="raillo-overground_910gpenew_910ganerley"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="723.9"
        y1="670.1"
        x2="723.9"
        y2="681"
      />
      <line
        id="raillo-overground_910gsydenhm_910gpenew"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="723.9"
        y1="658.2"
        x2="723.9"
        y2="671.6"
      />
      <line
        id="raillo-overground_910gforesth_910gsydenhm"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="723.9"
        y1="646.5"
        x2="723.9"
        y2="658.2"
      />
      <line
        id="raillo-overground_910ghonropk_910gforesth"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="723.9"
        y1="633.7"
        x2="723.9"
        y2="646.5"
      />
      <line
        id="raillo-overground_910gbrockly_910ghonropk"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="723.9"
        y1="621.7"
        x2="723.9"
        y2="633.7"
      />
      <line
        id="raillo-overground_910gnewxgte_910gbrockly"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="723.9"
        y1="607.2"
        x2="723.9"
        y2="621.7"
      />
      <line
        id="raillo-overground_910ghghi_910gcnnb"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="691.7"
        y1="330.8"
        x2="673.5"
        y2="330.8"
      />
      <path
        id="raillo-overground_910gcnnb_910gdals"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M716.6,342.1l-10.1-10.1c-0.8-0.8-1.9-1.3-3.1-1.3c-1,0-5.5,0-11.7,0"
      />
      <path
        id="raillo-overground_910gdals_910ghaggers"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M723.9,360.5c0,0,0-5.3,0-8c0-1.8-1-4.2-2.3-5.4l-5-5"
      />
      <path
        id="raillo-overground_910ghaggers_910ghoxton"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M723.9,375.4c0-4.9,0-9.8,0-14.9"
      />
      <path
        id="raillo-overground_910ghoxton_910gshrdhst"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M723.9,400.6c0-8.1,0-16.4,0-25.2"
      />
      <path
        id="raillo-overground_910gshrdhst_910gwchapel"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M723.9,418.9c0-5.5,0-12.4,0-18.3"
      />
      <path
        id="raillo-overground_910gwchapel_910gshadwel"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M723.9,473.6c0-13.1,0-39,0-54.8"
      />
      <line
        id="raillo-overground_910gshadwel_910gwapping"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="723.9"
        y1="487"
        x2="723.9"
        y2="473.6"
      />
      <path
        id="raillo-overground_910gwapping_910grtherhi"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M723.9,504.1c0,0,0-18.6,0-18.6"
      />
      <path
        id="raillo-overground_910grtherhi_910gcndaw"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M723.9,513.6c0,0,0-10.9,0-10.9"
      />
      <path
        id="raillo-overground_910gcndaw_910gsurreyq"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M723.9,557.7c0-7.5,0-34.7,0-44.1"
      />
      {/* <rect
        id="raillo-overground_910gwapping"
        x="725"
        y="485.5"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910grtherhi"
        x="721.3"
        y="502.6"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gsurreyq"
        x="721.3"
        y="556.2"
        fill="#F58025"
        width="1.5"
        height="1.5"
      />
      <rect
        id="raillo-overground_910gpenew_2_"
        x="725"
        y="670.1"
        fill="#F58025"
        width="1.5"
        height="1.5"
      /> */}
      <line
        id="raillo-overground_910gsurreyq_910gnewxgte"
        fill="none"
        stroke="#F58025"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="723.9"
        y1="556.2"
        x2="723.9"
        y2="607.2"
      />
      <g id="raillo-overground_white_line_9_">
        <path
          id="raillo-overground_white_line_3_"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M700.7,688.8c0,0,19.2-19.2,20.9-20.9c1.5-1.5,2.3-3.5,2.3-5.5c0-1.6,0-4.1,0-4.1"
        />
        <path
          id="raillo-overground_white_line_2_"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M723.9,717.1c0,0,0-361.8,0-364.5c0-1.8-1-4.2-2.3-5.4l-15.2-15.1c-0.8-0.8-1.9-1.3-3.1-1.3c-1,0-30,0-30,0"
        />
        <path
          id="raillo-overground_white_line"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M723.9,563.1c0,2-0.8,4-2.3,5.5c-1.4,1.4-4.8,4.8-30.2,30.2c-1.4,1.4-3.3,2.3-5.5,2.3H676c-2.1,0-4.1,0.9-5.5,2.3 c-1,1-50.4,50.4-50.4,50.4c-1.4,1.4-3.3,2.3-5.5,2.3c-1.5,0-103.3,0-103.3,0c-2,0-4-0.8-5.5-2.3c0,0-20.3-20.3-22.1-22.1 c-1.5-1.5-3.5-2.3-5.5-2.3l-53.3,0"
        />
        <path
          id="raillo-overground_white_line"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M723.9,563.1c0,2,0.8,4,2.3,5.5c1.4,1.4,13.5,13.5,38.8,38.8"
        />
      </g>
      <g id="lul-bakerloo_940gzzluksl_raillo-overground_910gkensig">
        {/* <rect
          id="lul-bakerloo_940gzzluksl"
          x="320.5"
          y="338.6"
          fill="#B06010"
          width="1.5"
          height="1.5"
        />
        <g id="raillo-overground-910gkensig">
          <rect
            id="raillo-overground-910gkensig"
            x="322.8"
            y="338.6"
            fill="#F58025"
            width="1.5"
            height="1.5"
          />
        </g> */}
      </g>
    </g>
    {/* Dlr */}
    <g id="dlr-dlr" className="line">
      <path
        id="dlr-dlr_940gzzdlwfe_940gzzdlwiq_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M795.5,489.8c0,0,0-8.5,0-10.2c0-1.8-1-4.2-2.3-5.4l-4.6-4.6c-1.2-1.2-3.5-2.3-5.1-2.3c-1.7,0-10.9,0-10.9,0"
      />
      <line
        id="dlr-dlr_940gzzdlgal_940gzzdlbec_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="991.7"
        y1="541"
        x2="1003.6"
        y2="552.9"
      />
      <line
        id="dlr-dlr_940gzzdlcyp_940gzzdlgal_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="979"
        y1="528.2"
        x2="991.7"
        y2="541"
      />
      <line
        id="dlr-dlr_940gzzdlbpk_940gzzdlcyp_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="966.3"
        y1="515.6"
        x2="979"
        y2="528.2"
      />
      <line
        id="dlr-dlr_940gzzdlral_940gzzdlbpk_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="953.7"
        y1="502.9"
        x2="966.3"
        y2="515.6"
      />
      <line
        id="dlr-dlr_940gzzdlpre_940gzzdlral_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="941"
        y1="490.3"
        x2="953.7"
        y2="502.9"
      />
      <line
        id="dlr-dlr_940gzzdlcus_940gzzdlpre_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="928.6"
        y1="477.9"
        x2="941"
        y2="490.3"
      />
      <path
        id="dlr-dlr_940gzzdlrvc_940gzzdlcus_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M895.3,467.4h19.6c1.8,0,4.2,1,5.4,2.3l8.2,8.2"
      />
      <line
        id="dlr-dlr_940gzzdlstl_940gzzdlcgt_7_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="845.2"
        y1="467.4"
        x2="895.3"
        y2="467.4"
      />
      <line
        id="dlr-dlr_940gzzdlein_940gzzdlcgt_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="831.4"
        y1="467.4"
        x2="846.4"
        y2="467.4"
      />
      <line
        id="dlr-dlr_940gzzdlbla_940gzzdlein_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="810.6"
        y1="467.4"
        x2="831.4"
        y2="467.4"
      />
      <line
        id="dlr-dlr_940gzzdlpop_940gzzdlbla_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="467.4"
        x2="810.6"
        y2="467.4"
      />
      <line
        id="dlr-dlr_940gzzdlwfe_940gzzdlpop_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="772.7"
        y1="467.4"
        x2="795.5"
        y2="467.4"
      />
      <line
        id="dlr-dlr_940gzzdllim_940gzzdlwfe_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="753.4"
        y1="467.4"
        x2="772.7"
        y2="467.4"
      />
      <line
        id="dlr-dlr_940gzzdlsha_940gzzdllim_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="731.5"
        y1="467.4"
        x2="753.4"
        y2="467.4"
      />
      <path
        id="dlr-dlr_940gzzdlbnk_940gzzdlsha_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M642.7,456h43.6c1.8,0,4.2,1,5.4,2.3l6.9,6.9c1.2,1.2,3.7,2.3,5.4,2.3h27.5"
      />
      <path
        id="dlr-dlr_940gzzdltwg_940gzzdlsha_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M692.4,477.9c0,0,6.6-6.6,8.3-8.3c1.2-1.2,3.7-2.3,5.4-2.3c1.5,0,25.3,0,25.3,0"
      />
      <line
        id="dlr-dlr_940gzzdlelv_940gzzdllew_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="626.4"
        x2="795.5"
        y2="638"
      />
      <line
        id="dlr-dlr_940gzzdldep_940gzzdlelv_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="614.9"
        x2="795.5"
        y2="626.4"
      />
      <line
        id="dlr-dlr_940gzzdlgre_940gzzdldep_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="603.3"
        x2="795.5"
        y2="614.9"
      />
      <line
        id="dlr-dlr_940gzzdlcut_940gzzdlgre_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="590.7"
        x2="795.5"
        y2="603.3"
      />
      <line
        id="dlr-dlr_940gzzdlisl_940gzzdlcut_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="570.6"
        x2="795.5"
        y2="590.7"
      />
      <line
        id="dlr-dlr_940gzzdlmud_940gzzdlisl_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="559.1"
        x2="795.5"
        y2="570.6"
      />
      <line
        id="dlr-dlr_940gzzdlcla_940gzzdlmud_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="547.6"
        x2="795.5"
        y2="559.1"
      />
      <line
        id="dlr-dlr_940gzzdlsoq_940gzzdlcla_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="536.2"
        x2="795.5"
        y2="547.6"
      />
      <line
        id="dlr-dlr_940gzzdlheq_940gzzdlsoq_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="524.5"
        x2="795.5"
        y2="536.2"
      />
      <line
        id="dlr-dlr_s940gzzdlcan_940gzzdlheq_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="514"
        x2="795.5"
        y2="524.5"
      />
      <line
        id="dlr-dlr_940gzzdlcan_940gzzdlheq_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="513.5"
        x2="795.5"
        y2="514"
      />
      <line
        id="dlr-dlr_940gzzdlwiq_940gzzdlcan_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="489.9"
        x2="795.5"
        y2="513.5"
      />
      <line
        id="dlr-dlr_940gzzdlpop_940gzzdlwiq_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="467.3"
        x2="795.5"
        y2="489.9"
      />
      <line
        id="dlr-dlr_940gzzdlall_940gzzdlpop_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="450.1"
        x2="795.5"
        y2="467.3"
      />
      <line
        id="dlr-dlr_940gzzdlldp_940gzzdlall_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="438.3"
        x2="795.5"
        y2="450.1"
      />
      <line
        id="dlr-dlr_940gzzdldev_940gzzdlldp_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="426.6"
        x2="795.5"
        y2="438.3"
      />
      <line
        id="dlr-dlr_940gzzdlbow_940gzzdldev_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="795.5"
        y1="407"
        x2="795.5"
        y2="426.6"
      />
      <path
        id="dlr-dlr_940gzzdlpud_940gzzdlbow_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M825.6,367.5l-27.9,27.9c-1.2,1.2-2.3,3.1-2.3,4.9v6.8"
      />
      <line
        id="dlr-dlr_940gzzdlstd_940gzzdlpud_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="853.6"
        y1="339.5"
        x2="825.6"
        y2="367.5"
      />
      <path
        id="dlr-dlr_940gzzdlsit_940gzzdlstd_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M854.8,339.9v-4.5c0-1.8-1-4.2-2.3-5.4c-1.7-1.7-12-12.1-12-12.1c-1.2-1.2-3.7-2.3-5.4-2.3c-2.3,0-16.3,0-16.3,0"
      />
      <line
        id="dlr-dlr_940gzzdlstd_940gzzdlshs"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="854.8"
        y1="359.8"
        x2="854.8"
        y2="339.9"
      />
      <line
        id="dlr-dlr_940gzzdlshs_940gzzdlabr"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="854.8"
        y1="377.9"
        x2="854.8"
        y2="359.8"
      />
      <line
        id="dlr-dlr_940gzzdlabr_940gzzdlwhm_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="855.2"
        y1="402.6"
        x2="855.2"
        y2="377.9"
      />
      <line
        id="dlr-dlr_940gzzdlwhm_940gzzdlstl_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="855.2"
        y1="429"
        x2="855.2"
        y2="402.6"
      />
      <line
        id="dlr-dlr_940gzzdlstl_940gzzdlcgt"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="855.2"
        y1="458.5"
        x2="855.2"
        y2="429"
      />
      <path
        id="dlr-dlr_940gzzdlkgv_940gzzdlwla_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M945.3,547.4l3.3,3.3c1.2,1.2,2.3,3.7,2.3,5.4c0,2.1,0,27.1,0,27.1"
      />
      <line
        id="dlr-dlr_940gzzdllca_940gzzdlkgv_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="929.4"
        y1="531.5"
        x2="945.3"
        y2="547.4"
      />
      <line
        id="dlr-dlr_940gzzdlpdk_940gzzdllca_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="913.5"
        y1="515.6"
        x2="929.4"
        y2="531.5"
      />
      <line
        id="dlr-dlr_940gzzdlwsv_940gzzdlpdk_2_"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="898.4"
        y1="500.4"
        x2="913.5"
        y2="515.6"
      />
      <path
        id="dlr-dlr_940gzzdlcgt_940gzzdlwsv"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M898.4,500.4l-30.8-30.8c-1.2-1.2-3.7-2.3-5.4-2.3c-1.8,0-3.3-1-4.6-2.3l-0.1-0.1c-1.2-1.2-2.3-3.7-2.3-5.4v-1.8"
      />
      <path
        id="dlr-dlr_940gzzdlrvc_940gzzdlcgt"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M855.2,459.5c0,1.8,1,4.2,2.3,5.4l0.1,0.1c1.2,1.2,3.2,2.3,5,2.3c2.2,0,32.8,0,32.8,0"
      />
      <path
        id="dlr-dlr_940gzzdlcgt_940gzzdlwsv"
        fill="none"
        stroke="#00B1B0"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M898.4,500.4l-30.8-30.8c-1.2-1.2-3.7-2.3-5.4-2.3c-2,0-15.2,0-15.2,0"
      />
      <g id="dlr-dlr_white_line_5_">
        <path
          id="dlr-dlr_white_line_4_"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M795.5,479.6c0-1.8-1-4.2-2.3-5.4l-4.6-4.6c-1.2-1.2-3.5-2.3-5.1-2.3"
        />
        <path
          id="dlr-dlr_white_line_6_"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M642.7,456h43.6c1.8,0,4.2,1,5.4,2.3l6.9,6.9c1.2,1.2,3.7,2.3,6,2.3h210.9c1.1,0,3.6,1,4.8,2.3l83.2,83.2"
        />
        <path
          id="dlr-dlr_white_line_2_"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M692.4,477.9c0,0,6.6-6.6,8.3-8.3c1.2-1.2,3.7-2.3,5.4-2.3"
        />
        <path
          id="dlr-dlr_white_line"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M853.6,339.5l-55.8,55.9c-1.2,1.2-2.3,3.1-2.3,4.9V638"
        />
        <path
          id="dlr-dlr_white_line_7_"
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="0.7795"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d=" M950.9,583.2c0,0,0-25,0-27.1c0-1.8-1-4.2-2.3-5.4l-3.3-3.3l-77.7-77.7c-1.2-1.2-3.7-2.3-5.4-2.3c-1.8,0-3.3-1-4.6-2.3l-0.1-0.1 c-1.2-1.2-2.3-3.7-2.3-7.7l-0.4-124.1c0,0.5-1-2-2.3-3.2c-1.7-1.7-12-12.1-12-12.1c-1.2-1.2-3.7-2.3-5.4-2.3c-2.3,0-16.3,0-16.3,0 "
        />
      </g>
    </g>
    {/* Waterloo and city */}
    <g id="lul-waterloo-city" className="line">
      <path
        id="lul-waterloo-city_940gzzluwlo_940gzzlubnk"
        fill="none"
        stroke="#86CEBC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M543.7,535.8l51,0c1.8,0,4.2-1,5.4-2.3l24.4-24.4c1.2-1.2,2.3-3.7,2.3-5.4V452"
      />
    </g>
    {/* Bakerloo */}
    <g id="lul-bakerloo" className="line">
      <line
        id="lul-bakerloo_940gzzluhaw_940gzzluken_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="319.4"
        y1="242.5"
        x2="319.4"
        y2="224.5"
      />
      <line
        id="lul-bakerloo_940gzzluken_940gzzluskt_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="319.4"
        y1="277.7"
        x2="319.4"
        y2="241"
      />
      <line
        id="lul-bakerloo_940gzzluskt_940gzzlunwy_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="319.4"
        y1="288.7"
        x2="319.4"
        y2="277.7"
      />
      <line
        id="lul-bakerloo_940gzzlunwy_940gzzluwyc_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="319.4"
        y1="296.9"
        x2="319.4"
        y2="287.2"
      />
      <line
        id="lul-bakerloo_940gzzluwyc_940gzzlusgp_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="319.4"
        y1="307"
        x2="319.4"
        y2="296.9"
      />
      <line
        id="lul-bakerloo_940gzzlusgp_940gzzluhsn_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="319.4"
        y1="316.1"
        x2="319.4"
        y2="305.5"
      />
      <line
        id="lul-bakerloo_940gzzluhsn_940gzzluwjn_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="319.4"
        y1="329"
        x2="319.4"
        y2="314.6"
      />
      <line
        id="lul-bakerloo_940gzzluwjn_940gzzluksl_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="319.4"
        y1="340.1"
        x2="319.4"
        y2="329"
      />
      <line
        id="lul-bakerloo_940gzzluksl_940gzzluqps"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="319.4"
        y1="348.9"
        x2="319.4"
        y2="338.8"
      />
      <path
        id="lul-bakerloo_940gzzluqps_940gzzlukpk_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M322.9,363.2l-1.4-1.4c-1.4-1.4-2.1-3.3-2.1-5.3v-3.7c0-1.1,0-2.5,0-3.9"
      />
      <line
        id="lul-bakerloo_940gzzlukpk_940gzzlumvl_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="331.1"
        y1="371.4"
        x2="321.8"
        y2="362.2"
      />
      <path
        id="lul-bakerloo_940gzzlumvl_940gzzluwka_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M343.2,373.8c0,0-6.5,0-6.5,0c-1.8,0-4.2-1-5.4-2.3l-1.2-1.2"
      />
      <path
        id="lul-bakerloo_940gzzluwka_940gzzlupac_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M377.1,373.8c-19.4,0-35.5,0-35.5,0"
      />
      <line
        id="lul-bakerloo_940gzzlupac_940gzzluerb_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="402.5"
        y1="373.8"
        x2="377.1"
        y2="373.8"
      />
      <path
        id="lul-bakerloo_940gzzluerb_940gzzlumyb_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M434,373.8c-8.5,0-33.1,0-33.1,0"
      />
      <path
        id="lul-bakerloo_940gzzlumyb_940gzzlubst_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M463.2,388.8L450.5,376c-1.2-1.2-3.7-2.3-5.4-2.3c-0.3,0-11.1,0-11.1,0"
      />
      <line
        id="lul-bakerloo_940gzzlubst_940gzzlurgp_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="482.7"
        y1="408.3"
        x2="463.2"
        y2="388.8"
      />
      <path
        id="lul-bakerloo_940gzzlurgp_940gzzluoxc_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M496,432.3v-7.6c0-2.1-0.8-4.3-2.3-5.4l-12.1-12.1"
      />
      <path
        id="lul-bakerloo_940gzzluoxc_940gzzlupcc_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M515.6,464.8l-17.3-17.3c-1.2-1.2-2.3-3.7-2.3-5.4v-9.7"
      />
      <line
        id="lul-bakerloo_940gzzlupcc_940gzzluchx_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="536.8"
        y1="486"
        x2="515.6"
        y2="464.8"
      />
      <path
        id="lul-bakerloo_940gzzluchx_940gzzluemb_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M543.8,512.2l0-16c0-1.8-1-4.2-2.3-5.4l-4.8-4.8"
      />
      <line
        id="lul-bakerloo_940gzzluemb_940gzzluwlo_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="543.8"
        y1="535.7"
        x2="543.8"
        y2="512.2"
      />
      <line
        id="lul-bakerloo_940gzzluwlo_940gzzlulbn_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="543.8"
        y1="571.6"
        x2="543.8"
        y2="535.7"
      />
      <path
        id="lul-bakerloo_940gzzlulbn_940gzzlueac_2_"
        fill="none"
        stroke="#B06010"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M549.1,610.3l-3-3c-1.2-1.2-2.3-3.7-2.3-5.4l0-31.7"
      />
      <line
        fill="none"
        stroke="#E6E7E8"
        strokeWidth="2.9476"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="314.8"
        y1="261.2"
        x2="327.6"
        y2="261.2"
      />
      {/* <g id="lul-bakerloo_940gzzluskt_raillo-overground_910gskenton_2_">
        <rect
          id="lul-bakerloo_940gzzluskt"
          x="316.8"
          y="277.8"
          fill="#B06010"
          width="1.5"
          height="1.5"
        />
        <rect
          id="raillo-overground_910gskenton_2_"
          x="319.1"
          y="277.8"
          fill="#F58025"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-bakerloo_940gzzlunwy_raillo-overground_910gnwembly_2_">
        <rect
          id="lul-bakerloo_940gzzlunwy"
          x="316.8"
          y="287.2"
          fill="#B06010"
          width="1.5"
          height="1.5"
        />
        <rect
          id="raillo-overground_910gnwembly_2_"
          x="319.1"
          y="287.2"
          fill="#F58025"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-bakerloo_940gzzlusgp_raillo-overground_910gstnbgpk_2_">
        <rect
          id="lul-bakerloo_940gzzlusgp"
          x="316.8"
          y="305.5"
          fill="#B06010"
          width="1.5"
          height="1.5"
        />
        <rect
          id="raillo-overground_910gstnbgpk_2_"
          x="319.1"
          y="305.5"
          fill="#F58025"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-bakerloo_940gzzluhsn_raillo-overground_910gharlsdn_2_">
        <rect
          id="lul-bakerloo_940gzzluhsn"
          x="316.8"
          y="314.6"
          fill="#B06010"
          width="1.5"
          height="1.5"
        />
        <rect
          id="raillo-overground_910gharlsdn_2_"
          x="319.1"
          y="314.6"
          fill="#F58025"
          width="1.5"
          height="1.5"
        />
      </g>
      <rect
        id="lul-bakerloo_940gzzlukpk_2_"
        x="320.3"
        y="363.2"
        transform="matrix(0.707 -0.7072 0.7072 0.707 -163.3463 333.6854)"
        fill="#B06010"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-bakerloo_940gzzlumvl_2_"
        x="328.5"
        y="371.4"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -166.7316 341.8276)"
        fill="#B06010"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-bakerloo_940gzzluwka_2_"
        x="341.7"
        y="374.9"
        fill="#B06010"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-bakerloo_940gzzluerb_2_"
        x="401"
        y="371.2"
        fill="#B06010"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-bakerloo_940gzzlurgp_2_"
        x="482.8"
        y="405.7"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -145.7756 460.9872)"
        fill="#B06010"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-bakerloo_940gzzlulbn_2_"
        x="545"
        y="570.1"
        fill="#B06010"
        width="1.5"
        height="1.5"
      /> */}
    </g>
    {/* Jubilee */}
    <g id="lul-jubilee" className="line">
      <path
        id="lul-jubilee_940gzzluwhm_940gzzlustd"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M846.4,389.2l0-36.4c0-2.3,1-3.8,2.3-5.1c1.5-1.5,7.2-7.2,7.2-7.2"
      />
      <line
        id="lul-jubilee_940gzzlucgt_940gzzluwhm"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="846.4"
        y1="467.4"
        x2="846.4"
        y2="389.2"
      />
      <path
        id="lul-jubilee_940gzzlungw_940gzzlucgt"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M834.5,508.3l9.7-9.7c1.2-1.2,2.3-2.8,2.3-5.1l0-26.2"
      />
      <path
        id="lul-jubilee_940gzzlucyf_940gzzlungw"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M795.6,513.4c0,0,30.1,0,30.6,0c1.8,0,4.2-1,5.4-2.3l2.8-2.8"
      />
      <line
        id="lul-jubilee_940gzzlucwr_940gzzlucyf"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="725.3"
        y1="513.4"
        x2="795.6"
        y2="513.4"
      />
      <line
        id="lul-jubilee_940gzzlubmy_940gzzlucwr"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="681.7"
        y1="513.4"
        x2="725.3"
        y2="513.4"
      />
      <path
        id="lul-jubilee_940gzzlulnb_940gzzlubmy"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M642.4,513.4c0,0,15.3,0,39.4,0"
      />
      <path
        id="lul-jubilee_940gzzluswk_940gzzlulnb"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M565.8,548.2h24.7c1.8,0,4.2-1,5.4-2.3l30.3-30.3c1.2-1.2,3.7-2.3,5.4-2.3c0,0,3.9,0,10.7,0"
      />
      <path
        id="lul-jubilee_940gzzluwlo_940gzzluswk"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M529.6,535.8l0,4.7c0,4.2,3.4,7.6,7.6,7.6h28.6"
      />
      <path
        id="lul-jubilee_940gzzluwsm_940gzzluwlo"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M516.3,505.1l11.1,11c1.2,1.2,2.3,3.7,2.3,5.4l0,14.3"
      />
      <line
        id="lul-jubilee_940gzzlugpk_940gzzluwsm"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="475.8"
        y1="464.8"
        x2="516.3"
        y2="505.1"
      />
      <path
        id="lul-jubilee_940gzzlubnd_940gzzlugpk"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M463.3,426.7v22.4c0,1.8,1,4.2,2.3,5.4l10.3,10.3"
      />
      <line
        id="lul-jubilee_940gzzlubst_940gzzlubnd"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="463.3"
        y1="388.7"
        x2="463.3"
        y2="426.7"
      />
      <path
        id="lul-jubilee_940gzzlusjw_940gzzlubst"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M457.6,356.6l3.5,3.5c1.2,1.2,2.3,3.7,2.3,5.4v23.3"
      />
      <line
        id="lul-jubilee_940gzzlukbn_940gzzluwhp"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="418.2"
        y1="317.2"
        x2="435"
        y2="334"
      />
      <line
        id="lul-jubilee_940gzzluwig_940gzzlukbn"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="406.3"
        y1="305.3"
        x2="418.2"
        y2="317.2"
      />
      <line
        id="lul-jubilee_940gzzludoh_940gzzluwig"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="395.6"
        y1="294.6"
        x2="407.3"
        y2="306.4"
      />
      <line
        id="lul-jubilee_940gzzlundn_940gzzludoh"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="384.9"
        y1="283.9"
        x2="396.6"
        y2="295.7"
      />
      <line
        id="lul-jubilee_940gzzluwyp_940gzzlundn"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="375"
        y1="274.1"
        x2="386"
        y2="285"
      />
      <path
        id="lul-jubilee_940gzzlukby_940gzzluwyp"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M370,256.6v9.2c0,1.8,1,4.2,2.3,5.4l2.8,2.8"
      />
      <line
        id="lul-jubilee_940gzzluqby_940gzzlukby"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="370"
        y1="243.9"
        x2="370"
        y2="256.6"
      />
      <line
        id="lul-jubilee_940gzzlucpk_940gzzluqby"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="370"
        y1="231.6"
        x2="370"
        y2="245.4"
      />
      <line
        id="lul-jubilee_940gzzlustm_940gzzlucpk"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="370"
        y1="221"
        x2="370"
        y2="233.1"
      />
      {/* <rect
        id="lul-jubilee_940gzzlucpk"
        x="371.1"
        y="231.6"
        fill="#949CA1"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-jubilee_940gzzluqby"
        x="371.1"
        y="243.9"
        fill="#949CA1"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-jubilee_940gzzlundn"
        x="386"
        y="282.6"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -87.0443 356.4437)"
        fill="#949CA1"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-jubilee_940gzzludoh"
        x="396.7"
        y="293.1"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -91.375 367.0905)"
        fill="#949CA1"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-jubilee_940gzzluwig"
        x="407.4"
        y="303.8"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -95.8055 377.7832)"
        fill="#949CA1"
        width="1.5"
        height="1.5"
      /> */}
      <line
        id="lul-jubilee_940gzzluswc_940gzzlusjw"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="449.2"
        y1="348.2"
        x2="458.6"
        y2="357.7"
      />
      <line
        id="lul-jubilee_940gzzlufyr_940gzzluswc"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="442.5"
        y1="341.5"
        x2="450.3"
        y2="349.3"
      />
      <line
        id="lul-jubilee_940gzzluwhp_940gzzlufyr"
        fill="none"
        stroke="#949CA1"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="435"
        y1="334"
        x2="442.5"
        y2="341.5"
      />
      {/* <rect
        id="lul-jubilee_940gzzluswc_2_"
        x="450.3"
        y="346.7"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -113.5782 420.7114)"
        fill="#949CA1"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-jubilee_940gzzlusjw_2_"
        x="458.7"
        y="355"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -117.0262 429.072)"
        fill="#949CA1"
        width="1.5"
        height="1.5"
      /> */}
    </g>
    {/* Victoria */}
    <g id="lul-victoria" className="line">
      <line
        id="lul-victoria_940gzzluwwl_940gzzlublr_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="760.7"
        y1="263.6"
        x2="792.8"
        y2="263.6"
      />
      <path
        id="lul-victoria_940gzzlublr_940gzzlutmh_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M730.3,263.6c9.4,0,20.5,0,30.5,0"
      />
      <path
        id="lul-victoria_940gzzlutmh_940gzzlusvs_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M714.1,263.6c3.8,0,7.3,0,16.2,0"
      />
      <path
        id="lul-victoria_940gzzlusvs_940gzzlufpk_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M674.7,287.3l21.5-21.5c1.2-1.2,3.1-2.3,4.6-2.3c0.1,0,11.7,0,13.2,0"
      />
      <path
        id="lul-victoria_940gzzlufpk_940gzzluhai_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M661.8,318.7v-15.3c0-1.8,1-4.2,2.3-5.4l17.3-17.3"
      />
      <path
        id="lul-victoria_940gzzluhai_940gzzluksx_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M577.9,366.2h62.4c1.8,0,4.2-1,5.4-2.3l13.8-13.8c1.2-1.2,2.3-3.7,2.3-5.4v-25.9"
      />
      <path
        id="lul-victoria_940gzzluksx_940gzzlueus_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M558.9,369.5l1-1c1.2-1.2,3.7-2.3,5.4-2.3h12.5"
      />
      <line
        id="lul-victoria_940gzzlueus_940gzzluwrr_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="536.8"
        y1="391.6"
        x2="558.9"
        y2="369.5"
      />
      <line
        id="lul-victoria_940gzzluwrr_940gzzluoxc_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="496"
        y1="432.4"
        x2="536.8"
        y2="391.6"
      />
      <path
        id="lul-victoria_940gzzluoxc_940gzzlugpk_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M475.9,464.8l0-9.2c0-1.7,0.8-4,2.1-5.2l18-18"
      />
      <line
        id="lul-victoria_940gzzlugpk_940gzzluvic_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="475.9"
        y1="505.9"
        x2="475.9"
        y2="464.8"
      />
      <line
        id="lul-victoria_940gzzluvic_940gzzlupco_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="475.8"
        y1="566.4"
        x2="475.9"
        y2="505.9"
      />
      <path
        id="lul-victoria_940gzzlupco_940gzzluvxl_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M481.8,612.1l-3.9-3.9c-1.2-1.2-2.1-3.5-2.1-5.3l0-38"
      />
      <line
        id="lul-victoria_940gzzluvxl_940gzzluskw_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="514.6"
        y1="644.9"
        x2="481.8"
        y2="612.1"
      />
      <line
        id="lul-victoria_940gzzluskw_940gzzlubxn_2_"
        fill="none"
        stroke="#009DDC"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="538.4"
        y1="668.7"
        x2="514.6"
        y2="644.9"
      />
      {/* <rect
        id="lul-victoria_940gzzlupco_2_"
        x="473.2"
        y="564.9"
        fill="#009DDC"
        width="1.5"
        height="1.5"
      /> */}
    </g>
    {/* Picadilly */}
    <g id="lul-piccadilly" className="line">
      <line
        id="lul-piccadilly_940gzzlubos_940gzzlunfd_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="190.9"
        y1="523"
        x2="200.8"
        y2="513.2"
      />
      <line
        id="lul-piccadilly_940gzzluosy_940gzzlubos_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="181.8"
        y1="532.2"
        x2="192"
        y2="522"
      />
      <line
        id="lul-piccadilly_940gzzluhwe_940gzzluosy_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="175"
        y1="538.9"
        x2="182.8"
        y2="531.1"
      />
      <line
        id="lul-piccadilly_940gzzluhwc_940gzzluhwe"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="165.3"
        y1="548.6"
        x2="175.1"
        y2="538.8"
      />
      <line
        id="lul-piccadilly_940gzzluhwt_940gzzluhwc_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="157.6"
        y1="556.4"
        x2="166.4"
        y2="547.6"
      />
      <line
        id="lul-piccadilly_940gzzluhnx_940gzzluhwt_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="143.9"
        y1="570"
        x2="157.6"
        y2="556.4"
      />
      <line
        id="lul-piccadilly_940gzzluhrc_940gzzluhnx_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="134.9"
        y1="579.1"
        x2="145"
        y2="568.9"
      />
      <path
        id="lul-piccadilly_940gzzluhr5_940gzzluhrc"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M134.9,579.1L112,601.9c-1.5,1.5-2.5,3.9-2.5,6.1c0,2.5,0,10.3,0,10.3"
      />
      <line
        id="lul-piccadilly_940gzzlucks_940gzzluoak_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="682.4"
        y1="178.7"
        x2="682.4"
        y2="166.1"
      />
      <line
        id="lul-piccadilly_940gzzluoak_940gzzlusgt_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="682.4"
        y1="191.3"
        x2="682.4"
        y2="178.7"
      />
      <line
        id="lul-piccadilly_940gzzlusgt_940gzzluasg_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="682.4"
        y1="203.1"
        x2="682.4"
        y2="189.8"
      />
      <line
        id="lul-piccadilly_940gzzluasg_940gzzlubds_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="682.4"
        y1="215"
        x2="682.4"
        y2="201.6"
      />
      <line
        id="lul-piccadilly_940gzzlubds_940gzzluwog_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="682.4"
        y1="226.8"
        x2="682.4"
        y2="213.5"
      />
      <line
        id="lul-piccadilly_940gzzluwog_940gzzlutpn_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="682.4"
        y1="238.7"
        x2="682.4"
        y2="225.3"
      />
      <line
        id="lul-piccadilly_940gzzlutpn_940gzzlumrh_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="682.4"
        y1="262.8"
        x2="682.4"
        y2="237.2"
      />
      <path
        id="lul-piccadilly_940gzzlufpk_940gzzlumrh_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M676.5,275.8l3.7-3.7c1.2-1.2,2.3-3.7,2.3-5.4v-5.4"
      />
      <line
        id="lul-piccadilly_940gzzluasl_940gzzlufpk_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="663"
        y1="289.2"
        x2="676.5"
        y2="275.8"
      />
      <line
        id="lul-piccadilly_940gzzluhwy_940gzzluasl_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="652.2"
        y1="300.1"
        x2="664.1"
        y2="288.1"
      />
      <line
        id="lul-piccadilly_940gzzlucar_940gzzluhwy_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="635.4"
        y1="316.8"
        x2="653.2"
        y2="299"
      />
      <line
        id="lul-piccadilly_940gzzluksx_940gzzlucar_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="578"
        y1="374.2"
        x2="635.4"
        y2="316.8"
      />
      <path
        id="lul-piccadilly_940gzzlursq_940gzzluksx_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M569.4,414v-28c0-1.8,1-4.2,2.3-5.4l6.3-6.3"
      />
      <path
        id="lul-piccadilly_940gzzluhbn_940gzzlursq_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M561.4,432.7l5.8-5.8c1.2-1.2,2.3-3.7,2.3-5.4v-9"
      />
      <line
        id="lul-piccadilly_940gzzlucgn_940gzzluhbn_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="547.8"
        y1="446.3"
        x2="561.4"
        y2="432.7"
      />
      <line
        id="lul-piccadilly_940gzzlulsq_940gzzlucgn_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="536.8"
        y1="457.3"
        x2="548.9"
        y2="445.3"
      />
      <path
        id="lul-piccadilly_940gzzlupcc_940gzzlulsq_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M515.6,464.8h10.6c1.8,0,4.2-1,5.4-2.3l5.2-5.2"
      />
      <line
        id="lul-piccadilly_940gzzlugpk_940gzzlupcc_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="475.9"
        y1="464.8"
        x2="515.6"
        y2="464.8"
      />
      <path
        id="lul-piccadilly_940gzzluhpc_940gzzlugpk_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M463.5,470.9l3.9-3.9c1.2-1.2,3.7-2.3,5.4-2.3h3.1"
      />
      <line
        id="lul-piccadilly_940gzzluknb_940gzzluhpc_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="443.3"
        y1="491.1"
        x2="464.6"
        y2="469.8"
      />
      <path
        id="lul-piccadilly_940gzzlusks_940gzzluknb_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M421.9,506.4l3,0c1.8,0,4.2-1,5.4-2.3l14.1-14.1"
      />
      <line
        id="lul-piccadilly_940gzzlugtr_940gzzlusks_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="406.7"
        y1="506.4"
        x2="421.9"
        y2="506.4"
      />
      <line
        id="lul-piccadilly_940gzzluect_940gzzlugtr_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="382.7"
        y1="506.4"
        x2="408.2"
        y2="506.4"
      />
      <line
        id="lul-piccadilly_940gzzlubsc_940gzzluect_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="347.4"
        y1="506.4"
        x2="382.7"
        y2="506.4"
      />
      <line
        id="lul-piccadilly_940gzzluhsd_940gzzlubsc_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="333.2"
        y1="506.4"
        x2="348.9"
        y2="506.4"
      />
      <line
        id="lul-piccadilly_940gzzlutng_940gzzluhsd_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="267.2"
        y1="506.4"
        x2="333.2"
        y2="506.4"
      />
      <line
        id="lul-piccadilly_940gzzlusea_940gzzluact_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="216.2"
        y1="506.4"
        x2="235.9"
        y2="506.4"
      />
      <path
        id="lul-piccadilly_940gzzlunfd_940gzzlusea_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M199.7,514.2l5.6-5.6l0,0c1.2-1.2,3.7-2.3,5.4-2.3l7,0"
      />
      <path
        id="lul-piccadilly_940gzzluuxb_940gzzluhgd_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M132.3,231.7c-11.5,0-20.3,0-20.3,0"
      />
      <line
        id="lul-piccadilly_940gzzluhgd_940gzzluick_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="148.8"
        y1="231.7"
        x2="132.3"
        y2="231.7"
      />
      <g id="lul-piccadilly_940gzzluick_940gzzlursp">
        <line
          id="lul-piccadilly_940gzzluick_940gzzlursp"
          fill="none"
          stroke="#1C3F94"
          strokeWidth="2.2707"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          x1="160.4"
          y1="231.7"
          x2="147.3"
          y2="231.7"
        />
        <line
          id="lul-piccadilly_940gzzluick_940gzzlursp_2_"
          fill="none"
          stroke="#1C3F94"
          strokeWidth="2.2707"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          x1="175.7"
          y1="231.7"
          x2="163.4"
          y2="231.7"
        />
      </g>
      <path
        id="lul-piccadilly_940gzzlursp_940gzzlursm_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M193.7,236.5c-1.8-1.8-2.5-2.5-2.7-2.7c-1-1-3-2.1-4.6-2.1c-0.2,0-12.2,0-12.2,0"
      />
      <path
        id="lul-piccadilly_940gzzlursm_940gzzlueae_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M205.4,248.2c0,0-9.7-9.7-12.8-12.8"
      />
      <line
        id="lul-piccadilly_940gzzlueae_940gzzluryl_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="215"
        y1="257.8"
        x2="204.3"
        y2="247.1"
      />
      <path
        id="lul-piccadilly_940gzzluryl_940gzzlushh_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M230.7,284v-7.4c0-1.8-1-4.2-2.3-5.4c0,0-6.2-6.2-13.4-13.4"
      />
      <line
        id="lul-piccadilly_940gzzlushh_940gzzlusuh_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="230.7"
        y1="305.3"
        x2="230.7"
        y2="282.5"
      />
      <line
        id="lul-piccadilly_940gzzlusuh_940gzzlusut_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="230.7"
        y1="326.2"
        x2="230.7"
        y2="303.8"
      />
      <line
        id="lul-piccadilly_940gzzlusut_940gzzlualp_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="230.7"
        y1="348.6"
        x2="230.7"
        y2="326.2"
      />
      <line
        id="lul-piccadilly_940gzzlualp_940gzzlupkr_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="230.7"
        y1="419"
        x2="230.7"
        y2="347.1"
      />
      <line
        id="lul-piccadilly_940gzzlupkr_940gzzlunen_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="230.7"
        y1="434.4"
        x2="230.7"
        y2="417.5"
      />
      <line
        id="lul-piccadilly_940gzzlunen_940gzzluecm_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="230.7"
        y1="482"
        x2="230.7"
        y2="432.9"
      />
      <g id="lul-piccadilly_940gzzluact_940gzzlutng_2_">
        <line
          fill="none"
          stroke="#1C3F94"
          strokeWidth="2.2707"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          x1="235.9"
          y1="506.4"
          x2="268.7"
          y2="506.4"
        />
        <path
          fill="none"
          stroke="#1C3F94"
          strokeWidth="2.2707"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d="M235.4,504.6 c1.8,1.2,3.9,1.9,6,1.9c2.7,0,27.3,0,27.3,0"
        />
      </g>
      <path
        id="lul-piccadilly_940gzzluecm_940gzzluact_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M230.7,482v15.8c0,1.5,0.6,2.8,1.6,3.8l0,0l1.7,1.7c0.5,0.5,0.9,0.9,1.4,1.2"
      />
      <path
        id="lul-piccadilly_940gzzluhr4_940gzzluhnx_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M130.7,624.6c5.3,0,9.7-4.3,9.7-9.7v-37.8c0-2.2,0.8-4.4,2.5-6.1c1-1,1.5-1.5,1.5-1.5"
      />
      <path
        id="lul-piccadilly_940gzzluhr4_940gzzluhrc_2_"
        fill="none"
        stroke="#1C3F94"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M127.9,624.6c-5.3,0-9.7-4.3-9.7-9.7l0-15.6c0-2.4,1-4.5,2.5-6.1c0.8-0.8,14.2-14.2,14.2-14.2"
      />
      <polyline
        fill="none"
        stroke="#E6E7E8"
        strokeWidth="0.9271"
        points="137.6,607 140.3,609.7 143,607 "
      />
      <polyline
        fill="none"
        stroke="#E6E7E8"
        strokeWidth="0.9271"
        points="120.9,610.8 118.2,608.2 115.5,610.8 "
      />
      {/* <rect
        id="lul-piccadilly_940gzzlushh_2_"
        x="228.1"
        y="282.5"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlusuh_2_"
        x="228.1"
        y="303.8"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlualp_2_"
        x="228.1"
        y="347.1"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlupkr_2_"
        x="228.1"
        y="417.5"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlunen_2_"
        x="228.1"
        y="432.9"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlusea_2_"
        x="216.2"
        y="503.9"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlunfd_2_"
        x="200.8"
        y="514.3"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -305.1484 293.3742)"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlubos_2_"
        x="192"
        y="523.1"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -313.9167 289.7149)"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzluosy_2_"
        x="182.9"
        y="532.2"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -323.1005 285.9402)"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzluhwc_2_"
        x="166.4"
        y="548.7"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -339.5627 279.1321)"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzluhnx_2_"
        x="145"
        y="570"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -360.8999 270.2684)"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlursq_2_"
        x="570.6"
        y="412.5"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlucgn_2_"
        x="548.9"
        y="446.4"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -155.1712 519.6078)"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzluhpc_2_"
        x="462"
        y="468.3"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -196.1478 464.6669)"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzluknb_2_"
        x="441.8"
        y="488.6"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -216.3866 456.2551)"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlucks_2_"
        x="679.7"
        y="166.1"
        fill="#1C3F94"
        width="5.4"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlusgt_2_"
        x="679.8"
        y="189.8"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzluasg_2_"
        x="679.8"
        y="201.6"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlubds_2_"
        x="679.8"
        y="213.5"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzluwog_2_"
        x="679.8"
        y="225.3"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlutpn_2_"
        x="679.8"
        y="237.2"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzlumrh_2_"
        x="679.8"
        y="261.3"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzluasl_2_"
        x="661.5"
        y="286.6"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -9.2281 552.4718)"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      />
      <rect
        id="lul-piccadilly_940gzzluhwy_2_"
        x="650.6"
        y="297.5"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.114 547.9689)"
        fill="#1C3F94"
        width="1.5"
        height="1.5"
        className="blue-fill"
      /> */}
    </g>
    {/* Central */}
    <g id="lul-central" className="line">
      <path
        id="lul-central_940gzzlulys_940gzzluwsd"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M857.7,266.2h-9.5c-2.7,0-5,2.2-5,5c0,2.6,0,11.1,0,11.1"
      />
      <path
        id="lul-central_940gzzluwof_940gzzlurvy"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M856.9,206.8h-8.7c-2.7,0-5,2.2-5,5c0,2.7,0,10.1,0,10.1"
      />
      <line
        id="lul-central_940gzzlurvy_940gzzlucwl"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="873.3"
        y1="206.8"
        x2="856.9"
        y2="206.8"
      />
      <line
        id="lul-central_940gzzlucwl_940gzzluggh"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="885.3"
        y1="206.8"
        x2="871.8"
        y2="206.8"
      />
      <path
        id="lul-central_940gzzluggh_940gzzluhlt"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M891,220v-8.2c0-2.7-2.2-5-5-5h-2.3"
      />
      <line
        id="lul-central_940gzzluflp_940gzzluhlt"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="891"
        y1="232"
        x2="891"
        y2="220"
      />
      <line
        id="lul-central_940gzzlubke_940gzzluflp"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="891"
        y1="243.1"
        x2="891"
        y2="230.5"
      />
      <line
        id="lul-central_940gzzlunbp_940gzzlubke"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="891"
        y1="256.5"
        x2="891"
        y2="241.6"
      />
      <path
        id="lul-central_940gzzlugth_940gzzlunbp"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M883.8,266.2h2.3c2.7,0,5-2.2,5-5V255"
      />
      <line
        id="lul-central_940gzzlurbg_940gzzlugth"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="870"
        y1="266.2"
        x2="885.3"
        y2="266.2"
      />
      <line
        id="lul-central_940gzzluwsd_940gzzlurbg"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="856.2"
        y1="266.2"
        x2="871.5"
        y2="266.2"
      />
      <path
        id="lul-central_940gzzlueby_940gzzluwta"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M249.5,452c-21,0-34.7,0-34.7,0"
      />
      <path
        id="lul-central_940gzzluwta_940gzzlunan"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M286.1,452c-12.8,0-27.8,0-38.1,0"
      />
      <line
        id="lul-central_940gzzlunan_940gzzluean"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="302.7"
        y1="452"
        x2="284.6"
        y2="452"
      />
      <line
        id="lul-central_940gzzluean_940gzzluwcy"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="324.1"
        y1="452"
        x2="301.2"
        y2="452"
      />
      <line
        id="lul-central_940gzzluwcy_940gzzlusbc"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="355.1"
        y1="452"
        x2="324.1"
        y2="452"
      />
      <line
        id="lul-central_940gzzlusbc_940gzzluhpk"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="374.2"
        y1="452"
        x2="355.1"
        y2="452"
      />
      <line
        id="lul-central_940gzzluhpk_940gzzlunhg"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="389.6"
        y1="452"
        x2="372.7"
        y2="452"
      />
      <line
        id="lul-central_940gzzlunhg_940gzzluqwy"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="416.4"
        y1="452"
        x2="389.6"
        y2="452"
      />
      <path
        id="lul-central_940gzzluqwy_940gzzlulgt"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M434.5,448.1l-1.6,1.6c-1.2,1.2-3.7,2.3-5.4,2.3c-0.3,0-12.5,0-12.5,0"
      />
      <line
        id="lul-central_940gzzlulgt_940gzzlumba"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="443.8"
        y1="438.8"
        x2="433.4"
        y2="449.2"
      />
      <path
        id="lul-central_940gzzlumba_940gzzlubnd"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M457.4,432.7H453c-1.7,0-4.2,1-5.4,2.3l-4.9,4.9"
      />
      <line
        id="lul-central_940gzzlubnd_940gzzluoxc"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="496.2"
        y1="432.7"
        x2="457.4"
        y2="432.7"
      />
      <line
        id="lul-central_940gzzluoxc_940gzzlutcr"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="530.7"
        y1="432.7"
        x2="496.2"
        y2="432.7"
      />
      <line
        id="lul-central_940gzzlutcr_940gzzluhbn"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="561.5"
        y1="432.7"
        x2="530.7"
        y2="432.7"
      />
      <path
        id="lul-central_940gzzluhbn_940gzzluchl"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M592.7,441.6l-6.7-6.7c-1.2-1.2-3.7-2.3-5.4-2.3h-19.2"
      />
      <path
        id="lul-central_940gzzluchl_940gzzluspu"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M608.9,452l-2.7,0c-1.8,0-4.2-1-5.4-2.3l-9.2-9.2"
      />
      <line
        id="lul-central_940gzzluspu_940gzzlubnk"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="626.9"
        y1="452"
        x2="607.4"
        y2="452"
      />
      <path
        id="lul-central_940gzzlubnk_940gzzlulvt"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M661.2,422.9l-26.9,26.9c-1.2,1.2-3.7,2.3-5.4,2.3h-2"
      />
      <path
        id="lul-central_940gzzlulvt_940gzzlublg"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M746.2,390.9c0,0-48.7,0-49.8,0c-1.8,0-4.2,1-5.4,2.3l-29.7,29.7"
      />
      <line
        id="lul-central_940gzzlublg_940gzzlumed"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="775"
        y1="390.9"
        x2="744.7"
        y2="390.9"
      />
      <path
        id="lul-central_940gzzlumed_940gzzlustd"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M835.9,340.1l-48.5,48.6c-1.2,1.2-3.7,2.3-5.4,2.3l-7,0"
      />
      <path
        id="lul-central_940gzzlustd_940gzzlulyn"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M843.2,311.1v17.4c0,2-0.8,5.2-2.3,6.7l-5,5"
      />
      <line
        id="lul-central_940gzzlulyn_940gzzlulys"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="843.2"
        y1="280.8"
        x2="843.2"
        y2="312.6"
      />
      <line
        id="lul-central_940gzzlulys_940gzzlusnb"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="843.2"
        y1="244.8"
        x2="843.2"
        y2="282.3"
      />
      <line
        id="lul-central_940gzzlusnb_940gzzluswf"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="843.2"
        y1="233"
        x2="843.2"
        y2="246.3"
      />
      <line
        id="lul-central_940gzzluswf_940gzzluwof"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="843.2"
        y1="221.9"
        x2="843.2"
        y2="234.5"
      />
      <line
        id="lul-central_940gzzluwof_940gzzlubkh"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="843.2"
        y1="180.4"
        x2="843.2"
        y2="221.9"
      />
      <line
        id="lul-central_940gzzlubkh_940gzzlulgn"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="843.2"
        y1="169.1"
        x2="843.2"
        y2="180.4"
      />
      <line
        id="lul-central_940gzzlulgn_940gzzludbn"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="843.2"
        y1="157.9"
        x2="843.2"
        y2="170.6"
      />
      <line
        id="lul-central_940gzzludbn_940gzzluthb"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="843.2"
        y1="148.4"
        x2="843.2"
        y2="160.2"
      />
      <line
        id="lul-central_940gzzluthb_940gzzluepg"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="843.2"
        y1="136.6"
        x2="843.2"
        y2="149.9"
      />
      <path
        id="lul-central_940gzzluhgr_940gzzlunan"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M216.7,398.4l51.3,51.3c1.2,1.2,3.7,2.3,5.4,2.3c1.7,0,12.6,0,12.6,0"
      />
      <line
        id="lul-central_940gzzlupvl_940gzzluhgr"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="197.1"
        y1="378.8"
        x2="217.8"
        y2="399.5"
      />
      <line
        id="lul-central_940gzzlugfd_940gzzlupvl"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="174.4"
        y1="356"
        x2="198.2"
        y2="379.8"
      />
      <path
        id="lul-central_940gzzlunht_940gzzlugfd"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M161.9,322.4v18.1c0,1.8,1,4.2,2.3,5.4l10.2,10.2"
      />
      <line
        id="lul-central_940gzzlusrp_940gzzlunht"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="161.9"
        y1="294.7"
        x2="161.9"
        y2="323.9"
      />
      <line
        id="lul-central_940gzzlursg_940gzzlusrp"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="161.9"
        y1="265.3"
        x2="161.9"
        y2="294.7"
      />
      {/* <rect
        id="lul-central_940gzzlursg"
        x="159.3"
        y="265.3"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlunht"
        x="159.3"
        y="322.4"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlupvl"
        x="195.5"
        y="379.9"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -211.6485 250.2835)"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzluhgr"
        x="215.2"
        y="399.5"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -219.7903 269.9452)"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzluwta"
        x="248"
        y="453.1"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlunan"
        x="284.6"
        y="453.1"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzluean_2_"
        x="301.2"
        y="449.3"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzluhpk"
        x="372.7"
        y="453.1"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzluqwy_2_"
        x="414.9"
        y="453.1"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlumba_2_"
        x="441.1"
        y="437.3"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -180.3151 440.7692)"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlulgt_2_"
        x="434.5"
        y="449.2"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -190.677 439.5732)"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzluchl"
        x="592.7"
        y="439"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -137.1376 548.4741)"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzluspu"
        x="607.4"
        y="453.1"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlublg"
        x="744.7"
        y="388.3"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlulyn"
        x="844.3"
        y="311.1"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlulys"
        x="844.3"
        y="280.8"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzluwsd"
        x="856.2"
        y="267.3"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlugth"
        x="883.8"
        y="267.3"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlurbg"
        x="870"
        y="263.6"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlusnb"
        x="844.3"
        y="244.9"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzluswf"
        x="844.3"
        y="233"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlunbp"
        x="892.1"
        y="255"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlubke"
        x="892.1"
        y="241.6"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzluflp"
        x="892.1"
        y="230.5"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlucwl"
        x="871.8"
        y="207.9"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzluggh"
        x="883.8"
        y="204.2"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzlulgn"
        x="844.3"
        y="169.1"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzludbn"
        x="844.3"
        y="158.7"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-central_940gzzluthb"
        x="844.3"
        y="148.4"
        fill="#EE3124"
        width="1.5"
        height="1.5"
      /> */}
      <line
        id="lul-central_940gzzluwrp_940gzzlursg"
        fill="none"
        stroke="#EE3124"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="161.9"
        y1="214.1"
        x2="161.9"
        y2="266.8"
      />
    </g>
    {/* Hammersmith and city */}
    <g id="lul-hammersmith-city" className="line">
      <line
        id="lul-hammersmith-city_940gzzluehm_940gzzlubkg_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="898.2"
        y1="365.7"
        x2="908.2"
        y2="355.7"
      />
      <line
        id="lul-hammersmith-city_940gzzluupk_940gzzluehm_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="885.5"
        y1="378.4"
        x2="898.2"
        y2="365.7"
      />
      <line
        id="lul-hammersmith-city_940gzzluplw_940gzzluupk_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="872.7"
        y1="391.2"
        x2="886.6"
        y2="377.3"
      />
      <path
        id="lul-hammersmith-city_940gzzluwhm_940gzzluplw_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M849.9,396.9l14.9,0h0c1.2,0,3-0.8,3.8-1.6l5.2-5.2"
      />
      <line
        id="lul-hammersmith-city_940gzzlubbb_940gzzluwhm"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="833.4"
        y1="396.9"
        x2="849.9"
        y2="396.9"
      />
      <line
        id="lul-hammersmith-city_940gzzlubwr_940gzzlubbb"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="804.9"
        y1="396.9"
        x2="833.4"
        y2="396.9"
      />
      <line
        id="lul-hammersmith-city_940gzzlumed_940gzzlubwr_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="774.9"
        y1="396.8"
        x2="804.9"
        y2="396.9"
      />
      <path
        id="lul-hammersmith-city_940gzzlusgn_940gzzlumed_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M740.2,401l1.3-1.3c1.7-1.7,4.7-2.9,7-2.9l26.3,0.1"
      />
      <line
        id="lul-hammersmith-city_940gzzluwpl_940gzzlusgn_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="724.4"
        y1="416.8"
        x2="741.3"
        y2="399.9"
      />
      <line
        id="lul-hammersmith-city_940gzzluade_940gzzluwpl_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="712.7"
        y1="428.6"
        x2="724.4"
        y2="416.8"
      />
      <path
        id="lul-hammersmith-city_940gzzlulvt_940gzzluade_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M661.2,420.7h25.4c2.7,0,5.2,1,7.1,2.9l7.3,7.3c1.3,1.3,2.5,2.2,4.7,2.2c2.3,0,3.3-0.8,4.6-2.2l2.3-2.3"
      />
      <line
        id="lul-hammersmith-city_940gzzlumgt_940gzzlulvt_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="636.7"
        y1="420.7"
        x2="661.2"
        y2="420.7"
      />
      <path
        id="lul-hammersmith-city_940gzzlubbn_940gzzlumgt_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M617,407.2l11.9,11.9c1,1,2.4,1.6,3.9,1.6h3.9"
      />
      <line
        id="lul-hammersmith-city_940gzzlufcn_940gzzlubbn_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="603.6"
        y1="393.8"
        x2="618"
        y2="408.2"
      />
      <path
        id="lul-hammersmith-city_940gzzluksx_940gzzlufcn_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M577.9,379.1l6.8,0c2.7,0,5.2,1,7.1,2.9l11.8,11.8"
      />
      <line
        id="lul-hammersmith-city_940gzzluesq_940gzzluksx_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="556.3"
        y1="379.1"
        x2="577.9"
        y2="379.1"
      />
      <line
        id="lul-hammersmith-city_940gzzlugps_940gzzluesq_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="500.4"
        y1="379.1"
        x2="557.8"
        y2="379.1"
      />
      <line
        id="lul-hammersmith-city_940gzzlubst_940gzzlugps_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="471.1"
        y1="379.1"
        x2="501.9"
        y2="379.1"
      />
      <line
        id="lul-hammersmith-city_940gzzluerc_940gzzlubst_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="417.1"
        y1="379.1"
        x2="471.1"
        y2="379.1"
      />
      <line
        id="lul-hammersmith-city_940gzzlupah_940gzzluerc_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="383.6"
        y1="379.1"
        x2="417.1"
        y2="379.1"
      />
      <path
        id="lul-hammersmith-city_940gzzluryo_940gzzlupah_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M345.2,393.6l9.6-9.6c3.1-3.1,7.3-4.9,11.8-4.9l16.9,0"
      />
      <line
        id="lul-hammersmith-city_940gzzluwsp_940gzzluryo_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="335.4"
        y1="403.4"
        x2="346.3"
        y2="392.5"
      />
      <path
        id="lul-hammersmith-city_940gzzlulad_940gzzluwsp_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M332.1,419.8v-9.9c0-0.8,0.2-1.9,0.7-2.9c0.4-1,1-1.9,1.6-2.5l2.2-2.2"
      />
      <line
        id="lul-hammersmith-city_940gzzlulrd_940gzzlulad_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="332.1"
        y1="431.1"
        x2="332.1"
        y2="418.2"
      />
      <line
        id="lul-hammersmith-city_940gzzluwla_940gzzlulrd_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="332.1"
        y1="461"
        x2="332.1"
        y2="429.5"
      />
      <line
        id="lul-hammersmith-city_940gzzlusbm_940gzzluwla_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="332.1"
        y1="477.9"
        x2="332.1"
        y2="461"
      />
      <line
        id="lul-hammersmith-city_940gzzlughk_940gzzlusbm_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="332.1"
        y1="490.3"
        x2="332.1"
        y2="476.4"
      />
      <line
        id="lul-hammersmith-city_940gzzluhsc_940gzzlughk_2_"
        fill="none"
        stroke="#F386A1"
        strokeWidth="2.2709"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="332.1"
        y1="498.7"
        x2="332.1"
        y2="488.8"
      />
    </g>
    {/* Circle */}
    <g id="lul-circle" className="line">
      <path
        id="lul-circle_940gzzlupac_940gzzluerc"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M393.1,389.7c1.5-1.5,3.5-2.3,5.5-2.3c2.1,0,18.7,0,18.7,0"
      />
      <path
        id="lul-circle_940gzzlupac_940gzzlubwt"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M390.8,418.9l0-23.7c0-2.1,0.9-4.1,2.3-5.5"
      />
      <line
        id="lul-circle_940gzzlubwt_940gzzlunhg"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="390.8"
        y1="452.1"
        x2="390.8"
        y2="417.4"
      />
      <line
        id="lul-circle_940gzzlunhg_940gzzluhsk"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="390.8"
        y1="477.1"
        x2="390.8"
        y2="452.1"
      />
      <path
        id="lul-circle_940gzzluhsk_940gzzlugtr"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M408.2,511.1h-9.7c-2.1,0-4.1-0.9-5.5-2.3c-1.5-1.5-2.3-3.5-2.3-5.5l0-27.7"
      />
      <line
        id="lul-circle_940gzzlugtr_940gzzlusks"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="421.9"
        y1="511.1"
        x2="406.7"
        y2="511.1"
      />
      <line
        id="lul-circle_940gzzlusks_940gzzlussq"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="450.3"
        y1="511.1"
        x2="421.9"
        y2="511.1"
      />
      <line
        id="lul-circle_940gzzlussq_940gzzluvic"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="468.9"
        y1="511.1"
        x2="448.8"
        y2="511.1"
      />
      <line
        id="lul-circle_940gzzluvic_940gzzlusjp"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="492.2"
        y1="511.1"
        x2="468.9"
        y2="511.1"
      />
      <line
        id="lul-circle_940gzzlusjp_940gzzluwsm"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="516.3"
        y1="511.1"
        x2="490.7"
        y2="511.1"
      />
      <line
        id="lul-circle_940gzzluwsm_940gzzluemb"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="543.8"
        y1="511.1"
        x2="516.3"
        y2="511.1"
      />
      <line
        id="lul-circle_940gzzluemb_940gzzlutmp"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="573.3"
        y1="511.1"
        x2="543.8"
        y2="511.1"
      />
      <path
        id="lul-circle_940gzzlutmp_940gzzlubkf"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M594.9,498.1l-11.4,11.4c-1,1-2.4,1.6-3.9,1.6h-7.8"
      />
      <line
        id="lul-circle_940gzzlubkf_940gzzlumsh"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="605.8"
        y1="487.3"
        x2="594.9"
        y2="498.1"
      />
      <line
        id="lul-circle_940gzzlumsh_940gzzlucst"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="616.1"
        y1="476.9"
        x2="604.7"
        y2="488.3"
      />
      <path
        id="lul-circle_940gzzlucst_940gzzlummt"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M643.6,468h-15.5c-2,0-4,0.8-5.4,2.2l-6.7,6.7"
      />
      <line
        id="lul-circle_940gzzlummt_940gzzlutwh"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="682.7"
        y1="468"
        x2="643.6"
        y2="468"
      />
      <path
        id="lul-circle_940gzzluald_940gzzlutwh"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M694.3,443.6l0,16.6c0,2.1-0.9,4.1-2.3,5.5c-1.4,1.4-3.3,2.3-5.5,2.3h-3.9"
      />
      <path
        id="lul-circle_940gzzlulvt_940gzzluald"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M661.3,422.9h25.3c2.1,0,4.1,0.9,5.5,2.3c1.4,1.4,2.3,3.3,2.3,5.5l0,14.4"
      />
      <line
        id="lul-circle_940gzzlumgt_940gzzlulvt"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="636.7"
        y1="422.9"
        x2="661.3"
        y2="422.9"
      />
      <path
        id="lul-circle_940gzzlubbn_940gzzlumgt"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M615.4,408.8l11.9,11.9c1.4,1.4,3.3,2.3,5.5,2.3h4"
      />
      <line
        id="lul-circle_940gzzlufcn_940gzzlubbn"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="602"
        y1="395.4"
        x2="616.4"
        y2="409.9"
      />
      <path
        id="lul-circle_940gzzluksx_940gzzlufcn"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M577.9,381.3l6.8,0c2.1,0,4.1,0.9,5.5,2.3l11.8,11.8"
      />
      <line
        id="lul-circle_940gzzluesq_940gzzluksx"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="556.3"
        y1="381.3"
        x2="577.9"
        y2="381.3"
      />
      <line
        id="lul-circle_940gzzlugps_940gzzluesq"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="500.4"
        y1="381.3"
        x2="557.8"
        y2="381.3"
      />
      <line
        id="lul-circle_940gzzlubst_940gzzlugps"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="471.1"
        y1="381.3"
        x2="501.9"
        y2="381.3"
      />
      <line
        id="lul-circle_940gzzluerc_940gzzlubst"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="417"
        y1="381.3"
        x2="471.1"
        y2="381.3"
      />
      <line
        id="lul-circle_940gzzlupah_940gzzluerc"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="383.6"
        y1="381.3"
        x2="417"
        y2="381.3"
      />
      <path
        id="lul-circle_940gzzluryo_940gzzlupah"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M346.8,395.2l9.6-9.6c2.6-2.6,6.2-4.2,10.2-4.2l17,0"
      />
      <line
        id="lul-circle_940gzzluwsp_940gzzluryo"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="337"
        y1="405"
        x2="347.9"
        y2="394.1"
      />
      <path
        id="lul-circle_940gzzlulad_940gzzluwsp"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M334.3,419.8l0-9.9l0,0c0-0.4,0.2-1.2,0.5-2l0,0c0.3-0.8,0.8-1.5,1.1-1.7l2.2-2.2"
      />
      <line
        id="lul-circle_940gzzlulrd_940gzzlulad"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="334.3"
        y1="431.1"
        x2="334.3"
        y2="418.2"
      />
      <line
        id="lul-circle_940gzzluwla_940gzzlulrd"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="334.2"
        y1="461.3"
        x2="334.3"
        y2="429.5"
      />
      <line
        id="lul-circle_940gzzlusbm_940gzzluwla"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="334.3"
        y1="477.9"
        x2="334.3"
        y2="461.3"
      />
      <line
        id="lul-circle_940gzzlughk_940gzzlusbm"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="334.3"
        y1="490.3"
        x2="334.3"
        y2="476.4"
      />
      <line
        id="lul-circle_940gzzluhsc_940gzzlughk"
        fill="none"
        stroke="#FFD200"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="334.3"
        y1="498.8"
        x2="334.3"
        y2="488.8"
      />
      {/* <g id="lul-circle_lul-hammersmith-city_940gzzluryo">
        <rect
          id="lul-hammersmith-city_940gzzluryo"
          x="346.3"
          y="393.6"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -177.1964 360.9177)"
          fill="#F386A1"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-circle_940gzzluryo"
          x="347.9"
          y="395.2"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -177.8597 362.499)"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-hammersmith-city_940gzzluwsp">
        <rect
          id="lul-hammersmith-city_940gzzluwsp"
          x="336.5"
          y="403.5"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -187.0528 356.8164)"
          fill="#F386A1"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-circle_940gzzluwsp"
          x="338.1"
          y="405.1"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -187.7198 358.4451)"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-hammersmith-city_940gzzlulad">
        <rect
          id="lul-circle_940gzzlulad"
          x="335.4"
          y="418.3"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-hammersmith-city_940gzzlulad"
          x="333.2"
          y="418.3"
          fill="#F386A1"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-hammersmith-city_940gzzlulrd">
        <rect
          id="lul-circle_940gzzlulrd"
          x="335.4"
          y="429.6"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-hammersmith-city_940gzzlulrd"
          x="333.1"
          y="429.6"
          fill="#F386A1"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-hammersmith-city_940gzzlusbm">
        <rect
          id="lul-circle_940gzzlusbm"
          x="331.7"
          y="476.4"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-hammersmith-city_940gzzlusbm"
          x="329.5"
          y="476.4"
          fill="#F386A1"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-hammersmith-city_940gzzlughk">
        <rect
          id="lul-circle_940gzzlughk"
          x="331.7"
          y="488.8"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-hammersmith-city_940gzzlughk"
          x="329.5"
          y="488.8"
          fill="#F386A1"
          width="1.5"
          height="1.5"
        />
      </g> */}
    </g>
    {/* District */}
    <g id="lul-district" className="line">
      <path
        id="lul-district_940gzzlupac_940gzzluerc_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M391.5,388.1c1.9-1.9,4.4-2.9,7.1-2.9c2.6,0,18.6,0,18.6,0"
      />
      <path
        id="lul-district_940gzzlupac_940gzzlubwt_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M388.5,418.9v-23.7c0-2.7,1-5.2,2.9-7.1"
      />
      <line
        id="lul-district_940gzzlubwt_940gzzlunhg_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="388.5"
        y1="452.2"
        x2="388.5"
        y2="417.4"
      />
      <line
        id="lul-district_940gzzlunhg_940gzzluhsk_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="388.5"
        y1="477.1"
        x2="388.5"
        y2="452.2"
      />
      <path
        id="lul-district_940gzzluhsk_940gzzluect_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M380.7,513.3c2.1,0,4.3-1,5.5-2.3c1.2-1.2,2.3-3.3,2.3-5.5v-30"
      />
      <g id="lul-district_940gzzluect_940gzzlukoy">
        <path
          fill="none"
          stroke="#00853F"
          strokeWidth="2.2707"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d="M384.4,513.3 c-2.1,0-4.1-0.9-5.5-2.3c-1.4-1.4-2.3-3.3-2.3-5.5c0-2.1,0-22.6,0-22.6"
        />
        <path
          fill="none"
          stroke="#FFFFFF"
          strokeWidth="1.4993"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          d="M384.4,513.3 c-2.1,0-4.1-0.9-5.5-2.3c-1.4-1.4-2.3-3.3-2.3-5.5c0-2.1,0-22.6,0-22.6"
        />
        <path
          id="lul-district_940gzzluect_940gzzlukoy_4_"
          fill="none"
          stroke="#00853F"
          strokeWidth="2.1706"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          strokeDasharray="1.5133,1.5133"
          d=" M384.4,513.3c-2.1,0-4.1-0.9-5.5-2.3c-1.4-1.4-2.3-3.3-2.3-5.5c0-2.1,0-22.6,0-22.6"
        />
      </g>
      <path
        id="lul-district_940gzzluwim_940gzzluwip_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M376.6,626.5c0,0,0,11.5,0,11.5"
      />
      <line
        id="lul-district_940gzzluwip_940gzzlusfs_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="376.6"
        y1="616"
        x2="376.6"
        y2="627.9"
      />
      <line
        id="lul-district_940gzzlusfs_940gzzluepy_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="376.6"
        y1="604.2"
        x2="376.6"
        y2="616"
      />
      <line
        id="lul-district_940gzzluepy_940gzzlupyb_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="376.6"
        y1="588.4"
        x2="376.6"
        y2="605.7"
      />
      <line
        id="lul-district_940gzzlupyb_940gzzlupsg_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="376.6"
        y1="578.5"
        x2="376.6"
        y2="589.9"
      />
      <line
        id="lul-district_940gzzlupsg_940gzzlufby_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="376.6"
        y1="569.2"
        x2="376.6"
        y2="580"
      />
      <line
        id="lul-district_940gzzlufby_940gzzluwbn_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="376.6"
        y1="542.6"
        x2="376.6"
        y2="569.2"
      />
      <path
        id="lul-district_940gzzluwbn_940gzzluect_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M384.4,513.3c-2.1,0-4.1,0.9-5.5,2.3c-1.4,1.4-2.3,3.3-2.3,5.5c0,0.3,0,21.5,0,21.5"
      />
      <line
        id="lul-district_940gzzluect_940gzzlugtr_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="408.2"
        y1="513.3"
        x2="382.7"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzlugtr_940gzzlusks_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="421.8"
        y1="513.3"
        x2="406.7"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzlusks_940gzzlussq_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="450.4"
        y1="513.3"
        x2="421.8"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzlussq_940gzzluvic_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="468.7"
        y1="513.3"
        x2="448.8"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzluvic_940gzzlusjp_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="492.2"
        y1="513.3"
        x2="468.7"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzlusjp_940gzzluwsm_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="516.3"
        y1="513.3"
        x2="490.7"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzluwsm_940gzzluemb_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="543.8"
        y1="513.3"
        x2="516.3"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzluemb_940gzzlutmp_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="573.3"
        y1="513.3"
        x2="543.8"
        y2="513.3"
      />
      <path
        id="lul-district_940gzzlutmp_940gzzlubkf_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M596.5,499.7l-11.3,11.3c-1.4,1.4-3.3,2.3-5.5,2.3h-7.8"
      />
      <line
        id="lul-district_940gzzlubkf_940gzzlumsh_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="607.4"
        y1="488.9"
        x2="596.5"
        y2="499.7"
      />
      <line
        id="lul-district_940gzzlumsh_940gzzlucst_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="617.6"
        y1="478.6"
        x2="606.3"
        y2="489.9"
      />
      <path
        id="lul-district_940gzzlucst_940gzzlummt_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M643.6,470.3h-15.4c-1.4,0-2.8,0.6-3.8,1.6l-6.7,6.7"
      />
      <line
        id="lul-district_940gzzlummt_940gzzlutwh_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="682.7"
        y1="470.3"
        x2="643.6"
        y2="470.3"
      />
      <path
        id="lul-district_940gzzlutwh_940gzzluade_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M714.4,430.1l-15.5,15.5c-1.5,1.5-2.3,3.5-2.3,5.5v9.2c0,2.7-1,5.2-2.9,7.1c-1.9,1.9-4.4,2.9-7.1,2.9h-3.9"
      />
      <line
        id="lul-district_940gzzluade_940gzzluwpl_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="725.8"
        y1="418.6"
        x2="714.4"
        y2="430.1"
      />
      <line
        id="lul-district_940gzzluwpl_940gzzlusgn_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="742.9"
        y1="401.5"
        x2="725.8"
        y2="418.6"
      />
      <path
        id="lul-district_940gzzlusgn_940gzzlumed_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M775,399.1l-26.4-0.1c-1.8,0-4.2,1-5.4,2.3l-1.3,1.3"
      />
      <line
        id="lul-district_940gzzlumed_940gzzlubwr_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="804.9"
        y1="399.2"
        x2="775"
        y2="399.1"
      />
      <line
        id="lul-district_940gzzlubwr_940gzzlubbb_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="833.3"
        y1="399.2"
        x2="804.9"
        y2="399.2"
      />
      <line
        id="lul-district_940gzzlubbb_940gzzluwhm_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="850.3"
        y1="399.2"
        x2="833.3"
        y2="399.2"
      />
      <path
        id="lul-district_940gzzluwhm_940gzzluplw_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M875.4,391.7l-5.2,5.2c-1.2,1.2-3.7,2.3-5.4,2.3l-14.5,0"
      />
      <line
        id="lul-district_940gzzluplw_940gzzluupk_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="888.2"
        y1="378.9"
        x2="874.3"
        y2="392.8"
      />
      <line
        id="lul-district_940gzzluupk_940gzzluehm_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="900"
        y1="367.1"
        x2="887.1"
        y2="380"
      />
      <line
        id="lul-district_940gzzluehm_940gzzlubkg_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="909.2"
        y1="357.9"
        x2="900"
        y2="367.1"
      />
      <line
        id="lul-district_940gzzlubkg_940gzzluupy_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="920.7"
        y1="346.4"
        x2="909.2"
        y2="357.9"
      />
      <line
        id="lul-district_940gzzluupy_940gzzlubec_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="929.4"
        y1="337.7"
        x2="920.7"
        y2="346.4"
      />
      <line
        id="lul-district_940gzzlubec_940gzzludgy_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="939.8"
        y1="327.3"
        x2="928.3"
        y2="338.8"
      />
      <line
        id="lul-district_940gzzludgy_940gzzludge_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="952.1"
        y1="315.1"
        x2="939.8"
        y2="327.3"
      />
      <line
        id="lul-district_940gzzludge_940gzzluepk_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="967.3"
        y1="299.9"
        x2="951"
        y2="316.1"
      />
      <line
        id="lul-district_940gzzluepk_940gzzluhch_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="976.3"
        y1="290.8"
        x2="967.3"
        y2="299.9"
      />
      <line
        id="lul-district_940gzzluhch_940gzzluupb_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="986.3"
        y1="280.8"
        x2="975.3"
        y2="291.9"
      />
      <line
        id="lul-district_940gzzluupb_940gzzluupm_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="996.5"
        y1="270.6"
        x2="985.2"
        y2="281.9"
      />
      <line
        id="lul-district_940gzzlurmd_940gzzlukwg_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="254.8"
        y1="565"
        x2="254.8"
        y2="578.7"
      />
      <line
        id="lul-district_940gzzlukwg_940gzzlugby_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="254.8"
        y1="537.7"
        x2="254.8"
        y2="565"
      />
      <path
        id="lul-district_940gzzlugby_940gzzlutng_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M254.8,537.7V521c0-2.1,0.9-4,2.2-5.4c1.4-1.4,3.3-2.2,5.4-2.2c1.8,0,6.2,0,6.2,0"
      />
      <path
        id="lul-district_940gzzlueby_940gzzluecm_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M228.4,482l0-20c0-2.1-0.9-4.1-2.3-5.5c-1.4-1.4-3.3-2.3-5.5-2.3c-2,0-5.8,0-5.8,0"
      />
      <path
        id="lul-district_940gzzluecm_940gzzluact_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M234.4,507l-3.7-3.7c-1.4-1.4-2.3-3.3-2.3-5.5l0-15.8"
      />
      <path
        id="lul-district_940gzzluact_940gzzlucwp_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M250.1,513.3H244c-2.1,0-4.1-0.9-5.5-2.3l-4.1-4.1"
      />
      <line
        id="lul-district_940gzzlucwp_940gzzlutng_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="268.7"
        y1="513.3"
        x2="248.6"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzlutng_940gzzlusfb_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="290.5"
        y1="513.3"
        x2="267.2"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzlusfb_940gzzlurvp_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="316"
        y1="513.3"
        x2="289.1"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzlurvp_940gzzluhsd_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="333.3"
        y1="513.3"
        x2="314.4"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzluhsd_940gzzlubsc_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="348.9"
        y1="513.3"
        x2="333.3"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzlubsc_940gzzluwkn_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="365.2"
        y1="513.3"
        x2="347.4"
        y2="513.3"
      />
      <line
        id="lul-district_940gzzluwkn_940gzzluect_2_"
        fill="none"
        stroke="#00853F"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="382.7"
        y1="513.3"
        x2="363.7"
        y2="513.3"
      />
      {/* <g id="lul-circle_lul-district_940gzzlumsh_2_">
        <rect
          id="lul-circle_940gzzlumsh_2_"
          x="603.2"
          y="485.7"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -167.116 569.5139)"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-district_940gzzlumsh_2_"
          x="604.8"
          y="487.3"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -167.7771 571.1538)"
          fill="#00853F"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-district_940gzzlutmp">
        <rect
          id="lul-circle_940gzzlutmp"
          x="571.8"
          y="508.5"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-district_940gzzlutmp"
          x="571.8"
          y="510.7"
          fill="#00853F"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-district_940gzzlusjp">
        <rect
          id="lul-circle_940gzzlusjp"
          x="490.7"
          y="508.5"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-district_940gzzlusjp"
          x="490.7"
          y="510.7"
          fill="#00853F"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-district_940gzzlussq_2_">
        <rect
          id="lul-district_940gzzlussq_2_"
          x="448.8"
          y="514.5"
          fill="#00853F"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-circle_940gzzlussq_2_"
          x="448.8"
          y="512.2"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-district_lul-piccadilly_940gzzlugtr">
        <rect
          id="lul-circle_940gzzlugtr"
          x="406.7"
          y="508.5"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-piccadilly_940gzzlugtr_2_"
          x="406.7"
          y="503.8"
          fill="#1C3F94"
          width="1.5"
          height="1.5"
          className="blue-fill"
        />
        <rect
          id="lul-district_940gzzlugtr"
          x="406.7"
          y="510.7"
          fill="#00853F"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-district_940gzzluhsk_2_">
        <rect
          id="lul-circle_940gzzluhsk_2_"
          x="391.9"
          y="475.6"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-district_940gzzluhsk_2_"
          x="389.6"
          y="475.6"
          fill="#00853F"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-district_940gzzlubwt_2_">
        <rect
          id="lul-circle_940gzzlubwt_2_"
          x="391.9"
          y="417.4"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-district_940gzzlubwt_2_"
          x="389.6"
          y="417.4"
          fill="#00853F"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-district_lul-hammersmith-city_940gzzlusgn_2_">
        <rect
          id="lul-district_940gzzlusgn_2_"
          x="743"
          y="402.6"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -67.4011 644.023)"
          fill="#00853F"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-hammersmith-city_940gzzlusgn_2_"
          x="741.4"
          y="401"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -66.727 642.4583)"
          fill="#F386A1"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-district_lul-hammersmith-city_940gzzluplw_2_">
        <rect
          id="lul-district_940gzzluplw_2_"
          x="875.4"
          y="392.8"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -21.6747 734.8308)"
          fill="#00853F"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-hammersmith-city_940gzzluplw_2_"
          x="873.8"
          y="391.2"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -20.9961 733.2701)"
          fill="#F386A1"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-district_lul-hammersmith-city_940gzzluupk_2_">
        <rect
          id="lul-district_940gzzluupk_2_"
          x="888.2"
          y="380"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.874 740.1348)"
          fill="#00853F"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-hammersmith-city_940gzzluupk_2_"
          x="886.6"
          y="378.4"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -8.2108 738.5336)"
          fill="#F386A1"
          width="1.5"
          height="1.5"
        />
      </g>
      <rect
        id="lul-district_940gzzlubec_2_"
        x="929.4"
        y="338.8"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 32.3102 757.1652)"
        fill="#00853F"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-district_940gzzludge_2_"
        x="952.1"
        y="316.1"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 55.0048 766.5839)"
        fill="#00853F"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-district_940gzzluhch_2_"
        x="976.4"
        y="291.9"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 79.2346 776.6049)"
        fill="#00853F"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-district_940gzzluupb_2_"
        x="986.3"
        y="281.9"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 89.2015 780.7336)"
        fill="#00853F"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-district_940gzzlupsg_2_"
        x="374"
        y="578.5"
        fill="#00853F"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-district_940gzzlupyb_2_"
        x="374"
        y="588.4"
        fill="#00853F"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-district_940gzzluepy_2_"
        x="374"
        y="604.2"
        fill="#00853F"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-district_940gzzluwip_2_"
        x="374"
        y="626.5"
        fill="#00853F"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-district_940gzzlucwp_2_"
        x="248.6"
        y="514.5"
        fill="#00853F"
        width="1.5"
        height="1.5"
      />
      <g id="lul-district_lul-piccadilly_940gzzlutng_2_">
        <rect
          id="lul-piccadilly_940gzzlutng_2_"
          x="267.2"
          y="507.6"
          fill="#1C3F94"
          width="1.5"
          height="1.5"
          className="blue-fill"
        />
        <rect
          id="lul-district_940gzzlutng_2_"
          x="267.2"
          y="514.5"
          fill="#00853F"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-district_lul-piccadilly_940gzzlubsc_2_">
        <rect
          id="lul-piccadilly_940gzzlubsc_2_"
          x="347.4"
          y="503.8"
          fill="#1C3F94"
          width="1.5"
          height="1.5"
          className="blue-fill"
        />
        <rect
          id="lul-district_940gzzlubsc_2_"
          x="347.4"
          y="510.7"
          fill="#00853F"
          width="1.5"
          height="1.5"
        />
      </g>
      <rect
        id="lul-district_940gzzluwkn_2_"
        x="363.7"
        y="514.5"
        fill="#00853F"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-district_940gzzlusfb_2_"
        x="289.1"
        y="514.5"
        fill="#00853F"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-district_940gzzlurvp_2_"
        x="314.4"
        y="514.5"
        fill="#00853F"
        width="1.5"
        height="1.5"
      /> */}
    </g>
    {/* Metropolitan */}
    <g id="lul-metropolitan" className="line">
      <path
        id="lul-metropolitan_940gzzlucsm_940gzzlucal"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M109.7,147l11.5,11.5c1.2,1.2,3.7,2.3,5.4,2.3c1.7,0,17.9,0,17.9,0"
      />
      <rect
        id="lul-metropolitan_940gzzluwaf"
        x="198.1"
        y="163.8"
        fill="#97005E"
        width="5.4"
        height="1.5"
      />
      <line
        id="lul-metropolitan_940gzzluwhw_940gzzluhoh"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="250.3"
        y1="261.2"
        x2="277.3"
        y2="261.2"
      />
      <path
        id="lul-metropolitan_940gzzluryl_940gzzluwhw"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M216.7,256.2l2.7,2.7c1.2,1.2,3.7,2.3,5.4,2.3h27"
      />
      <line
        id="lul-metropolitan_940gzzlueae_940gzzluryl"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="205.9"
        y1="245.5"
        x2="216.7"
        y2="256.2"
      />
      <line
        id="lul-metropolitan_940gzzlursm_940gzzlueae"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="194.2"
        y1="233.8"
        x2="207"
        y2="246.6"
      />
      <path
        id="lul-metropolitan_940gzzlursp_940gzzlursm"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M174.2,229.4h12.1c2.1,0,4.8,1.2,6.3,2.8l2.7,2.7"
      />
      <g id="lul-metropolitan_940gzzluick_940gzzlursp_2_">
        <line
          id="lul-metropolitan_940gzzluick_940gzzlursp"
          fill="none"
          stroke="#97005E"
          strokeWidth="2.2707"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          x1="163.4"
          y1="229.4"
          x2="175.7"
          y2="229.4"
        />
        <line
          id="lul-metropolitan_940gzzluick_940gzzlursp"
          fill="none"
          stroke="#97005E"
          strokeWidth="2.2707"
          strokeLinejoin="round"
          strokeMiterlimit="3.9938"
          x1="147.3"
          y1="229.4"
          x2="160.4"
          y2="229.4"
        />
      </g>
      <line
        id="lul-metropolitan_940gzzluhgd_940gzzluick"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="132.2"
        y1="229.4"
        x2="148.8"
        y2="229.4"
      />
      <line
        id="lul-metropolitan_940gzzluuxb_940gzzluhgd"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="112"
        y1="229.4"
        x2="132.2"
        y2="229.4"
      />
      <line
        id="lul-metropolitan_940gzzluams_940gzzlucal"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="144.3"
        y1="160.7"
        x2="92.7"
        y2="160.7"
      />
      <path
        id="lul-metropolitan_940gzzlulvt_940gzzluald"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M661.3,425.2h25.3c1.5,0,2.9,0.6,3.9,1.6c1,1,1.6,2.4,1.6,3.9v14.4"
      />
      <line
        id="lul-metropolitan_940gzzlumgt_940gzzlulvt"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="636.7"
        y1="425.2"
        x2="661.3"
        y2="425.2"
      />
      <path
        id="lul-metropolitan_940gzzlubbn_940gzzlumgt"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M613.8,410.4l11.9,11.9c1.9,1.9,4.4,2.9,7.1,2.9h3.9"
      />
      <line
        id="lul-metropolitan_940gzzlufcn_940gzzlubbn"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="600.4"
        y1="397.1"
        x2="614.8"
        y2="411.5"
      />
      <path
        id="lul-metropolitan_940gzzluksx_940gzzlufcn"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M578,383.6l6.7,0c1.5,0,2.9,0.6,3.9,1.6l11.9,11.9"
      />
      <line
        id="lul-metropolitan_940gzzluesq_940gzzluksx"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="556.3"
        y1="383.6"
        x2="578"
        y2="383.6"
      />
      <line
        id="lul-metropolitan_940gzzlugps_940gzzluesq"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="500.4"
        y1="383.6"
        x2="557.8"
        y2="383.6"
      />
      <path
        id="lul-metropolitan_940gzzlubst_940gzzlugps"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M471.2,380.6l0.7,0.7c1.2,1.2,3.8,2.1,5.4,2.3l24.5,0"
      />
      <line
        id="lul-metropolitan_940gzzlufyr_940gzzlubst"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="435"
        y1="344.4"
        x2="471.2"
        y2="380.6"
      />
      <line
        id="lul-metropolitan_940gzzluwyp_940gzzlufyr"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="369.8"
        y1="279.2"
        x2="435"
        y2="344.4"
      />
      <path
        id="lul-metropolitan_940gzzluprd_940gzzluwyp"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M337.2,261.2l11.5,0c1.8,0,4.2,1,5.4,2.2l15.7,15.7"
      />
      <line
        id="lul-metropolitan_940gzzlunkp_940gzzluprd"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="303.5"
        y1="261.2"
        x2="338.7"
        y2="261.2"
      />
      <line
        id="lul-metropolitan_940gzzluhoh_940gzzlunkp"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="277.2"
        y1="261.2"
        x2="305"
        y2="261.2"
      />
      <path
        id="lul-metropolitan_940gzzlunha_940gzzluhoh"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M248.9,242.8L265,259c1.2,1.2,3.7,2.3,5.4,2.3l6.8,0"
      />
      <line
        id="lul-metropolitan_940gzzlupnr_940gzzlunha"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="239.9"
        y1="233.9"
        x2="250"
        y2="243.9"
      />
      <line
        id="lul-metropolitan_940gzzlunwh_940gzzlupnr"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="227.8"
        y1="221.7"
        x2="239.9"
        y2="233.9"
      />
      <line
        id="lul-metropolitan_940gzzlunow_940gzzlunwh"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="217"
        y1="210.9"
        x2="228.8"
        y2="222.7"
      />
      <line
        id="lul-metropolitan_940gzzlumpk_940gzzlunow"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="206.2"
        y1="200.1"
        x2="218"
        y2="211.9"
      />
      <line
        id="lul-metropolitan_940gzzlurkw_940gzzlumpk"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="190.7"
        y1="184.6"
        x2="207.2"
        y2="201.1"
      />
      <line
        id="lul-metropolitan_940gzzlucyd_940gzzlurkw"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="177.2"
        y1="171.1"
        x2="191.8"
        y2="185.7"
      />
      <path
        id="lul-metropolitan_940gzzlucal_940gzzlucyd"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M144.3,160.7h19.4c1.8,0,4.2,1,5.4,2.3l8.1,8.1"
      />
      <path
        id="lul-metropolitan_940gzzlucxy_940gzzlumpk"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        d=" M200.7,178.4l0,8.6l0,4.4c0,1.8,1,4.2,2.3,5.4c1,1,4.3,4.3,4.3,4.3"
      />
      <line
        id="lul-metropolitan_940gzzluwaf_940gzzlucxy"
        fill="none"
        stroke="#97005E"
        strokeWidth="2.2707"
        strokeLinejoin="round"
        strokeMiterlimit="3.9938"
        x1="200.7"
        y1="163.8"
        x2="200.7"
        y2="179.9"
      />
      {/* <rect
        id="lul-metropolitan_940gzzlucxy"
        x="201.8"
        y="178.4"
        fill="#97005E"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-metropolitan_940gzzlurkw"
        x="189.2"
        y="185.7"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -76.2375 188.9303)"
        fill="#97005E"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-metropolitan_940gzzlumpk"
        x="207.3"
        y="198.5"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -79.9787 205.477)"
        fill="#97005E"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-metropolitan_940gzzlunow"
        x="218.1"
        y="209.3"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -84.4371 216.2676)"
        fill="#97005E"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-metropolitan_940gzzlunwh"
        x="228.9"
        y="220.1"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -88.9121 227.0678)"
        fill="#97005E"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-metropolitan_940gzzlunha"
        x="250"
        y="241.3"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -97.6894 248.1952)"
        fill="#97005E"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-metropolitan_940gzzluwhw"
        x="250.3"
        y="262.3"
        fill="#97005E"
        width="1.5"
        height="1.5"
      />
      <rect
        id="lul-metropolitan_940gzzlunkp"
        x="303.5"
        y="262.3"
        fill="#97005E"
        width="1.5"
        height="1.5"
      />
      <g id="lul-metropolitan_lul-piccadilly_940gzzluick">
        <rect
          id="lul-metropolitan_940gzzluick"
          x="147.3"
          y="230.5"
          fill="#97005E"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-piccadilly_940gzzluick"
          x="147.3"
          y="232.8"
          fill="#1C3F94"
          width="1.5"
          height="1.5"
          className="blue-fill"
        />
      </g>
      <g id="lul-metropolitan_lul-piccadilly_940gzzlursp">
        <rect
          id="lul-metropolitan_940gzzlursp"
          x="174.2"
          y="226.8"
          fill="#97005E"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-piccadilly_940gzzlursp"
          x="174.2"
          y="229.1"
          fill="#1C3F94"
          width="1.5"
          height="1.5"
          className="blue-fill"
        />
      </g>
      <g id="lul-metropolitan_lul-piccadilly_940gzzlursm">
        <rect
          id="lul-metropolitan_940gzzlursm"
          x="195.3"
          y="232.2"
          transform="matrix(0.707 -0.7072 0.7072 0.707 -107.3249 206.9091)"
          fill="#97005E"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-piccadilly_940gzzlursm"
          x="193.7"
          y="233.9"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -108.9358 206.216)"
          fill="#1C3F94"
          width="1.5"
          height="1.5"
          className="blue-fill"
        />
      </g>
      <g id="lul-metropolitan_lul-piccadilly_940gzzlueae">
        <rect
          id="lul-metropolitan_940gzzlueae"
          x="207"
          y="244"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -112.1917 218.6145)"
          fill="#97005E"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-piccadilly_940gzzlueae"
          x="205.4"
          y="245.6"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -113.8037 217.9344)"
          fill="#1C3F94"
          width="1.5"
          height="1.5"
          className="blue-fill"
        />
      </g>
      <rect
        id="lul-metropolitan_940gzzluprd_2_"
        x="337.2"
        y="258.6"
        fill="#97005E"
        width="1.5"
        height="1.5"
      />
      <g id="lul-circle_lul-hammersmith-city_lul-metropolitan_940gzzlugps">
        <rect
          id="lul-hammersmith-city_940gzzlugps"
          x="500.4"
          y="376.4"
          fill="#F386A1"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-circle_940gzzlugps"
          x="500.4"
          y="378.7"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-metropolitan_940gzzlugps"
          x="500.4"
          y="381"
          fill="#97005E"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-hammersmith-city_lul-metropolitan_940gzzluesq">
        <rect
          id="lul-metropolitan_940gzzluesq"
          x="556.3"
          y="384.7"
          fill="#97005E"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-circle_940gzzluesq"
          x="556.3"
          y="382.4"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-hammersmith-city_940gzzluesq"
          x="556.3"
          y="380.2"
          fill="#F386A1"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-metropolitan_940gzzluald">
        <rect
          id="lul-metropolitan_940gzzluald"
          x="689.4"
          y="443.6"
          fill="#97005E"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-circle_940gzzluald"
          x="691.7"
          y="443.6"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
      </g>
      <g id="lul-circle_lul-hammersmith-city_lul-metropolitan_940gzzlubbn">
        <rect
          id="lul-metropolitan_940gzzlubbn"
          x="612.2"
          y="411.5"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -111.9566 554.187)"
          fill="#97005E"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-circle_940gzzlubbn"
          x="613.8"
          y="409.9"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -110.3403 554.8831)"
          fill="#FFD200"
          width="1.5"
          height="1.5"
        />
        <rect
          id="lul-hammersmith-city_940gzzlubbn"
          x="615.4"
          y="408.3"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -108.7449 555.5173)"
          fill="#F386A1"
          width="1.5"
          height="1.5"
        />
      </g> */}
    </g>
  </>
)

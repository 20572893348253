import React, { FC } from "react"

const interchangeCircles: FC = () => (
  <g id="interchange-circles">
    <g id="lul-northern_940gzzlucpn_raillo-overground_910gclphhs_1_">
      <g id="lul-northern_940gzzlucpn_raillo-overground_910gclphhs_2_">
        <path d="M498.8,651.4v4.5c-1,0.6-1.7,1.7-1.7,3c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4c0-1.3-0.7-2.4-1.7-3v-4.5 c1-0.6,1.7-1.7,1.7-3c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4C497.1,649.7,497.7,650.8,498.8,651.4z" />
      </g>
      <path
        id="raillo-overground_910gclphhs"
        d="M503.9,648.5c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4 C502.3,645.1,503.9,646.6,503.9,648.5z"
      />
      <path
        id="lul-northern_940gzzlucpn_1_"
        d="M503.9,658.9c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4 C502.3,655.5,503.9,657,503.9,658.9z"
      />
      <path
        id="lul-northern_940gzzlucpn_raillo-overground_910gclphhs"
        fill="#FFFFFF"
        d="M498.2,648.5 c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,1.1-0.7,1.9-1.7,2.2v6c1,0.3,1.7,1.1,1.7,2.2c0,1.3-1,2.3-2.3,2.3 c-1.3,0-2.3-1-2.3-2.3c0-1.1,0.7-2,1.7-2.2v-6C498.9,650.4,498.2,649.5,498.2,648.5z"
        className="white-fill"
      />
    </g>
    <g id="s-940gzzdlsit_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M818.3,312.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C814.9,313.8,816.4,312.3,818.3,312.3z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M817.2,316.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L817.2,316.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M818.4,313.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C818.6,312.9,818.4,313.1,818.4,313.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M818.6,314.3l0,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C818.7,314.1,818.6,314.3,818.6,314.3z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-jubilee_940gzzlungw_cab-emirates-air-line-9400zzalgwp">
      <g id="lul-jubilee_940gzzlungw_cab-emirates-air-line-9400zzalgwp_1_">
        <rect x="832.8" y="506.8" width="12.9" height="3.4" />
        <rect
          x="832.6"
          y="507.9"
          transform="matrix(1 -1.016544e-03 1.016544e-03 1 -0.5165 0.8532)"
          fill="#FFFFFF"
          width="13"
          height="1.1"
          className="white-fill"
        />
      </g>
      <g id="lul-jubilee_940gzzlungw_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M834.7,505.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C831.3,506.6,832.8,505.1,834.7,505.1z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M833.5,509.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L833.5,509.5z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M834.7,506.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C835,505.7,834.7,505.9,834.7,506.2z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M835,507.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 h1.3c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C835.1,506.9,835,507.1,835,507.1z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="cab-emirates-air-line-9400zzalgwp_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M844.7,505.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C841.3,506.6,842.8,505.1,844.7,505.1z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M843.5,509.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L843.5,509.5z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M844.7,506.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C845,505.7,844.7,505.9,844.7,506.2z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M845,507.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 h1.3c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C845.1,506.9,845,507.1,845,507.1z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-jubilee_940gzzlucgt_dlr-dlr_940gzzdlcgt_3_">
      <g id="lul-jubilee_940gzzlucgt_dlr-dlr_940gzzdlcgt_5_">
        <rect
          x="844.9"
          y="461.4"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -78.2822 737.1635)"
          width="11.5"
          height="3.4"
        />
        <rect
          x="845"
          y="462.4"
          transform="matrix(0.7064 -0.7078 0.7078 0.7064 -77.906 738.1476)"
          fill="#FFFFFF"
          width="11.6"
          height="1.1"
          className="white-fill"
        />
      </g>
      <g id="lul-jubilee_940gzzlucgt_dlr-dlr_940gzzdlcgt_4_">
        <g>
          <path
            fill="#1C3F94"
            d="M846.5,463.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C843.1,465.5,844.6,463.9,846.5,463.9z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M845.3,468.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L845.3,468.4z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M846.5,465.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C846.8,464.6,846.5,464.8,846.5,465.1z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M846.8,466l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C846.9,465.8,846.8,466,846.8,466z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="dlr-dlr_940gzzdlcgt_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M855.1,455.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C851.7,456.8,853.2,455.3,855.1,455.3z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M853.9,459.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L853.9,459.7z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M855.2,456.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C855.4,456,855.2,456.2,855.2,456.5z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M855.4,457.3l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C855.5,457.1,855.4,457.3,855.4,457.3z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="dlr-dlr_940gzzdlrvc_cab-emirates-air-line-9400zzalrdk">
      <g id="dlr-dlr_940gzzdlrvc_cab-emirates-air-line-9400zzalrdk_1_">
        <rect x="893.7" y="465.8" width="3.4" height="12.9" />
        <rect
          x="894.9"
          y="465.6"
          transform="matrix(1 -1.016544e-03 1.016544e-03 1 -0.4794 0.9105)"
          fill="#FFFFFF"
          width="1.1"
          height="13"
          className="white-fill"
        />
      </g>
      <g id="dlr-dlr_940gzzdlrvc_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M895.2,464c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C891.8,465.6,893.3,464,895.2,464z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M894.1,468.4c0.2,0.5,0.7,0.9,1.3,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.9,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L894.1,468.4z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M895.3,465.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C895.5,464.6,895.3,464.9,895.3,465.1z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M895.6,466l0,0.4l-1.1,0c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2l1,0l0,0.3l-1.4,0c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.7,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C895.6,465.8,895.6,466,895.6,466z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="cab-emirates-air-line-9400zzalrdk_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M895.3,473c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C891.8,474.5,893.4,473,895.3,473z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M894.1,477.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L894.1,477.4z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M895.3,474.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C895.5,473.6,895.3,473.8,895.3,474.1z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M895.6,475l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H894c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C895.7,474.8,895.6,475,895.6,475z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-940gzzlubxn_1_" data-linestop="victoria">
      <g>
        <path
          fill="#1C3F94"
          d="M538.3,665.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C534.9,666.7,536.4,665.2,538.3,665.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M537.1,669.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L537.1,669.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M538.3,666.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C538.6,665.8,538.3,666.1,538.3,666.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M538.6,667.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C538.7,667,538.6,667.2,538.6,667.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluhr4_1_" data-linestop="piccadilly">
      <g>
        <path
          fill="#1C3F94"
          d="M129.3,621.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C125.9,623,127.4,621.5,129.3,621.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M128.1,625.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L128.1,625.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M129.3,622.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C129.6,622.2,129.3,622.4,129.3,622.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M129.6,623.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0h1.3 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C129.7,623.3,129.6,623.6,129.6,623.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910ghtrwtm4" data-linestop="tfl-rail">
      <g>
        <path
          fill="#1C3F94"
          d="M129.2,613.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C125.8,615.2,127.4,613.6,129.2,613.6z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M128.1,618.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L128.1,618.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M129.3,614.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C129.5,614.3,129.3,614.5,129.3,614.8z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M129.6,615.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H128c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0h1.3 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C129.7,615.5,129.6,615.7,129.6,615.7z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluhrc_1_" data-linestop="piccadilly">
      <g>
        <path
          fill="#1C3F94"
          d="M134.8,575.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C131.4,577.2,132.9,575.7,134.8,575.7z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M133.7,580.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L133.7,580.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M134.9,576.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5 C135.1,576.4,134.9,576.6,134.9,576.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M135.2,577.8l-0.1,0.4H134c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C135.2,577.5,135.2,577.8,135.2,577.8z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910ghtrwapt" data-linestop="tfl-rail">
      <g>
        <path
          fill="#1C3F94"
          d="M129.3,570.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C125.9,571.7,127.4,570.2,129.3,570.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M128.2,574.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L128.2,574.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M129.4,571.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C129.6,570.9,129.4,571.1,129.4,571.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M129.7,572.3l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C129.7,572,129.7,572.3,129.7,572.3z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluhwt_1_" data-linestop="piccadilly">
      <g>
        <path
          fill="#1C3F94"
          d="M157.4,553.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C154,554.7,155.5,553.1,157.4,553.1z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M156.2,557.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L156.2,557.5z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M157.5,554.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C157.7,553.8,157.5,554,157.5,554.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M157.7,555.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C157.8,554.9,157.7,555.2,157.7,555.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzlutmh_1_" data-linestop="victoria">
      <g>
        <path
          fill="#1C3F94"
          d="M730.3,260.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C726.8,261.7,728.4,260.2,730.3,260.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M729.1,264.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L729.1,264.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M730.3,261.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C730.5,260.8,730.3,261,730.3,261.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M730.6,262.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H729c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C730.7,262,730.6,262.2,730.6,262.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910ghaggers_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M723.9,357.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C720.5,358.7,722,357.2,723.9,357.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M722.8,361.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L722.8,361.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M724,358.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C724.2,357.8,724,358,724,358.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M724.2,359.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C724.3,359,724.2,359.2,724.2,359.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910ghoxton_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M723.9,372c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C720.5,373.5,722,372,723.9,372z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M722.8,376.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L722.8,376.4z"
            className="white-fill"
          />
          <circle
            fill="#FFFFFF"
            cx="724.5"
            cy="373.1"
            r="0.5"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M724.2,374l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C724.3,373.8,724.2,374,724.2,374z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gshrdhst_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M723.9,397.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C720.5,398.7,722,397.2,723.9,397.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M722.8,401.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L722.8,401.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M724,398.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C724.2,397.8,724,398,724,398.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M724.2,399.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C724.3,399,724.2,399.2,724.2,399.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-district_lul-hammersmith-city_940gzzlubwr_dlr-dlr_940gzzdlbow">
      <g id="s-940gzzlubwr_1_" data-linestop="hammersmith-city">
        <path d="M801.5,398.7l-5.1,5.1c-1.1-0.3-2.4,0-3.3,0.9c-1.3,1.3-1.3,3.5,0,4.8c1.3,1.3,3.5,1.3,4.8,0c0.9-0.9,1.2-2.2,0.9-3.3 l5.1-5.1c1.1,0.3,2.4,0,3.3-0.9c1.3-1.3,1.3-3.5,0-4.8c-1.3-1.3-3.5-1.3-4.8,0C801.5,396.3,801.2,397.6,801.5,398.7z" />
        <path d="M807.2,400.2c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0C808.6,396.7,808.6,398.9,807.2,400.2 z" />
        <path
          fill="#FFFFFF"
          d="M803.2,396.2c0.9-0.9,2.3-0.9,3.2,0c0.9,0.9,0.9,2.3,0,3.2c-0.8,0.8-1.9,0.9-2.8,0.3l-6.2,6.2 c0.5,0.9,0.4,2-0.3,2.8c-0.9,0.9-2.3,0.9-3.2,0c-0.9-0.9-0.9-2.3,0-3.2c0.8-0.8,1.9-0.9,2.8-0.3l6.2-6.2 C802.4,398.1,802.5,397,803.2,396.2z"
          className="white-fill"
        />
      </g>
      <g id="s-940gzzdlbow_1_" data-linestop="dlr">
        <g>
          <path
            fill="#1C3F94"
            d="M795.5,403.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,405.3,793.6,403.7,795.5,403.7z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M794.4,408.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L794.4,408.1z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M795.6,404.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,404.4,795.6,404.6,795.6,404.9z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M795.9,405.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,405.5,795.9,405.8,795.9,405.8z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-910gdals_2_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M716.7,338.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C713.3,340.3,714.8,338.7,716.7,338.7z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M715.5,343.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L715.5,343.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M716.7,339.9c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5S716.7,339.6,716.7,339.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M717,340.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l-0.1,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C717.1,340.6,717,340.8,717,340.8z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlpud_2_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M825.6,364.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C822.2,365.7,823.8,364.2,825.6,364.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M824.5,368.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L824.5,368.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M825.7,365.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C825.9,364.9,825.7,365.1,825.7,365.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M826,366.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C826,366,826,366.2,826,366.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdldev_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,423.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,424.8,793.6,423.3,795.5,423.3z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.4,427.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L794.4,427.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.6,424.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,423.9,795.6,424.2,795.6,424.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,425.3l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,425.1,795.8,425.3,795.8,425.3z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlldp_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,435c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,436.5,793.6,435,795.5,435z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.4,439.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L794.4,439.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.6,436.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,435.6,795.6,435.8,795.6,436.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,437l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,436.8,795.8,437,795.8,437z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlall_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,446.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,448.3,793.6,446.7,795.5,446.7z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.4,451.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L794.4,451.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.6,447.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,447.4,795.6,447.6,795.6,447.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,448.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,448.5,795.8,448.8,795.8,448.8z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlpop_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,464c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,465.5,793.7,464,795.5,464z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.4,468.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L794.4,468.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.6,465.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,464.6,795.6,464.8,795.6,465.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.9,466l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,465.8,795.9,466,795.9,466z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdllim_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M753.4,464c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C750,465.5,751.5,464,753.4,464z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M752.3,468.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L752.3,468.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M753.5,465.1c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5S753.5,464.8,753.5,465.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M753.7,466l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C753.8,465.8,753.7,466,753.7,466z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlwfe_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M772.7,464c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C769.3,465.5,770.9,464,772.7,464z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M771.6,468.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L771.6,468.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M772.8,465.1c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5S772.8,464.8,772.8,465.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M773.1,466l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C773.1,465.8,773.1,466,773.1,466z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlbla_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M810.7,464c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C807.3,465.5,808.8,464,810.7,464z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M809.6,468.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L809.6,468.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M810.8,465.1c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5S810.8,464.8,810.8,465.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M811,466l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C811.1,465.8,811,466,811,466z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlein_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M831.4,464c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C828,465.5,829.5,464,831.4,464z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M830.3,468.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L830.3,468.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M831.5,465.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C831.7,464.6,831.5,464.8,831.5,465.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M831.7,466l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C831.8,465.8,831.7,466,831.7,466z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlcus_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M928.4,474.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C925,475.9,926.6,474.3,928.4,474.3z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M927.3,478.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L927.3,478.8z"
            className="white-fill"
          />
          <circle
            fill="#FFFFFF"
            cx="929"
            cy="475.5"
            r="0.5"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M928.8,476.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C928.8,476.2,928.8,476.4,928.8,476.4z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlpre_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M941.1,487c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C937.6,488.5,939.2,487,941.1,487z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M939.9,491.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L939.9,491.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M941.1,488.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C941.3,487.6,941.1,487.9,941.1,488.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M941.4,489l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C941.5,488.8,941.4,489,941.4,489z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlral_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M953.7,499.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C950.3,501.1,951.8,499.6,953.7,499.6z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M952.6,504c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L952.6,504z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M953.8,500.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C954,500.3,953.8,500.5,953.8,500.8z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M954,501.6L954,502h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0h1.3 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C954.1,501.4,954,501.6,954,501.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlbpk_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M966.4,512.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C963,513.8,964.5,512.2,966.4,512.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M965.2,516.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L965.2,516.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M966.4,513.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C966.7,512.9,966.4,513.1,966.4,513.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M966.7,514.3l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C966.8,514,966.7,514.3,966.7,514.3z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlcyp_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M979,524.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C975.6,526.4,977.1,524.9,979,524.9z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M977.8,529.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L977.8,529.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M979,526c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C979.2,525.5,979,525.7,979,526z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M979.3,526.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C979.4,526.7,979.3,526.9,979.3,526.9z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlgal_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M913.5,512.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C910.1,513.7,911.6,512.1,913.5,512.1z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M912.3,516.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L912.3,516.5z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M913.5,513.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C913.8,512.8,913.5,513,913.5,513.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M913.8,514.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C913.9,514,913.8,514.2,913.8,514.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlbec_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M929.4,528.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C926,529.6,927.5,528.1,929.4,528.1z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M928.3,532.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L928.3,532.5z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M929.5,529.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C929.7,528.7,929.5,528.9,929.5,529.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M929.8,530.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C929.8,529.9,929.8,530.1,929.8,530.1z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlwla_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M950.9,579.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C947.5,581.3,949,579.8,950.9,579.8z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M949.7,584.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L949.7,584.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M950.9,581c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C951.2,580.5,950.9,580.7,950.9,581z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M951.2,581.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C951.3,581.6,951.2,581.8,951.2,581.8z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlkgv_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M945.3,544c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C941.9,545.5,943.4,544,945.3,544z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M944.2,548.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L944.2,548.4z"
            className="white-fill"
          />
          <circle
            fill="#FFFFFF"
            cx="945.9"
            cy="545.1"
            r="0.5"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M945.7,546l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C945.7,545.8,945.7,546,945.7,546z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdllca_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M929.4,528.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C926,529.6,927.5,528.1,929.4,528.1z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M928.3,532.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L928.3,532.5z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M929.5,529.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C929.7,528.7,929.5,528.9,929.5,529.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M929.8,530.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C929.8,529.9,929.8,530.1,929.8,530.1z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlpdk_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M913.5,512.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C910.1,513.7,911.6,512.1,913.5,512.1z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M912.3,516.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L912.3,516.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M913.5,513.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C913.8,512.8,913.5,513,913.5,513.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M913.8,514.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C913.9,514,913.8,514.2,913.8,514.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlwsv_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M898.4,497c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C895,498.6,896.5,497,898.4,497z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M897.2,501.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L897.2,501.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M898.4,498.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C898.7,497.7,898.4,497.9,898.4,498.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M898.7,499.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C898.8,498.8,898.7,499.1,898.7,499.1z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlelv_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,622.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,624.5,793.6,622.9,795.5,622.9z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.3,627.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L794.3,627.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.6,624.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,623.6,795.6,623.8,795.6,624.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,625l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,624.8,795.8,625,795.8,625z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdldep_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,611.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,613,793.6,611.5,795.5,611.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.3,615.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L794.3,615.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.6,612.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,612.1,795.6,612.3,795.6,612.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,613.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,613.3,795.8,613.5,795.8,613.5z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlgre_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,600c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,601.5,793.6,600,795.5,600z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.3,604.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L794.3,604.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.6,601.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5 C795.8,600.6,795.6,600.8,795.6,601.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,602l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,601.8,795.8,602,795.8,602z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlcut_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,587.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,588.8,793.6,587.2,795.5,587.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.3,591.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L794.3,591.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.6,588.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,587.9,795.6,588.1,795.6,588.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,589.3l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,589.1,795.8,589.3,795.8,589.3z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlisl_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,567c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,568.5,793.6,567,795.5,567z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.4,571.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L794.4,571.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.6,568.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,567.7,795.6,567.9,795.6,568.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,569.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,568.8,795.8,569.1,795.8,569.1z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlmud_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,555.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,557.1,793.6,555.6,795.5,555.6z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.4,560c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L794.4,560z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.6,556.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,556.2,795.6,556.5,795.6,556.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,557.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,557.4,795.8,557.6,795.8,557.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlcla_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,544.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,545.7,793.6,544.2,795.5,544.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.4,548.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L794.4,548.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.6,545.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,544.8,795.6,545,795.6,545.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,546.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0h1.3 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,546,795.8,546.2,795.8,546.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlsoq_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,532.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,534.3,793.6,532.7,795.5,532.7z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.4,537.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L794.4,537.1z"
            className="white-fill"
          />
          <circle
            fill="#FFFFFF"
            cx="796.1"
            cy="533.9"
            r="0.5"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,534.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,534.6,795.8,534.8,795.8,534.8z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlheq_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,521.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,522.8,793.7,521.3,795.5,521.3z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.4,525.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L794.4,525.7z"
            className="white-fill"
          />
          <circle
            fill="#FFFFFF"
            cx="796.1"
            cy="522.5"
            r="0.5"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.9,523.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,523.1,795.9,523.4,795.9,523.4z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlwiq_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,486.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,488,793.6,486.5,795.5,486.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.4,490.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L794.4,490.9z"
            className="white-fill"
          />
          <circle
            fill="#FFFFFF"
            cx="796.1"
            cy="487.6"
            r="0.5"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,488.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,488.3,795.8,488.5,795.8,488.5z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdllew_1_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M795.5,634.4c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,635.9,793.6,634.4,795.5,634.4z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M794.3,638.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L794.3,638.8z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.6,635.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,635,795.6,635.3,795.6,635.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M795.8,636.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,636.2,795.8,636.4,795.8,636.4z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <path
      id="lul-circle_lul-district_940gzzlutwh_1_"
      d="M685.1,471.6c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8 c1.3-1.3,3.5-1.3,4.8,0C686.4,468.1,686.4,470.2,685.1,471.6z"
    />
    <g id="s-940gzzlubmy_1_" data-linestop="jubilee">
      <g>
        <path
          fill="#1C3F94"
          d="M681.7,510c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C678.3,511.5,679.8,510,681.7,510z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M680.6,514.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L680.6,514.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M681.8,511.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C682,510.6,681.8,510.9,681.8,511.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M682,512l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C682.1,511.8,682,512,682,512z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluswk_1_" data-linestop="jubilee">
      <g>
        <path
          fill="#1C3F94"
          d="M565.8,544.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C562.3,546.3,563.9,544.8,565.8,544.8z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M564.6,549.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L564.6,549.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M565.8,545.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C566,545.4,565.8,545.6,565.8,545.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M566.1,546.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C566.2,546.6,566.1,546.8,566.1,546.8z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlshs_2_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M854.8,356.4c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C851.3,357.9,852.9,356.4,854.8,356.4z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M853.6,360.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L853.6,360.8z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M854.8,357.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C855,357.1,854.8,357.3,854.8,357.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M855.1,358.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l-0.1,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C855.2,358.2,855.1,358.5,855.1,358.5z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="dlr-dlr_940gzzdlabr_2_">
      <g>
        <path
          fill="#1C3F94"
          d="M854.8,374.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C851.4,375.8,852.9,374.3,854.8,374.3z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M853.6,378.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L853.6,378.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M854.8,375.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C855.1,374.9,854.8,375.2,854.8,375.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M855.1,376.3l-0.1,0.4H854c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C855.2,376.1,855.1,376.3,855.1,376.3z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlstl_2_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M855.2,425.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C851.8,427.1,853.3,425.6,855.2,425.6z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M854,430c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L854,430z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M855.2,426.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C855.5,426.2,855.2,426.4,855.2,426.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M855.5,427.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H854c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C855.6,427.4,855.5,427.6,855.5,427.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-jubilee_lul-piccadilly_lul-victoria_940gzzlugpk_1_">
      <g>
        <path
          fill="#1C3F94"
          d="M475.8,461.4c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4S473.9,461.4,475.8,461.4z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M474.6,465.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L474.6,465.8z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M475.8,462.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C476,462,475.8,462.3,475.8,462.5z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M476.1,463.4l-0.1,0.4H475c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0h1.3 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C476.2,463.2,476.1,463.4,476.1,463.4z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-circle_lul-district_lul-central_lul-northern_lul-waterloo-city_940gzzlubnk_dlr-dlr_940gzzdlbnk">
      <path
        id="lul-circle_lul-district_lul-central_lul-northern_lul-waterloo-city_940gzzlubnk_dlr-dlr_940gzzdlbnk_1_"
        d=" M646.1,466.9c-0.9-0.9-2.1-1.3-3.2-1l-3.2-3.1c0.1-0.6,0.1-1,0.1-1.3l2-2c1.1,0.3,2.4,0,3.3-0.9c1.3-1.3,1.3-3.5,0-4.8 c-1.3-1.3-3.5-1.3-4.8,0c-0.9,0.9-1.2,2.1-0.9,3.3l-1.7,1.7c-0.7-0.3-1.4-0.3-2.1-0.2l-5.6-5.6c0.4-1.1,0.1-2.4-0.7-3.3 c-1.3-1.4-3.4-1.5-4.8-0.2c-1.4,1.3-1.5,3.4-0.2,4.8c0.9,0.9,2.1,1.3,3.2,1l5.6,5.6c-0.4,1.1-0.1,2.4,0.7,3.3 c0.9,0.9,2.1,1.3,3.2,1l3.2,3.1c-0.4,1.1-0.1,2.4,0.7,3.3c1.3,1.4,3.4,1.5,4.8,0.2C647.3,470.5,647.4,468.3,646.1,466.9z"
      />
      <path
        id="lul-central_lul-waterloo-city_940gzzlubnk_1_"
        d="M629.3,449.4c1.3,1.3,1.3,3.5,0,4.8c-1.3,1.3-3.5,1.3-4.8,0 c-1.3-1.3-1.3-3.5,0-4.8C625.8,448.1,627.9,448.1,629.3,449.4z"
      />
      <path
        id="lul-northern_940gzzlubnk_1_"
        d="M638.8,459.3c1.3,1.3,1.3,3.5,0,4.8c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8 C635.3,458,637.5,458,638.8,459.3z"
      />
      <path
        id="lul-circle_lul-district_940gzzlummt_1_"
        d="M646,466.8c1.3,1.3,1.3,3.5,0,4.8c-1.3,1.3-3.5,1.3-4.8,0 c-1.3-1.3-1.3-3.5,0-4.8C642.5,465.5,644.7,465.5,646,466.8z"
      />
      <path
        fill="#FFFFFF"
        d="M645.3,467.7c-0.7-0.8-1.8-0.9-2.7-0.5l-4.2-4.2c0.1-0.2,0.2-0.4,0.3-0.7c0.1-0.2,0.1-0.8,0-1.1 c0-0.1-0.1-0.1-0.1-0.2l3-3c0.9,0.5,2,0.4,2.8-0.3c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0c-0.7,0.7-0.9,1.9-0.4,2.7 l-2.8,2.8c-0.7-0.6-1.7-0.7-2.5-0.3l-6.6-6.7c0.6-0.8,0.5-2-0.2-2.8c-0.8-0.9-2.3-1-3.2-0.1c-0.9,0.8-1,2.3-0.1,3.2 c0.7,0.8,1.8,0.9,2.7,0.5l6.6,6.7c-0.6,0.8-0.5,2,0.2,2.8c0.7,0.8,1.8,0.9,2.7,0.5l4.2,4.2c-0.5,0.8-0.5,2,0.2,2.8 c0.8,0.9,2.3,1,3.2,0.1C646.1,470,646.1,468.6,645.3,467.7z"
        className="white-fill"
      />
      <g id="dlr-dlr_940gzzdlbnk_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M642.7,452.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C639.3,454.2,640.8,452.7,642.7,452.7z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M641.5,457.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L641.5,457.1z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M642.7,453.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C642.9,453.4,642.7,453.6,642.7,453.9z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M643,454.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C643.1,454.5,643,454.8,643,454.8z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-940gzzlucal_1_" data-linestop="metropolitan">
      <g>
        <path
          fill="#1C3F94"
          d="M144.4,157.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C141,158.9,142.5,157.3,144.4,157.3z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M143.2,161.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L143.2,161.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M144.4,158.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C144.7,158,144.4,158.2,144.4,158.5z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M144.7,159.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C144.8,159.1,144.7,159.4,144.7,159.4z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzlucyd_1_" data-linestop="metropolitan">
      <g>
        <path
          fill="#1C3F94"
          d="M177.1,167.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C173.7,169.5,175.2,167.9,177.1,167.9z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M175.9,172.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L175.9,172.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M177.2,169.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C177.4,168.6,177.2,168.8,177.2,169.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M177.4,170l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C177.5,169.8,177.4,170,177.4,170z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzlupnr_1_" data-linestop="metropolitan">
      <g>
        <path
          fill="#1C3F94"
          d="M239.9,230.4c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C236.5,232,238.1,230.4,239.9,230.4z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M238.8,234.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L238.8,234.8z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M240,231.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C240.2,231.1,240,231.3,240,231.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M240.3,232.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C240.3,232.3,240.3,232.5,240.3,232.5z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluhcl_1_" data-linestop="northern">
      <g>
        <path
          fill="#1C3F94"
          d="M473.7,241.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C470.2,243.3,471.8,241.8,473.7,241.8z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M472.5,246.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L472.5,246.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M473.7,242.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C473.9,242.4,473.7,242.7,473.7,242.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M474,243.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C474.1,243.6,474,243.8,474,243.8z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluegw_1_" data-linestop="northern">
      <g>
        <path
          fill="#1C3F94"
          d="M436.1,204.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C432.7,205.8,434.2,204.3,436.1,204.3z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M435,208.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L435,208.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M436.2,205.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C436.4,204.9,436.2,205.1,436.2,205.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M436.5,206.3l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C436.5,206.1,436.5,206.3,436.5,206.3z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzlukby_1_" data-linestop="jubilee">
      <g>
        <path
          fill="#1C3F94"
          d="M369.9,253.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C366.5,255,368,253.5,369.9,253.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M368.8,257.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L368.8,257.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M370,254.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C370.2,254.1,370,254.3,370,254.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M370.2,255.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C370.3,255.3,370.2,255.5,370.2,255.5z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluoak_1_" data-linestop="piccadilly">
      <g>
        <path
          fill="#1C3F94"
          d="M682.2,175.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C678.8,176.7,680.3,175.2,682.2,175.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M681.1,179.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L681.1,179.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M682.3,176.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C682.5,175.8,682.3,176,682.3,176.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M682.6,177.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H681c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C682.6,177,682.6,177.2,682.6,177.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-piccadilly_lul-victoria_940gzzlufpk_1_">
      <g id="lul-piccadilly_lul-victoria_940gzzlufpk_6_">
        <path d="M677.3,279.1l0.7,0.7c-0.3,1.1,0,2.4,0.9,3.3c1.3,1.3,3.5,1.3,4.8,0c1.3-1.3,1.3-3.5,0-4.8c-0.9-0.9-2.2-1.2-3.3-0.9 l-0.7-0.7c0.3-1.1,0-2.4-0.9-3.3c-1.3-1.3-3.5-1.3-4.8,0c-1.3,1.3-1.3,3.5,0,4.8C674.9,279.2,676.2,279.4,677.3,279.1z" />
      </g>
      <path
        id="lul-victoria_940gzzlufpk_2_"
        d="M683.7,283.1c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0 C685,279.6,685,281.8,683.7,283.1z"
      />
      <path
        id="lul-piccadilly_940gzzlufpk_2_"
        d="M678.8,278.3c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8 c1.3-1.3,3.5-1.3,4.8,0C680.2,274.8,680.2,276.9,678.8,278.3z"
      />
      <path
        id="lul-piccadilly_lul-victoria_940gzzlufpk_2_"
        fill="#FFFFFF"
        d="M674.8,277.5c-0.9-0.9-0.9-2.3,0-3.2 c0.9-0.9,2.3-0.9,3.2,0c0.8,0.8,0.9,1.9,0.3,2.8l1.7,1.7c0.9-0.5,2-0.4,2.8,0.3c0.9,0.9,0.9,2.3,0,3.2c-0.9,0.9-2.3,0.9-3.2,0 c-0.8-0.8-0.9-1.9-0.3-2.8l-1.7-1.7C676.7,278.3,675.6,278.2,674.8,277.5z"
        className="white-fill"
      />
    </g>
    <g id="lul-district_lul-hammersmith-city_940gzzluade_1_">
      <path
        fill="#FFFFFF"
        d="M715.6,431.3c-1.1,1.1-2.9,1.1-4,0c-1.1-1.1-1.1-2.9,0-4c1.1-1.1,2.9-1.1,4,0 C716.7,428.4,716.7,430.2,715.6,431.3z"
        className="white-fill"
      />
      <path d="M716,431.7c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0C717.3,428.2,717.3,430.4,716,431.7z M712,430.9c0.9,0.9,2.3,0.9,3.2,0c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0C711.1,428.6,711.1,430,712,430.9z" />
    </g>
    <g id="lul-district_lul-hammersmith-city_940gzzluwpl_raillo-overground_910gwchapel_1_">
      <path
        fill="#FFFFFF"
        d="M725.9,420.9c-1.1,1.1-2.9,1.1-4,0c-1.1-1.1-1.1-2.9,0-4c1.1-1.1,2.9-1.1,4,0 C727,418,727,419.8,725.9,420.9z"
        className="white-fill"
      />
      <path d="M726.3,421.3c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0C727.7,417.8,727.7,419.9,726.3,421.3z M722.3,420.5c0.9,0.9,2.3,0.9,3.2,0c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0C721.4,418.1,721.4,419.6,722.3,420.5z" />
    </g>
    <g id="s-940gzzlusvs_1_" data-linestop="victoria">
      <path
        fill="#FFFFFF"
        d="M716.1,265.6c-1.1,1.1-2.9,1.1-4,0c-1.1-1.1-1.1-2.9,0-4c1.1-1.1,2.9-1.1,4,0 C717.2,262.7,717.2,264.5,716.1,265.6z"
        className="white-fill"
      />
      <path d="M716.5,266c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0C717.9,262.5,717.9,264.7,716.5,266z M712.5,265.2c0.9,0.9,2.3,0.9,3.2,0c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0C711.6,262.9,711.6,264.3,712.5,265.2z" />
    </g>
    <g id="s-940gzzluksh_2_" data-linestop="northern">
      <path
        fill="#FFFFFF"
        d="M581.7,300.6c-1.1,1.1-2.9,1.1-4,0c-1.1-1.1-1.1-2.9,0-4c1.1-1.1,2.9-1.1,4,0 C582.8,297.7,582.8,299.5,581.7,300.6z"
        className="white-fill"
      />
      <path d="M582.1,301c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0C583.4,297.5,583.4,299.6,582.1,301z M578,300.2c0.9,0.9,2.3,0.9,3.2,0c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0C577.1,297.8,577.2,299.3,578,300.2z" />
    </g>
    <g id="lul-district_940gzzlugby_raillo-overground_910ggnrsbry">
      <path
        fill="#FFFFFF"
        d="M256.6,537.5c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C255.3,534.7,256.6,536,256.6,537.5z"
        className="white-fill"
      />
      <path d="M257.1,537.5c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C255.6,534.1,257.1,535.7,257.1,537.5z M253.7,539.8c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3S252.5,539.8,253.7,539.8z" />
    </g>
    <g id="lul-bakerloo_lul-northern_940gzzlueac_1_">
      <path
        fill="#FFFFFF"
        d="M552.1,610.2c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C550.8,607.4,552.1,608.6,552.1,610.2z"
        className="white-fill"
      />
      <path d="M552.6,610.2c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C551.1,606.8,552.6,608.3,552.6,610.2z M549.2,612.5c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C546.9,611.5,548,612.5,549.2,612.5z" />
    </g>
    <g id="lul-northern_lul-victoria_940gzzluskw_1_">
      <path
        fill="#FFFFFF"
        d="M517.4,644.9c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C516.1,642,517.4,643.3,517.4,644.9z"
        className="white-fill"
      />
      <path d="M517.9,644.9c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C516.4,641.5,517.9,643,517.9,644.9z M514.5,647.2c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3C512.2,646.1,513.3,647.2,514.5,647.2z" />
    </g>
    <g id="s-940gzzlublm_1_" data-linestop="northern">
      <path
        fill="#FFFFFF"
        d="M473.6,688.6c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C472.3,685.8,473.6,687.1,473.6,688.6z"
        className="white-fill"
      />
      <path d="M474.1,688.6c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C472.6,685.2,474.1,686.8,474.1,688.6z M470.7,690.9c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C468.4,689.9,469.5,690.9,470.7,690.9z" />
    </g>
    <g id="s-910gnorwdj_2_" data-linestop="london-overground">
      <path
        fill="#FFFFFF"
        d="M726.7,691.1c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C725.4,688.3,726.7,689.6,726.7,691.1z"
        className="white-fill"
      />
      <path d="M727.3,691.1c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C725.7,687.7,727.3,689.2,727.3,691.1z M723.9,693.4c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C721.6,692.4,722.6,693.4,723.9,693.4z" />
    </g>
    <g id="lul-district_lul-piccadilly_940gzzluecm_1_">
      <path
        fill="#FFFFFF"
        d="M232.4,482.1c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C231.1,479.2,232.4,480.5,232.4,482.1z"
        className="white-fill"
      />
      <path d="M232.9,482.1c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C231.4,478.7,232.9,480.2,232.9,482.1z M229.5,484.4c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3S228.3,484.4,229.5,484.4z" />
    </g>
    <g id="s-940gzzluwrp_1_" data-linestop="central">
      <path
        fill="#FFFFFF"
        d="M164.8,213.9c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C163.5,211.1,164.8,212.3,164.8,213.9z"
        className="white-fill"
      />
      <path d="M165.4,213.9c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C163.8,210.5,165.4,212,165.4,213.9z M162,216.2c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C159.7,215.2,160.7,216.2,162,216.2z" />
    </g>
    <g id="s-940gzzluhoh_1_" data-linestop="metropolitan">
      <path
        fill="#FFFFFF"
        d="M280.1,261.2c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C278.8,258.4,280.1,259.6,280.1,261.2z"
        className="white-fill"
      />
      <path d="M280.7,261.2c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C279.2,257.8,280.7,259.3,280.7,261.2z M277.3,263.5c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C275,262.5,276,263.5,277.3,263.5z" />
    </g>
    <g id="s-940gzzlusrp_1_" data-linestop="central">
      <path
        fill="#FFFFFF"
        d="M164.8,294.7c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C163.5,291.9,164.8,293.1,164.8,294.7z"
        className="white-fill"
      />
      <path d="M165.4,294.7c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C163.8,291.3,165.4,292.8,165.4,294.7z M162,297c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C159.7,296,160.7,297,162,297z" />
    </g>
    <g id="s-940gzzluams_1_" data-linestop="metropolitan">
      <path
        fill="#FFFFFF"
        d="M95,160.8c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C93.7,157.9,95,159.2,95,160.8z"
        className="white-fill"
      />
      <path d="M95.6,160.8c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C94.1,157.3,95.6,158.9,95.6,160.8z M92.2,163c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C89.9,162,90.9,163,92.2,163z" />
    </g>
    <g id="lul-metropolitan_lul-piccadilly_940gzzluryl_1_">
      <path
        fill="#FFFFFF"
        d="M218.8,257c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C217.5,254.2,218.8,255.5,218.8,257z"
        className="white-fill"
      />
      <path d="M219.3,257c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C217.8,253.6,219.3,255.2,219.3,257z M215.9,259.3c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C213.6,258.3,214.7,259.3,215.9,259.3z" />
    </g>
    <g id="s-910gbushey_1_" data-linestop="london-overground">
      <path
        fill="#FFFFFF"
        d="M324.5,175.8c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C323.2,172.9,324.5,174.2,324.5,175.8z"
        className="white-fill"
      />
      <path d="M325.1,175.8c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C323.6,172.4,325.1,173.9,325.1,175.8z M321.7,178.1c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C319.4,177,320.4,178.1,321.7,178.1z" />
    </g>
    <g id="lul-bakerloo_940gzzluqps_raillo-overground_910gqprk">
      <path
        fill="#FFFFFF"
        d="M323.4,348.9c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C322.1,346,323.4,347.3,323.4,348.9z"
        className="white-fill"
      />
      <path d="M323.9,348.9c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C322.4,345.4,323.9,347,323.9,348.9z M320.5,351.1c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C318.2,350.1,319.2,351.1,320.5,351.1z" />
    </g>
    <g id="lul-central_lul-district_lul-hammersmith-city_940gzzlumed_3_">
      <path
        id="lul-central_lul-district_lul-hammersmith-city_940gzzlumed_5_"
        d="M776.6,394.8v-0.9c1-0.6,1.7-1.7,1.7-3 c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4c0,1.3,0.7,2.4,1.7,3v0.9c-1,0.6-1.7,1.7-1.7,3c0,1.9,1.5,3.4,3.4,3.4 c1.9,0,3.4-1.5,3.4-3.4C778.3,396.5,777.6,395.4,776.6,394.8z"
      />
      <path
        id="lul-central_940gzzlumed_1_"
        d="M774.9,394.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4 C778.3,392.9,776.7,394.4,774.9,394.4z"
      />
      <path
        id="lul-district_lul-hammersmith-city_940gzzlumed_1_"
        d="M774.9,401.2c-1.9,0-3.4-1.5-3.4-3.4 c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4C778.3,399.7,776.7,401.2,774.9,401.2z"
      />
      <g id="lul-central_lul-district_lul-hammersmith-city_940gzzlumed_4_">
        <path
          fill="#FFFFFF"
          d="M777.1,397.8c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.1,0.7-1.9,1.7-2.2v-2.4 c-1-0.3-1.7-1.1-1.7-2.2c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,1.1-0.7,1.9-1.7,2.2v2.4 C776.4,395.8,777.1,396.7,777.1,397.8z"
          className="white-fill"
        />
      </g>
    </g>
    <g id="lul-circle_lul-district_lul-hammersmith-city_940gzzluerc_2_">
      <path
        id="lul-circle_lul-hammersmith-city_940gzzluerc_1_"
        d="M420.4,380.1c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 c0-1.9,1.5-3.4,3.4-3.4C418.9,376.7,420.4,378.2,420.4,380.1z"
      />
      <path
        id="lul-circle_lul-district_940gzzluerc_1_"
        d="M420.4,386.1c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 c0-1.9,1.5-3.4,3.4-3.4C418.9,382.7,420.4,384.2,420.4,386.1z"
      />
      <path
        id="lul-circle_lul-district_lul-hammersmith-city_940gzzluerc_3_"
        fill="#FFFFFF"
        d="M414.7,380.1 c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,1.1-0.7,2-1.7,2.2v1.6c1,0.3,1.7,1.1,1.7,2.2c0,1.3-1,2.3-2.3,2.3 c-1.3,0-2.3-1-2.3-2.3c0-1.1,0.7-1.9,1.7-2.2v-1.6C415.4,382.1,414.7,381.2,414.7,380.1z"
        className="white-fill"
      />
    </g>
    <g id="s-940gzzluods_1_" data-linestop="northern">
      <path
        fill="#FFFFFF"
        d="M638.7,388.8c-1.1,1.1-2.9,1.1-4,0c-1.1-1.1-1.1-2.9,0-4c1.1-1.1,2.9-1.1,4,0 C639.8,385.8,639.8,387.7,638.7,388.8z"
        className="white-fill"
      />
      <path d="M639.1,389.2c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0C640.4,385.7,640.4,387.8,639.1,389.2z M635.1,388.4c0.9,0.9,2.3,0.9,3.2,0c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0C634.2,386,634.2,387.5,635.1,388.4z" />
    </g>
    <g id="lul-central_lul-circle_lul-district_940gzzlunhg_1_">
      <path
        fill="#FFFFFF"
        d="M392.4,452.1c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C391.1,449.3,392.4,450.6,392.4,452.1z"
        className="white-fill"
      />
      <path d="M393,452.1c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C391.4,448.7,393,450.2,393,452.1z M389.6,454.4c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C387.3,453.4,388.3,454.4,389.6,454.4z" />
    </g>
    <g id="lul-circle_lul-district_940gzzlucst_2_">
      <circle
        fill="#FFFFFF"
        cx="616.9"
        cy="477.8"
        r="2.8"
        className="white-fill"
      />
      <path d="M620.3,477.8c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C618.7,474.4,620.3,475.9,620.3,477.8z M616.9,480.1c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3S615.6,480.1,616.9,480.1z" />
    </g>
    <g id="lul-bakerloo_lul-central_lul-victoria_940gzzluoxc_2_">
      <path
        fill="#FFFFFF"
        d="M498.9,432.3c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C497.6,429.5,498.9,430.8,498.9,432.3z"
        className="white-fill"
      />
      <path d="M499.4,432.3c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C497.9,428.9,499.4,430.5,499.4,432.3z M496,434.6c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C493.8,433.6,494.8,434.6,496,434.6z" />
    </g>
    <g id="lul-northern_lul-victoria_940gzzluwrr_1_">
      <path
        fill="#FFFFFF"
        d="M539.6,391.6c0,1.6-1.3,2.8-2.8,2.8s-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8S539.6,390.1,539.6,391.6z"
        className="white-fill"
      />
      <path d="M540.2,391.6c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C538.6,388.2,540.2,389.7,540.2,391.6z M536.7,393.9c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3C534.5,392.9,535.5,393.9,536.7,393.9z" />
    </g>
    <g id="s-940gzzlumyb_1_" data-linestop="bakerloo">
      <path
        fill="#FFFFFF"
        d="M436.8,373.8c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C435.6,370.9,436.8,372.2,436.8,373.8z"
        className="white-fill"
      />
      <path d="M437.4,373.8c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C435.9,370.4,437.4,371.9,437.4,373.8z M434,376.1c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C431.7,375,432.7,376.1,434,376.1z" />
    </g>
    <g id="lul-circle_lul-hammersmith-city_lul-metropolitan_lul-northern_940gzzlumgt_1_">
      <path
        fill="#FFFFFF"
        d="M639.7,422.9c0,1.6-1.3,3-3,3c-1.6,0-3-1.3-3-3c0-1.6,1.3-3,3-3C638.3,419.9,639.7,421.3,639.7,422.9z"
        className="white-fill"
      />
      <path d="M640.3,422.9c0,2-1.6,3.6-3.6,3.6s-3.6-1.6-3.6-3.6c0-2,1.6-3.6,3.6-3.6S640.3,420.9,640.3,422.9z M636.7,425.3 c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4c-1.3,0-2.4,1.1-2.4,2.4C634.3,424.2,635.4,425.3,636.7,425.3z" />
    </g>
    <g id="lul-bakerloo_lul-piccadilly_940gzzlupcc_1_">
      <path
        fill="#FFFFFF"
        d="M518.4,464.8c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C517.1,461.9,518.4,463.2,518.4,464.8z"
        className="white-fill"
      />
      <path d="M519,464.8c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C517.5,461.4,519,462.9,519,464.8z M515.6,467.1c1.3,0,2.3-1,2.3-2.3s-1-2.3-2.3-2.3s-2.3,1-2.3,2.3S514.3,467.1,515.6,467.1z" />
    </g>
    <g
      id="lul-northern_lul-piccadilly_940gzzlulsq_1_"
    >
      <path
        fill="#FFFFFF"
        d="M539.6,457.4c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C538.3,454.6,539.6,455.8,539.6,457.4z"
        className="white-fill"
      />
      <path d="M540.2,457.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C538.6,454,540.2,455.5,540.2,457.4z M536.7,459.7c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3C534.5,458.7,535.5,459.7,536.7,459.7z" />
    </g>
    <g id="lul-bakerloo_lul-northern_940gzzluchx_1_" >
      <path
        fill="#FFFFFF"
        d="M539.6,486c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C538.3,483.1,539.6,484.4,539.6,486z"
        className="white-fill"
      />
      <path d="M540.2,486c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C538.6,482.6,540.2,484.1,540.2,486z M536.7,488.3c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3C534.5,487.2,535.5,488.3,536.7,488.3z" />
    </g>
    <g id="s-910gpckhmry_1_" data-linestop="london-overground">
      <path
        fill="#FFFFFF"
        d="M647.4,629.2c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C646.2,626.3,647.4,627.6,647.4,629.2z"
        className="white-fill"
      />
      <path d="M648,629.2c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C646.5,625.8,648,627.3,648,629.2z M644.6,631.4c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C642.3,630.4,643.3,631.4,644.6,631.4z" />
    </g>
    <g id="lul-central_lul-piccadilly_940gzzluhbn_1_">
      <path
        fill="#FFFFFF"
        d="M564.2,432.7c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C563,429.9,564.2,431.2,564.2,432.7z"
        className="white-fill"
      />
      <path d="M564.8,432.7c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C563.3,429.3,564.8,430.8,564.8,432.7z M561.4,435c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C559.1,434,560.1,435,561.4,435z" />
    </g>
    <g id="lul-circle_lul-district_lul-piccadilly_940gzzlusks_3_">
      <path
        id="lul-circle_lul-district_lul-piccadilly_940gzzlusks_5_"
        d="M420.1,509.2v1.4v0c-0.5,0.3-0.9,0.7-1.2,1.2 c-0.3,0.5-0.5,1.1-0.5,1.7c0,0.9,0.4,1.8,1,2.4c0.6,0.6,1.5,1,2.4,1c0.9,0,1.8-0.4,2.4-1c0.6-0.6,1-1.5,1-2.4 c0-0.6-0.2-1.2-0.5-1.7c-0.3-0.5-0.7-0.9-1.2-1.2v0v-1.4c0.5-0.3,0.9-0.7,1.2-1.2c0.3-0.5,0.5-1.1,0.5-1.7c0-0.9-0.4-1.8-1-2.4 c-0.6-0.6-1.5-1-2.4-1c-0.9,0-1.8,0.4-2.4,1c-0.6,0.6-1,1.5-1,2.4c0,0.6,0.2,1.2,0.5,1.7C419.2,508.5,419.6,508.9,420.1,509.2z"
      />
      <path
        id="lul-piccadilly_940gzzlusks_1_"
        d="M425.2,506.2c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 c0-1.9,1.5-3.4,3.4-3.4C423.7,502.8,425.2,504.3,425.2,506.2z"
      />
      <path
        id="lul-circle_lul-district_940gzzlusks_1_"
        d="M425.2,513.5c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 c0-1.9,1.5-3.4,3.4-3.4C423.7,510.1,425.2,511.6,425.2,513.5z"
      />
      <path
        id="lul-circle_lul-district_lul-piccadilly_940gzzlusks_4_"
        fill="#FFFFFF"
        d="M419.6,506.2 c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,1.1-0.7,1.9-1.7,2.2v2.9c1,0.3,1.7,1.1,1.7,2.2c0,1.3-1,2.3-2.3,2.3 c-1.3,0-2.3-1-2.3-2.3c0-1.1,0.7-1.9,1.7-2.2v-2.8C420.3,508.2,419.6,507.3,419.6,506.2z"
        className="white-fill"
      />
    </g>
    <g id="s-940gzzlucsm_1_" data-linestop="metropolitan">
      <g>
        <path
          fill="#1C3F94"
          d="M111.5,145.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C108.1,147.4,109.6,145.9,111.5,145.9z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M111.5,152.3c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C114.6,151,113.2,152.3,111.5,152.3L111.5,152.3z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M110.4,150.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L110.4,150.3z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M111.6,147c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C111.8,146.5,111.6,146.7,111.6,147z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M111.8,147.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C111.9,147.7,111.8,147.9,111.8,147.9z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzlusut_1_" data-linestop="piccadilly">
      <g>
        <path
          fill="#1C3F94"
          d="M230.7,322.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C227.3,324.3,228.8,322.8,230.7,322.8z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M230.7,329.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C233.7,327.9,232.4,329.2,230.7,329.2L230.7,329.2z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M229.5,327.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L229.5,327.2z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M230.7,323.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C231,323.4,230.7,323.6,230.7,323.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M231,324.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C231.1,324.6,231,324.8,231,324.8z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-bakerloo_940gzzluwjn_raillo-overground_910gwlsdjhl">
      <g>
        <path
          fill="#1C3F94"
          d="M320.6,325.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C317.2,327.2,318.7,325.6,320.6,325.6z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M320.6,332.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1 C323.7,330.7,322.3,332.1,320.6,332.1L320.6,332.1z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M319.5,330c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L319.5,330z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M320.7,326.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C320.9,326.3,320.7,326.5,320.7,326.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M321,327.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C321,327.4,321,327.7,321,327.7z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-bakerloo_940gzzluwyc_raillo-overground_910gwmby">
      <g>
        <path
          fill="#1C3F94"
          d="M320.6,293.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C317.2,295.1,318.7,293.6,320.6,293.6z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M320.6,300c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1 C323.7,298.7,322.3,300,320.6,300L320.6,300z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M319.5,298c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L319.5,298z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M320.7,294.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C320.9,294.2,320.7,294.4,320.7,294.7z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M321,295.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C321,295.4,321,295.6,321,295.6z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-bakerloo_940gzzluhaw_raillo-overground_910ghrow">
      <g>
        <path
          fill="#1C3F94"
          d="M320.5,223.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C317.1,224.7,318.6,223.1,320.5,223.1z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M320.5,229.6c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1 C323.6,228.2,322.2,229.6,320.5,229.6L320.5,229.6z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M319.3,227.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L319.3,227.5z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M320.6,224.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C320.8,223.8,320.6,224,320.6,224.3z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M320.8,225.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C320.9,225,320.8,225.2,320.8,225.2z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-jubilee_lul-metropolitan_940gzzluwyp_2_">
      <g id="lul-jubilee_lul-metropolitan_940gzzluwyp_3_">
        <rect
          x="368.7"
          y="274.8"
          transform="matrix(0.7072 -0.707 0.707 0.7072 -86.4409 344.3429)"
          width="7.6"
          height="3.4"
        />
        <rect
          x="368.5"
          y="276"
          transform="matrix(0.7066 -0.7076 0.7076 0.7066 -86.3792 344.7302)"
          fill="#FFFFFF"
          width="8.1"
          height="1.1"
          className="white-fill"
        />
      </g>
      <g id="lul-metropolitan_940gzzluwyp_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M369.9,275.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C366.5,277.2,368,275.7,369.9,275.7z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M368.8,280.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L368.8,280.1z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M370,276.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C370.2,276.3,370,276.6,370,276.9z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M370.2,277.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C370.3,277.5,370.2,277.7,370.2,277.7z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="lul-jubilee_940gzzluwyp_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M375,270.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C371.6,272.1,373.1,270.6,375,270.6z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M373.9,275c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L373.9,275z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M375.1,271.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C375.3,271.2,375.1,271.5,375.1,271.7z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M375.4,272.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C375.4,272.4,375.4,272.6,375.4,272.6z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-940gzzlustm_1_" data-linestop="jubilee">
      <g>
        <path
          fill="#1C3F94"
          d="M370,216.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C366.6,218.2,368.1,216.7,370,216.7z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M370,223.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C373,221.8,371.7,223.2,370,223.2L370,223.2z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M368.8,221.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L368.8,221.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M370,217.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C370.2,217.3,370,217.6,370,217.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M370.3,218.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C370.4,218.5,370.3,218.7,370.3,218.7z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluggn_1_" data-linestop="northern">
      <g>
        <path
          fill="#1C3F94"
          d="M495.8,264c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C492.4,265.5,494,264,495.8,264z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M495.8,270.5c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C498.9,269.1,497.5,270.5,495.8,270.5L495.8,270.5z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M494.7,268.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L494.7,268.4z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M495.9,265.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C496.1,264.6,495.9,264.9,495.9,265.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M496.2,266l-0.1,0.4H495c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C496.2,265.8,496.2,266,496.2,266z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzlukbn_1_" data-linestop="jubilee">
      <g>
        <path
          fill="#1C3F94"
          d="M418.2,313.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C414.8,315.3,416.3,313.8,418.2,313.8z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M418.2,320.3c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C421.3,318.9,419.9,320.3,418.2,320.3L418.2,320.3z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M417.1,318.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L417.1,318.2z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M418.3,314.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C418.5,314.4,418.3,314.7,418.3,314.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M418.6,315.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H417c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C418.6,315.6,418.6,315.8,418.6,315.8z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluhbt_1_" data-linestop="northern">
      <g>
        <path
          fill="#1C3F94"
          d="M585,164c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C581.6,165.6,583.1,164,585,164z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M583.8,168.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L583.8,168.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M585,165.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C585.3,164.7,585,164.9,585,165.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M585.3,166.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C585.4,165.8,585.3,166.1,585.3,166.1z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluwop_1_" data-linestop="northern">
      <g>
        <path
          fill="#1C3F94"
          d="M585,187.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C581.6,189.4,583.1,187.8,585,187.8z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M583.8,192.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L583.8,192.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M585,189c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C585.3,188.5,585,188.7,585,189z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M585.3,189.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C585.4,189.6,585.3,189.9,585.3,189.9z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzlufyc_1_" data-linestop="northern">
      <g>
        <path
          fill="#1C3F94"
          d="M585,218.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C581.6,219.7,583.1,218.2,585,218.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M583.8,222.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L583.8,222.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M585,219.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C585.3,218.8,585,219.1,585,219.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M585.3,220.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C585.4,220,585.3,220.2,585.3,220.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910ggosplok_1_" data-linestop="london-overground">
      <g id="s-910ggosplok_3_" data-linestop="london-overground">
        <rect
          x="544.2"
          y="276.8"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -35.5184 471.1976)"
          width="13.7"
          height="3.4"
        />
        <rect
          x="544"
          y="277.9"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -35.5245 471.1942)"
          fill="#FFFFFF"
          width="14.1"
          height="1.1"
          className="white-fill"
        />
      </g>
      <g id="s-910ggosplok_2_" data-linestop="london-overground">
        <g>
          <path
            fill="#1C3F94"
            d="M556.2,269.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C552.8,271.5,554.3,269.9,556.2,269.9z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M556.2,276.4c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C559.3,275,557.9,276.4,556.2,276.4L556.2,276.4z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M555.1,274.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L555.1,274.4z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M556.3,271.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C556.5,270.6,556.3,270.8,556.3,271.1z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M556.6,272l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H555c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C556.6,271.8,556.6,272,556.6,272z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
      <g id="s-910ggosplok" data-linestop="london-overground">
        <g>
          <path
            fill="#1C3F94"
            d="M546.6,279.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C543.2,281.1,544.7,279.6,546.6,279.6z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M546.6,286.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C549.6,284.7,548.3,286.1,546.6,286.1L546.6,286.1z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M545.4,284c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L545.4,284z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M546.6,280.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C546.8,280.3,546.6,280.5,546.6,280.8z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M546.9,281.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C547,281.4,546.9,281.7,546.9,281.7z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-910gcmdnrd_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M585.7,311.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C582.3,313.3,583.8,311.8,585.7,311.8z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M585.7,318.3c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C588.7,316.9,587.4,318.3,585.7,318.3L585.7,318.3z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M584.5,316.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L584.5,316.2z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M585.7,313c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C586,312.5,585.7,312.7,585.7,313z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M586,313.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C586.1,313.6,586,313.9,586,313.9z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910ghmpstdh_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M525.7,279.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C522.3,281.2,523.9,279.7,525.7,279.7z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M525.7,286.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C528.8,284.8,527.4,286.1,525.7,286.1L525.7,286.1z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M524.6,284.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L524.6,284.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M525.8,280.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C526,280.3,525.8,280.6,525.8,280.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M526.1,281.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C526.1,281.5,526.1,281.7,526.1,281.7z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-northern-lul-victoria_940gzzlueus_raillo-overground_910geuston">
      <path
        id="lul-northern-lul-victoria_raillo-overground_940gzzlueus"
        d="M558.1,365.5c-1.3,0-2.4,0.7-3,1.7l-3.5,0 c-0.6-1-1.7-1.7-3-1.7s-2.4,0.7-3,1.7h-3.1c-0.6-1-1.7-1.7-3-1.7c-1.9,0-3.4,1.5-3.4,3.4c0,1.9,1.5,3.4,3.4,3.4 c1.3,0,2.4-0.7,2.9-1.7h3.1c0.6,1,1.7,1.7,3,1.7c1.3,0,2.4-0.7,3-1.7h3.5c0.6,1,1.7,1.7,3,1.7c1.9,0,3.4-1.5,3.4-3.4 C561.5,367,560,365.5,558.1,365.5z"
      />
      <path
        id="lul-northern_lul-victoria_940gzzlueus_1_"
        d="M561.5,368.9c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 c0-1.9,1.5-3.4,3.4-3.4C560,365.5,561.5,367,561.5,368.9z"
      />
      <path
        id="lul-northern_940gzzlueus_1_"
        d="M552.1,368.9c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4 C550.6,365.5,552.1,367,552.1,368.9z"
      />
      <path
        id="lul-northern-lul-victoria_raillo-059_940gzzlueus_3_"
        fill="#FFFFFF"
        d="M560.4,368.9c0,1.3-1,2.3-2.3,2.3 c-1.1,0-1.9-0.7-2.2-1.7h-5c-0.3,1-1.1,1.7-2.2,1.7c-1.1,0-1.9-0.7-2.2-1.7l-4.6,0c-0.3,1-1.1,1.7-2.2,1.7c-1.3,0-2.3-1-2.3-2.3 c0-1.3,1-2.3,2.3-2.3c1.1,0,1.9,0.7,2.2,1.7l4.6,0c0.3-1,1.1-1.7,2.2-1.7c1.1,0,1.9,0.7,2.2,1.7l5,0c0.3-1,1.1-1.7,2.2-1.7 C559.4,366.6,560.4,367.6,560.4,368.9z"
        className="white-fill"
      />
      <g id="raillo-overground_940gzzlueus">
        <g>
          <path
            fill="#1C3F94"
            d="M539.7,365.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C536.3,367,537.9,365.5,539.7,365.5z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M539.7,371.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C542.8,370.6,541.4,371.9,539.7,371.9L539.7,371.9z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M538.6,369.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L538.6,369.9z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M539.8,366.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C540,366.1,539.8,366.3,539.8,366.6z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M540.1,367.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C540.1,367.3,540.1,367.5,540.1,367.5z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-circle_lul-hammersmith-city_lul-metropolitan_940gzzlufcn_1_">
      <g>
        <path
          fill="#1C3F94"
          d="M602,391.8c2,0,3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6c-2,0-3.6-1.6-3.6-3.6 C598.4,393.4,600,391.8,602,391.8z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M602,398.6c-1.8,0-3.2-1.4-3.2-3.2c0-1.8,1.4-3.2,3.2-3.2c1.8,0,3.2,1.4,3.2,3.2 C605.2,397.1,603.8,398.6,602,398.6L602,398.6z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M600.8,396.4c0.2,0.6,0.7,1,1.3,1c0.8,0,1.4-0.6,1.4-1.4c0-0.4-0.2-0.8-0.4-1l0.1-0.6 c0.5,0.3,0.9,0.9,0.9,1.6c0,1-0.8,1.9-1.9,1.9c-0.7,0-1.3-0.4-1.6-0.9L600.8,396.4z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M602.1,393c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C602.3,392.5,602.1,392.7,602.1,393z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M602.3,393.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.3c0,0.2,0.2,0.3,0.2,0.3h1.1l0,0.4h-1.5c0,0-0.1,0-0.2,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.3c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C602.4,393.7,602.3,393.9,602.3,393.9z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-central_940gzzlusbc_raillo-overground_910gshpdsb_1_">
      <g id="lul-central_940gzzlusbc_raillo-overground_910gshpdsb_2_">
        <path d="M353.3,455l0,1.3c-1,0.6-1.7,1.7-1.7,3c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4c0-1.3-0.7-2.4-1.7-3V455 c1-0.6,1.7-1.7,1.7-3c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4C351.6,453.3,352.3,454.4,353.3,455z" />
      </g>
      <path
        id="lul-central_940gzzlusbc_1_"
        d="M355,448.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C351.6,450.1,353.2,448.6,355,448.6z"
      />
      <path
        id="lul-central_940gzzlusbc_raillo-overground_910gshpdsb"
        fill="#FFFFFF"
        d="M352.8,452c0-1.3,1-2.3,2.3-2.3 c1.3,0,2.3,1,2.3,2.3c0,1.1-0.7,1.9-1.7,2.2v2.8c1,0.3,1.7,1.1,1.7,2.2c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3 c0-1.1,0.7-1.9,1.7-2.1l0-2.9C353.5,454,352.8,453.1,352.8,452z"
        className="white-fill"
      />
      <g id="raillo-overground_910gshpdsb">
        <g>
          <path
            fill="#1C3F94"
            d="M355,455.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C351.6,457.4,353.2,455.8,355,455.8z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M355,462.3c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1 C358.1,460.9,356.7,462.3,355,462.3L355,462.3z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M353.9,460.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L353.9,460.2z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M355.1,457c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C355.3,456.5,355.1,456.7,355.1,457z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M355.4,457.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C355.4,457.7,355.4,457.9,355.4,457.9z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-central_940gzzluwcy_lul-circle_lul-hammersmith-city_940gzzluwla_3_">
      <g id="lul-central_940gzzluwcy_lul-circle_lul-hammersmith-city_940gzzluwla_5_">
        <path d="M332.4,457.9l-5.1-5.1c0.3-1.1,0-2.4-0.9-3.3c-1.3-1.3-3.5-1.3-4.8,0c-1.3,1.3-1.3,3.5,0,4.8c0.9,0.9,2.2,1.2,3.3,0.9 l5.1,5.1c-0.3,1.1,0,2.4,0.9,3.3c1.3,1.3,3.5,1.3,4.8,0c1.3-1.3,1.3-3.5,0-4.8C334.8,457.9,333.5,457.6,332.4,457.9z" />
      </g>
      <path
        id="lul-central_940gzzluwcy_1_"
        d="M326.4,449.5c1.3,1.3,1.3,3.5,0,4.8c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8 C322.9,448.2,325.1,448.2,326.4,449.5z"
      />
      <path
        id="lul-central_940gzzluwcy_lul-circle_lul-hammersmith-city_940gzzluwla_4_"
        fill="#FFFFFF"
        d=" M334.9,459.6c0.9,0.9,0.9,2.3,0,3.2c-0.9,0.9-2.3,0.9-3.2,0c-0.7-0.8-0.9-1.9-0.3-2.8l-6.1-6.1c-0.9,0.5-2,0.4-2.8-0.3 c-0.9-0.9-0.9-2.3,0-3.2c0.9-0.9,2.3-0.9,3.2,0c0.7,0.7,0.9,1.9,0.3,2.8l6.1,6.1C333,458.7,334.1,458.8,334.9,459.6z"
        className="white-fill"
      />
      <g id="lul-circle_lul-hammersmith-city_940gzzluwla_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M333.2,457.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C329.8,459.3,331.4,457.8,333.2,457.8z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M333.2,464.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C336.3,462.9,334.9,464.2,333.2,464.2L333.2,464.2z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M332.1,462.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L332.1,462.2z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M333.3,458.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C333.5,458.4,333.3,458.6,333.3,458.9z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M333.6,459.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H332c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C333.7,459.6,333.6,459.8,333.6,459.8z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-910gactnctl_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M271.4,471.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C268,473.1,269.5,471.5,271.4,471.5z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M271.4,478c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C274.5,476.6,273.1,478,271.4,478L271.4,478z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M270.3,475.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L270.3,475.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M271.5,472.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C271.7,472.2,271.5,472.4,271.5,472.7z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M271.8,473.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C271.8,473.4,271.8,473.6,271.8,473.6z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gsacton_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M271.4,484.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C268,486.3,269.5,484.8,271.4,484.8z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M271.4,491.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C274.5,489.9,273.1,491.2,271.4,491.2L271.4,491.2z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M270.3,489.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L270.3,489.2z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M271.5,485.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C271.7,485.4,271.5,485.6,271.5,485.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M271.8,486.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0h1.3 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C271.8,486.6,271.8,486.8,271.8,486.8z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-district_940gzzlukwg_raillo-overground_910gkewgrdn">
      <g>
        <path
          fill="#1C3F94"
          d="M253.7,561.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C250.3,563,251.9,561.5,253.7,561.5z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M253.7,568c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C256.8,566.6,255.4,568,253.7,568L253.7,568z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M252.6,565.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L252.6,565.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M253.8,562.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C254,562.1,253.8,562.4,253.8,562.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M254.1,563.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C254.1,563.3,254.1,563.5,254.1,563.5z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-district_940gzzlurmd_raillo-overground_910grichmnd">
      <g>
        <path
          fill="#1C3F94"
          d="M253.7,575.5c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4S251.9,575.5,253.7,575.5z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M253.7,582c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C256.8,580.6,255.4,582,253.7,582L253.7,582z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M252.6,579.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L252.6,579.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M253.8,576.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C254,576.2,253.8,576.4,253.8,576.7z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M254.1,577.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C254.1,577.3,254.1,577.6,254.1,577.6z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluhr5_1_" data-linestop="piccadilly">
      <g>
        <path
          fill="#1C3F94"
          d="M109.5,615.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C106.1,616.7,107.7,615.2,109.5,615.2z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M109.5,621.6c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C112.6,620.3,111.2,621.6,109.5,621.6L109.5,621.6z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M108.4,619.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L108.4,619.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M109.6,616.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C109.8,615.8,109.6,616,109.6,616.3z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M109.9,617.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C109.9,617,109.9,617.2,109.9,617.2z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluhwe_1_" data-linestop="piccadilly">
      <g>
        <path
          fill="#1C3F94"
          d="M175,535.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C171.6,537,173.2,535.5,175,535.5z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M175,542c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C178.1,540.6,176.7,542,175,542L175,542z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M173.9,539.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L173.9,539.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M175.1,536.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C175.3,536.1,175.1,536.4,175.1,536.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M175.4,537.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C175.4,537.3,175.4,537.5,175.4,537.5z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzlufby_1_" data-linestop="district">
      <g>
        <path
          fill="#1C3F94"
          d="M376.6,565.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C373.2,567.3,374.7,565.8,376.6,565.8z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M376.6,572.3c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C379.7,570.9,378.3,572.3,376.6,572.3L376.6,572.3z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M375.5,570.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L375.5,570.2z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M376.7,567c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C376.9,566.5,376.7,566.7,376.7,567z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M377,567.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C377,567.6,377,567.9,377,567.9z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-district_940gzzluwbn_raillo-overground_910gwbrmptn">
      <g id="lul-district_940gzzluwbn_raillo-overground_910gwbrmptn_2_">
        <path d="M373.7,540.9h-1.3c-0.3-0.5-0.7-0.9-1.2-1.2c-0.5-0.3-1.1-0.5-1.7-0.5c-0.9,0-1.8,0.4-2.4,1c-0.6,0.6-1,1.5-1,2.4 c0,0.9,0.4,1.8,1,2.4c0.6,0.6,1.5,1,2.4,1c0.6,0,1.2-0.2,1.7-0.5c0.5-0.3,0.9-0.7,1.2-1.2h1.3c0.3,0.5,0.7,0.9,1.2,1.2 c0.5,0.3,1.1,0.5,1.7,0.5c0.9,0,1.8-0.4,2.4-1c0.6-0.6,1-1.5,1-2.4c0-0.9-0.4-1.8-1-2.4c-0.6-0.6-1.5-1-2.4-1 c-0.6,0-1.2,0.2-1.7,0.5C374.4,540,374,540.4,373.7,540.9z" />
      </g>
      <path
        id="lul-district_940gzzluwbn_1_"
        d="M380.1,542.6c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4 C378.5,539.2,380.1,540.7,380.1,542.6z"
      />
      <path
        id="lul-district_940gzzluwbn_raillo-overground_910gwbrmptn_1_"
        fill="#FFFFFF"
        d="M376.7,540.3 c1.3,0,2.3,1,2.3,2.3c0,1.3-1,2.3-2.3,2.3c-1.1,0-1.9-0.7-2.2-1.7h-2.8c-0.3,1-1.1,1.7-2.2,1.7c-1.3,0-2.3-1-2.3-2.3 c0-1.3,1-2.3,2.3-2.3c1.1,0,1.9,0.7,2.2,1.7h2.8C374.7,541.1,375.6,540.3,376.7,540.3z"
        className="white-fill"
      />
      <g id="raillo-overground_910gwbrmptn">
        <g>
          <path
            fill="#1C3F94"
            d="M369.4,539.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C366,540.7,367.5,539.2,369.4,539.2z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M369.4,545.7c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C372.5,544.3,371.1,545.7,369.4,545.7L369.4,545.7z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M368.3,543.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L368.3,543.6z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M369.5,540.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C369.7,539.8,369.5,540.1,369.5,540.3z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M369.7,541.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C369.8,541,369.7,541.2,369.7,541.2z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-district_lul-piccadilly_940gzzluect_2_">
      <g id="lul-piccadilly_940gzzluect_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M382.7,502.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C379.2,504.4,380.8,502.8,382.7,502.8z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M381.5,507.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L381.5,507.2z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M382.7,504c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C382.9,503.5,382.7,503.7,382.7,504z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M383,504.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 h1.3c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C383.1,504.7,383,504.9,383,504.9z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="lul-district_940gzzluect_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M382.7,510.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C379.2,511.6,380.8,510.1,382.7,510.1z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M381.5,514.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L381.5,514.5z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M382.7,511.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5 C382.9,510.7,382.7,510.9,382.7,511.2z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M383,512.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C383.1,511.9,383,512.1,383,512.1z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-910gcseah_2_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M401.3,583.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C397.9,584.6,399.4,583.1,401.3,583.1z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M401.3,589.5c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C404.4,588.2,403,589.5,401.3,589.5L401.3,589.5z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M400.2,587.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L400.2,587.5z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M401.4,584.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C401.6,583.7,401.4,583.9,401.4,584.2z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M401.7,585.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C401.7,584.9,401.7,585.1,401.7,585.1z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gclphmjc_1_">
      <g id="s-910gclphmjc_3_">
        <rect
          x="419.8"
          y="622.1"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -318.8939 481.2831)"
          width="3.4"
          height="6.9"
        />
        <rect
          x="421"
          y="621.9"
          transform="matrix(0.7071 -0.7071 0.7071 0.7071 -318.8968 481.2895)"
          fill="#FFFFFF"
          width="1.1"
          height="7.3"
          className="white-fill"
        />
      </g>
      <g id="s-910gclphmjc_2_">
        <g>
          <path
            fill="#1C3F94"
            d="M425.2,625.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C421.8,627.4,423.3,625.9,425.2,625.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M418.1,618.7c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4S416.2,618.7,418.1,618.7z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M418.1,625.2c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1S419.8,625.2,418.1,625.2 L418.1,625.2z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M416.9,623.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L416.9,623.1z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M418.2,619.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C418.4,619.4,418.2,619.6,418.2,619.9z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M418.4,620.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C418.5,620.5,418.4,620.8,418.4,620.8z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
      <g id="s-910gclphmjc">
        <g>
          <path
            fill="#FFFFFF"
            d="M425.2,632.3c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C428.3,631,426.9,632.3,425.2,632.3L425.2,632.3z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M424.1,630.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L424.1,630.3z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M425.3,627c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C425.5,626.5,425.3,626.7,425.3,627z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M425.6,627.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H424c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C425.6,627.7,425.6,627.9,425.6,627.9z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-940gzzlusfs_1_" data-linestop="district">
      <g>
        <path
          fill="#1C3F94"
          d="M376.6,612.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C373.2,614.2,374.7,612.7,376.6,612.7z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M375.5,617.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L375.5,617.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M376.7,613.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C376.9,613.4,376.7,613.6,376.7,613.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M377,614.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C377,614.5,377,614.8,377,614.8z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzlumdn_2_" data-linestop="northern">
      <g>
        <path
          fill="#1C3F94"
          d="M412.5,743.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C409.1,745,410.6,743.5,412.5,743.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M411.3,747.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L411.3,747.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M412.5,744.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C412.7,744.1,412.5,744.4,412.5,744.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M412.8,745.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C412.9,745.3,412.8,745.5,412.8,745.5z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-district_lul-piccadilly_940gzzluact_1_">
      <g>
        <path
          fill="#1C3F94"
          d="M235,502.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C231.6,504.3,233.1,502.7,235,502.7z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M235,509.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C238,507.8,236.7,509.2,235,509.2L235,509.2z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M233.8,507.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L233.8,507.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M235,503.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C235.3,503.4,235,503.6,235,503.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M235.3,504.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C235.4,504.5,235.3,504.8,235.3,504.8z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910ghrgygl_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M696.3,241.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C692.9,243.2,694.4,241.7,696.3,241.7z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M696.3,248.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1 C699.3,246.8,698,248.1,696.3,248.1L696.3,248.1z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M695.1,246.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L695.1,246.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M696.3,242.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C696.6,242.3,696.3,242.5,696.3,242.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M696.6,243.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H695c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C696.7,243.5,696.6,243.7,696.6,243.7z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910ghomrton_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M781.6,336.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C778.2,338,779.7,336.5,781.6,336.5z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M781.6,342.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C784.7,341.6,783.3,342.9,781.6,342.9L781.6,342.9z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M780.5,340.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L780.5,340.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M781.7,337.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C781.9,337.1,781.7,337.3,781.7,337.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M781.9,338.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C782,338.3,781.9,338.5,781.9,338.5z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910ghacknyw_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M810.3,336.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C806.9,338,808.4,336.5,810.3,336.5z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M810.3,342.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C813.4,341.6,812,342.9,810.3,342.9L810.3,342.9z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M809.2,340.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L809.2,340.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M810.4,337.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C810.6,337.1,810.4,337.3,810.4,337.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M810.7,338.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C810.7,338.3,810.7,338.5,810.7,338.5z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-central_lul-jubilee_940gzzlustd_dlr-dlr_940gzzdlstd_raillo-overground_910gstfd_tfl-rail_910gstfd">
      <g id="lul-central_lul-jubilee_940gzzlustd_dlr-dlr_940gzzdlstd_raillo-overground_910gstfd_tfl-rail_910gstfd_1_">
        <rect x="835.7" y="338.4" width="20.8" height="3.4" />
        <rect
          x="837"
          y="339.5"
          fill="#FFFFFF"
          width="19.7"
          height="1.1"
          className="white-fill"
        />
      </g>
      <g id="lul-jubilee_940gzzlustd_dlr-dlr_940gzzdlstd_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M854.8,336.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C851.4,338.1,852.9,336.6,854.8,336.6z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M853.6,341c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L853.6,341z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M854.8,337.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C855.1,337.2,854.8,337.4,854.8,337.7z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M855.1,338.6L855,339H854c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C855.2,338.4,855.1,338.6,855.1,338.6z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="lul-central_940gzzlustd_raillo-overground_910gstfd">
        <g id="lul-central_raillo-059_940gzzlustd_3_">
          <g>
            <path
              fill="#1C3F94"
              d="M835.6,336.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C832.2,338.1,833.7,336.6,835.6,336.6z"
              className="blue-fill"
            />
          </g>
        </g>
        <g id="lul-central_raillo-059_940gzzlustd_2_">
          <g>
            <path
              fill="#FFFFFF"
              d="M835.6,343c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C838.7,341.7,837.3,343,835.6,343L835.6,343z"
              className="white-fill"
            />
          </g>
          <g>
            <g>
              <path
                fill="#1C3F94"
                d="M834.4,341c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L834.4,341z"
                className="blue-fill"
              />
              <path
                fill="#1C3F94"
                d="M835.7,337.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C835.9,337.2,835.7,337.4,835.7,337.7z"
                className="blue-fill"
              />
              <path
                fill="#1C3F94"
                d="M835.9,338.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C836,338.4,835.9,338.6,835.9,338.6z"
                className="blue-fill"
              />
            </g>
          </g>
        </g>
      </g>
      <g id="tfl-rail_910gstfd">
        <g id="tfl_rail_910glivst_2_">
          <g>
            <path
              fill="#1C3F94"
              d="M844.9,336.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C841.5,338.1,843,336.6,844.9,336.6z"
              className="blue-fill"
            />
          </g>
        </g>
        <g id="tfl_rail_910gstfd">
          <g>
            <path
              fill="#FFFFFF"
              d="M844.9,343c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C848,341.7,846.6,343,844.9,343L844.9,343z"
              className="white-fill"
            />
          </g>
          <g>
            <g>
              <path
                fill="#1C3F94"
                d="M843.8,341c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L843.8,341z"
                className="blue-fill"
              />
              <path
                fill="#1C3F94"
                d="M845,337.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C845.2,337.2,845,337.4,845,337.7z"
                className="blue-fill"
              />
              <path
                fill="#1C3F94"
                d="M845.3,338.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C845.3,338.4,845.3,338.6,845.3,338.6z"
                className="blue-fill"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="s-940gzzluupy_2_" data-linestop="district">
      <g>
        <path
          fill="#1C3F94"
          d="M920.6,343.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C917.1,344.6,918.7,343.1,920.6,343.1z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M920.6,349.6c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C923.6,348.2,922.2,349.6,920.6,349.6L920.6,349.6z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M919.4,347.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L919.4,347.5z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M920.6,344.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C920.8,343.8,920.6,344,920.6,344.3z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M920.9,345.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C921,344.9,920.9,345.1,920.9,345.1z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluwfn_1_" data-linestop="northern">
      <g>
        <path
          fill="#1C3F94"
          d="M585,199.4c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C581.6,200.9,583.1,199.4,585,199.4z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M585,205.8c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C588.1,204.4,586.7,205.8,585,205.8L585,205.8z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M583.9,203.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L583.9,203.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M585.1,200.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C585.3,200,585.1,200.2,585.1,200.5z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M585.3,201.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C585.4,201.2,585.3,201.4,585.3,201.4z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gcrpndpk_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M321.7,185.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C318.3,187.1,319.8,185.6,321.7,185.6z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M321.7,192.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C324.7,190.7,323.4,192.1,321.7,192.1L321.7,192.1z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M320.5,190c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L320.5,190z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M321.7,186.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C322,186.2,321.7,186.5,321.7,186.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M322,187.6L322,188h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C322.1,187.4,322,187.6,322,187.6z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gwatfdj_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M321.7,143.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C318.3,144.9,319.8,143.3,321.7,143.3z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M321.7,149.8c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C324.8,148.4,323.4,149.8,321.7,149.8L321.7,149.8z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M320.6,147.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L320.6,147.7z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M321.8,144.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C322,144,321.8,144.2,321.8,144.5z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M322,145.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C322.1,145.1,322,145.4,322,145.4z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-metropolitan_lul-piccadilly_940gzzluhgd_1_">
      <g>
        <path
          fill="#1C3F94"
          d="M132.2,227.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C128.8,228.7,130.4,227.2,132.2,227.2z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M132.2,233.7c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C135.3,232.3,133.9,233.7,132.2,233.7L132.2,233.7z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M131.1,231.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L131.1,231.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M132.3,228.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C132.5,227.8,132.3,228.1,132.3,228.3z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M132.6,229.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H131c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C132.6,229,132.6,229.2,132.6,229.2z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-metropolitan_lul-piccadilly_940gzzluuxb_1_">
      <g>
        <path
          fill="#1C3F94"
          d="M111.9,227.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C108.5,228.7,110,227.2,111.9,227.2z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M111.9,233.7c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C115,232.3,113.6,233.7,111.9,233.7L111.9,233.7z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M110.8,231.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L110.8,231.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M112,228.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C112.2,227.8,112,228.1,112,228.3z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M112.2,229.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C112.3,229,112.2,229.2,112.2,229.2z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-district_940gzzlukoy_raillo-overground_910gkenolym">
      <g id="lul-district_940gzzlukoy_raillo-overground_910gkenolym_1_">
        <rect x="370.3" y="480.9" width="5.1" height="3.4" />
        <rect
          x="370.1"
          y="482"
          fill="#FFFFFF"
          width="5.6"
          height="1.1"
          className="white-fill"
        />
      </g>
      <g id="raillo-overground_910gkenolym">
        <g id="raillo-059_940gzzlukoy_5_">
          <path
            fill="#1C3F94"
            d="M369.4,479.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C366,480.7,367.6,479.2,369.4,479.2z"
            className="blue-fill"
          />
        </g>
        <path
          id="raillo-059_940gzzlukoy_4_"
          fill="#FFFFFF"
          d="M369.4,485.7c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1 s3.1,1.4,3.1,3.1C372.5,484.3,371.1,485.7,369.4,485.7L369.4,485.7z"
          className="white-fill"
        />
        <g id="raillo-059_940gzzlukoy_3_">
          <g>
            <path
              fill="#1C3F94"
              d="M368.3,483.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L368.3,483.6z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M369.5,480.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C369.7,479.8,369.5,480.1,369.5,480.4z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M369.8,481.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C369.8,481,369.8,481.2,369.8,481.2z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
      <g id="lul-district_940gzzlukoy">
        <g id="lul-district_940gzzlukoy_5_">
          <path
            fill="#1C3F94"
            d="M376.6,479.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C373.2,480.7,374.8,479.2,376.6,479.2z"
            className="blue-fill"
          />
        </g>
        <path
          id="lul-district_940gzzlukoy_4_"
          fill="#FFFFFF"
          d="M376.6,485.7c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1 s3.1,1.4,3.1,3.1C379.7,484.3,378.3,485.7,376.6,485.7L376.6,485.7z"
          className="white-fill"
        />
        <g id="lul-district_940gzzlukoy_3_">
          <g>
            <path
              fill="#1C3F94"
              d="M375.5,483.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L375.5,483.6z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M376.7,480.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C376.9,479.8,376.7,480.1,376.7,480.4z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M377,481.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C377.1,481,377,481.2,377,481.2z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-circle_lul-district_lul_jubilee_940gzzluwsm">
      <g id="s-940gzzluwsm" data-linestop="jubilee">
        <rect x="514.5" y="503.9" width="3.4" height="8.9" />
        <rect
          x="515.7"
          y="503.7"
          fill="#FFFFFF"
          width="1.1"
          height="9.3"
          className="white-fill"
        />
      </g>
      <g id="s-940gzzluwsm_1_" data-linestop="jubilee">
        <g id="lul-jubilee_940gzzluwsm_1_">
          <g>
            <path
              fill="#1C3F94"
              d="M516.2,501.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C512.8,503.1,514.3,501.5,516.2,501.5z"
              className="blue-fill"
            />
          </g>
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M515.1,506c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L515.1,506z"
                className="white-fill"
              />
              <path
                fill="#FFFFFF"
                d="M516.3,502.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C516.5,502.2,516.3,502.4,516.3,502.7z"
                className="white-fill"
              />
              <path
                fill="#FFFFFF"
                d="M516.6,503.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H515c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C516.6,503.4,516.6,503.6,516.6,503.6z"
                className="white-fill"
              />
            </g>
          </g>
        </g>
        <g id="lul-circle_lul-district_940gzzluwsm_1_">
          <g>
            <path
              fill="#1C3F94"
              d="M516.2,508.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C512.8,510.3,514.3,508.7,516.2,508.7z"
              className="blue-fill"
            />
            <path
              fill="#FFFFFF"
              d="M516.2,515.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C519.3,513.8,517.9,515.2,516.2,515.2L516.2,515.2z"
              className="white-fill"
            />
          </g>
          <g>
            <g>
              <path
                fill="#1C3F94"
                d="M515.1,513.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L515.1,513.1z"
                className="blue-fill"
              />
              <path
                fill="#1C3F94"
                d="M516.3,509.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C516.5,509.4,516.3,509.6,516.3,509.9z"
                className="blue-fill"
              />
              <path
                fill="#1C3F94"
                d="M516.6,510.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H515c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 h1.3c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C516.6,510.5,516.6,510.8,516.6,510.8z"
                className="blue-fill"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="s-910ganerley_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M723.9,677.6c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4S722,677.6,723.9,677.6z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M723.9,684.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C727,682.7,725.6,684.1,723.9,684.1L723.9,684.1z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M722.8,682c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L722.8,682z"
            className="blue-fill"
          />
          <circle
            fill="#1C3F94"
            cx="724.5"
            cy="678.7"
            r="0.5"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M724.3,679.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C724.3,679.4,724.3,679.6,724.3,679.6z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gsydenhm_2_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M723.9,654.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C720.5,656.3,722,654.8,723.9,654.8z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M723.9,661.2c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1S725.6,661.2,723.9,661.2 L723.9,661.2z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M722.8,659.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L722.8,659.2z"
            className="blue-fill"
          />
          <circle
            fill="#1C3F94"
            cx="724.5"
            cy="655.9"
            r="0.5"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M724.3,656.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C724.3,656.6,724.3,656.8,724.3,656.8z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gforesth_2_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M723.9,643c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4S722,643,723.9,643z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M723.9,649.4c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C727,648.1,725.6,649.4,723.9,649.4L723.9,649.4z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M722.8,647.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L722.8,647.4z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M724,644.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C724.2,643.6,724,643.8,724,644.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M724.3,645l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C724.3,644.8,724.3,645,724.3,645z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gbrockly_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M723.9,618.4c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4S722,618.4,723.9,618.4z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M723.9,624.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C727,623.5,725.6,624.9,723.9,624.9L723.9,624.9z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M722.8,622.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L722.8,622.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M724,619.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C724.2,619.1,724,619.3,724,619.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M724.3,620.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C724.3,620.2,724.3,620.5,724.3,620.5z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910ghonropk_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M723.9,630.4c1.9,0,3.4,1.5,3.4,3.4s-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4S722,630.4,723.9,630.4z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M723.9,636.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C727,635.5,725.6,636.9,723.9,636.9L723.9,636.9z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M722.8,634.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L722.8,634.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M724,631.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C724.2,631,724,631.3,724,631.5z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M724.3,632.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C724.3,632.2,724.3,632.4,724.3,632.4z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gnwcrell_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M765.2,603.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C761.8,605.4,763.3,603.9,765.2,603.9z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M765.2,610.4c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C768.2,609,766.9,610.4,765.2,610.4L765.2,610.4z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M764,608.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L764,608.3z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M765.2,605.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C765.5,604.6,765.2,604.8,765.2,605.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M765.5,606l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C765.6,605.7,765.5,606,765.5,606z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gpckhmqd_2_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M666.4,603.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C663,605.4,664.6,603.9,666.4,603.9z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M666.4,610.4c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C669.5,609,668.1,610.4,666.4,610.4L666.4,610.4z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M665.3,608.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L665.3,608.3z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M666.5,605.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C666.7,604.6,666.5,604.8,666.5,605.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M666.8,606l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C666.8,605.7,666.8,606,666.8,606z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-jubilee_940gzzlucwr_raillo-overground_910gcndaw">
      <g id="lul-jubilee_940gzzlucwr_raillo-overground_910gcndaw_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M724,510.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C720.5,511.6,722.1,510.1,724,510.1z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M722.8,514.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L722.8,514.5z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M724,511.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C724.2,510.7,724,511,724,511.2z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M724.3,512.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C724.4,511.9,724.3,512.1,724.3,512.1z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-910gcldnnrb_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M647.8,320.4c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C644.4,321.9,646,320.4,647.8,320.4z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M647.8,326.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C650.9,325.5,649.5,326.9,647.8,326.9L647.8,326.9z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M646.7,324.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L646.7,324.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M647.9,321.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C648.1,321.1,647.9,321.3,647.9,321.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M648.2,322.5l-0.1,0.4H647c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C648.2,322.2,648.2,322.5,648.2,322.5z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluhlt_1_" data-linestop="central">
      <g>
        <path
          fill="#1C3F94"
          d="M891,216.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C887.6,218.1,889.1,216.6,891,216.6z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M891,223c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C894,221.7,892.7,223,891,223L891,223z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M889.8,221c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L889.8,221z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M891,217.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C891.3,217.2,891,217.4,891,217.7z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M891.3,218.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C891.4,218.4,891.3,218.6,891.3,218.6z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluwof_1_" data-linestop="central">
      <g>
        <path
          fill="#1C3F94"
          d="M843.2,218.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C839.8,220,841.3,218.5,843.2,218.5z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M843.2,224.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C846.3,223.6,844.9,224.9,843.2,224.9L843.2,224.9z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M842,222.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L842,222.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M843.3,219.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C843.5,219.1,843.3,219.3,843.3,219.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M843.5,220.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H842c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C843.6,220.3,843.5,220.5,843.5,220.5z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzlurvy_1_" data-linestop="central">
      <g>
        <path
          fill="#1C3F94"
          d="M856.9,203.4c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C853.5,204.9,855,203.4,856.9,203.4z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M856.9,209.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C859.9,208.5,858.6,209.9,856.9,209.9L856.9,209.9z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M855.7,207.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L855.7,207.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M856.9,204.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C857.2,204,856.9,204.3,856.9,204.5z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M857.2,205.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C857.3,205.2,857.2,205.4,857.2,205.4z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluepg_1_" data-linestop="central">
      <g>
        <path
          fill="#1C3F94"
          d="M843.2,135.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C839.8,136.8,841.3,135.3,843.2,135.3z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M843.2,141.8c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C846.3,140.4,844.9,141.8,843.2,141.8L843.2,141.8z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M842,139.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L842,139.7z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M843.3,136.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C843.5,136,843.3,136.2,843.3,136.5z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M843.5,137.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H842c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C843.6,137.1,843.5,137.4,843.5,137.4z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gcrystlp_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M702.8,683.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C699.4,684.8,700.9,683.3,702.8,683.3z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M702.8,689.8c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C705.8,688.4,704.5,689.8,702.8,689.8L702.8,689.8z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M701.6,687.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L701.6,687.7z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M702.8,684.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C703.1,684,702.8,684.2,702.8,684.5z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M703.1,685.4l-0.1,0.4H702c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C703.2,685.1,703.1,685.4,703.1,685.4z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gdenmrkh_1_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M579.9,652.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C576.5,654,578,652.5,579.9,652.5z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M579.9,659c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C582.9,657.6,581.6,659,579.9,659L579.9,659z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M578.7,656.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L578.7,656.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M579.9,653.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C580.2,653.2,579.9,653.4,579.9,653.7z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M580.2,654.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C580.3,654.3,580.2,654.6,580.2,654.6z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-bakerloo_lul-circle_lul-hammersmith-city_lul-jubilee_lul-metropolitan_940gzzlubst_1_">
      <path
        id="lul-bakerloo_lul-circle_lul-hammersmith-city_lul-metropolitan_lul-jubilee_940gzzlubst_8_"
        d=" M467.8,381.7l-3.7,3.7c-1.1-0.3-2.4,0-3.3,0.9c-1.3,1.3-1.3,3.5,0,4.8c1.3,1.3,3.5,1.3,4.8,0c0.9-0.9,1.2-2.2,0.9-3.3l3.7-3.7 c1.1,0.3,2.4,0,3.3-0.9c1.3-1.3,1.3-3.5,0-4.8c-1.3-1.3-3.5-1.3-4.8,0C467.8,379.3,467.5,380.6,467.8,381.7z"
      />
      <g id="lul-bakerloo_lul-jubilee_940gzzlubst_3_">
        <path d="M465.6,391.1c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0C467,387.6,467,389.8,465.6,391.1z" />
      </g>
      <g id="lul-circle_lul-hammersmith-city_lul-metropolitan_940gzzlubst_3_">
        <path d="M473.5,383.2c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0C474.9,379.7,474.9,381.9,473.5,383.2 z" />
      </g>
      <path
        id="lul-bakerloo_lul-circle_lul-hammersmith-city_lul-metropolitan_lul-jubilee_940gzzlubst_7_"
        fill="#FFFFFF"
        d=" M469.5,379.2c0.9-0.9,2.3-0.9,3.2,0c0.9,0.9,0.9,2.3,0,3.2c-0.8,0.8-1.9,0.9-2.8,0.3l-4.8,4.8c0.5,0.9,0.4,2-0.3,2.8 c-0.9,0.9-2.3,0.9-3.2,0c-0.9-0.9-0.9-2.3,0-3.2c0.8-0.8,1.9-0.9,2.8-0.3l4.8-4.8C468.7,381.1,468.8,380,469.5,379.2z"
        className="white-fill"
      />
    </g>
    <g id="lul-bakerloo_lul-circle_lul-district_lul-northern_l940gzzluemb_1_">
      <path
        id="lul-bakerloo_lul-circle_lul-district_lul-northern_l940gzzluemb"
        d="M542.9,508.9L540,506 c0.3-1.1,0-2.4-0.9-3.3c-1.3-1.3-3.5-1.3-4.8,0c-1.3,1.3-1.3,3.5,0,4.8c0.9,0.9,2.2,1.2,3.3,0.9l2.9,2.9c-0.3,1.1,0,2.4,0.9,3.3 c1.3,1.3,3.5,1.3,4.8,0c1.3-1.3,1.3-3.5,0-4.8C545.3,508.8,544,508.6,542.9,508.9z"
      />
      <g id="lul-northern_l940gzzluemb">
        <path d="M534.3,507.5c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0c1.3,1.3,1.3,3.5,0,4.8C537.8,508.8,535.6,508.8,534.3,507.5z " />
      </g>
      <g id="lul-circle_lul_bakerloo_lul_district_l940gzzluemb">
        <path d="M541.4,514.6c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0c1.3,1.3,1.3,3.5,0,4.8C544.9,515.9,542.7,515.9,541.4,514.6z " />
      </g>
      <path
        id="lul-bakerloo_lul-circle_lul-district_lul-northern_l940gzzluemb_2_"
        fill="#FFFFFF"
        d="M545.4,510.5 c0.9,0.9,0.9,2.3,0,3.2c-0.9,0.9-2.3,0.9-3.2,0c-0.8-0.8-0.9-1.9-0.3-2.8l-4-4c-0.9,0.5-2,0.4-2.8-0.3c-0.9-0.9-0.9-2.3,0-3.2 c0.9-0.9,2.3-0.9,3.2,0c0.8,0.8,0.9,1.9,0.3,2.8l4,4C543.5,509.7,544.6,509.8,545.4,510.5z"
        className="white-fill"
      />
    </g>
    <g id="lul-victoria_940gzzluhai_raillo-overground_910ghghi">
      <g id="lul-victoria_940gzzluhai_raillo-overground_910ghghi_1_">
        <rect
          x="666"
          y="316.3"
          transform="matrix(0.7072 -0.707 0.707 0.7072 -34.005 567.1833)"
          width="3.4"
          height="16.8"
        />
      </g>
      <g id="lul-victoria_940gzzluhai_1_">
        <path
          id="vic_940gzzluhai_1_"
          d="M664.2,321.1c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0 C665.5,317.6,665.5,319.7,664.2,321.1z"
        />
        <path
          id="s-940gzzluhai_4_"
          fill="#FFFFFF"
          d="M660.2,320.3c0.9,0.9,2.3,0.9,3.2,0c0.9-0.9,0.9-2.3,0-3.2 c-0.9-0.9-2.3-0.9-3.2,0C659.3,317.9,659.3,319.4,660.2,320.3z"
          className="white-fill"
          data-linestop="victoria"
        />
        <rect
          id="s-940gzzluhai_1_"
          x="667.3"
          y="316.3"
          transform="matrix(0.7065 -0.7077 0.7077 0.7065 -33.8262 567.9251)"
          fill="#FFFFFF"
          width="1.1"
          height="16.9"
          className="white-fill"
          data-linestop="victoria"
        />
      </g>
      <g id="raillo-overground_x28_gospel_oak-new_cross_x29__910ghghi">
        <g>
          <path
            fill="#1C3F94"
            d="M673.8,327.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C670.4,328.9,672,327.3,673.8,327.3z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M673.8,333.8c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1 C676.9,332.4,675.5,333.8,673.8,333.8L673.8,333.8z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M672.7,331.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L672.7,331.7z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M673.9,328.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C674.1,328,673.9,328.2,673.9,328.5z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M674.2,329.4l-0.1,0.4H673c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C674.2,329.1,674.2,329.4,674.2,329.4z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
      <g id="raillo-overground_x28_richmond-stratford_x29__910ghghi">
        <g>
          <path
            fill="#1C3F94"
            d="M667,320.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C663.6,322,665.2,320.5,667,320.5z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M667,327c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1C670.1,325.6,668.7,327,667,327 L667,327z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M665.9,324.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L665.9,324.9z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M667.1,321.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C667.3,321.2,667.1,321.4,667.1,321.7z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M667.4,322.6l0,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C667.4,322.3,667.4,322.6,667.4,322.6z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="dlr-dlr_940gzzdlsha_raillo-overground_910gshadwel_1_">
      <g id="dlr-dlr_940gzzdlsha_raillo-overground_910gshadwel">
        <g id="dlr-dlr_940gzzdlsha_raillo-overground_910gshadwel_3_">
          <path
            id="raillo-059_910gshadwel_2_"
            d="M726.9,468.2l-2.2,2.2c-1.1-0.3-2.4,0-3.3,0.9c-1.3,1.3-1.3,3.5,0,4.8 c1.3,1.3,3.5,1.3,4.8,0c0.9-0.9,1.2-2.2,0.9-3.3l2.2-2.2c1.1,0.3,2.4,0,3.3-0.9c1.3-1.3,1.3-3.5,0-4.8c-1.3-1.3-3.5-1.3-4.8,0 C726.9,465.8,726.6,467,726.9,468.2z"
          />
        </g>
        <path
          id="raillo-overground_910gshadwel"
          d="M726.3,471.2c1.3,1.3,1.3,3.5,0,4.8c-1.3,1.3-3.5,1.3-4.8,0 c-1.3-1.3-1.3-3.5,0-4.8C722.8,469.9,724.9,469.9,726.3,471.2z"
        />
        <path
          id="dlr-dlr_940gzzdlsha_raillo-overground_910gshadwel_2_"
          fill="#FFFFFF"
          d="M728.6,465.7 c0.9-0.9,2.3-0.9,3.2,0c0.9,0.9,0.9,2.3,0,3.2c-0.7,0.8-1.9,0.9-2.8,0.3l-3.2,3.2c0.5,0.9,0.4,2-0.3,2.8c-0.9,0.9-2.3,0.9-3.2,0 c-0.9-0.9-0.9-2.3,0-3.2c0.8-0.7,1.9-0.9,2.8-0.3l3.2-3.2C727.7,467.6,727.8,466.4,728.6,465.7z"
          className="white-fill"
        />
        <g id="dlr-dlr_940gzzdlsha_1_">
          <g>
            <path
              fill="#1C3F94"
              d="M730.2,463.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C726.8,465.4,728.3,463.9,730.2,463.9z"
              className="blue-fill"
            />
          </g>
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M729,468.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L729,468.3z"
                className="white-fill"
              />
              <path
                fill="#FFFFFF"
                d="M730.3,465c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5S730.3,464.8,730.3,465z"
                className="white-fill"
              />
              <path
                fill="#FFFFFF"
                d="M730.5,465.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H729c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 h1.3c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C730.6,465.7,730.5,465.9,730.5,465.9z"
                className="white-fill"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="lul-bakerloo_lul-jubilee_lul-northern_lul-waterloo-city_940gzzluwlo_5_">
      <g id="lul-bakerloo_lul-northern_lul-waterloo-city_940gzzluwlo_1_">
        <g id="lul-bakerloo_lul-jubilee_lul-northern_lul-waterloo-city_940gzzluwlo_6_">
          <path
            id="lul-bakerloo_lul-jubilee_lul-northern_lul-waterloo-city_940gzzluwlo_8_"
            d="M543.6,532.3 c-1.3,0-2.4,0.7-3,1.7h-0.9c-0.6-1-1.7-1.7-3-1.7c-1.3,0-2.4,0.7-3,1.7h-1.2c-0.6-1-1.7-1.7-3-1.7c-1.9,0-3.4,1.5-3.4,3.4 c0,1.9,1.5,3.4,3.4,3.4c1.3,0,2.4-0.7,3-1.7h1.2c0.6,1,1.7,1.7,3,1.7c1.3,0,2.4-0.7,3-1.7h0.9c0.6,1,1.7,1.7,3,1.7 c1.9,0,3.4-1.5,3.4-3.4C547,533.9,545.5,532.3,543.6,532.3z"
          />
          <path
            id="lul-bakerloo_lul-waterloo-city_940gzzluwlo_1_"
            d="M547,535.8c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 c0-1.9,1.5-3.4,3.4-3.4C545.5,532.4,547,533.9,547,535.8z"
          />
          <path
            id="lul-northern_940gzzluwlo_1_"
            d="M540.2,535.8c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 c0-1.9,1.5-3.4,3.4-3.4C538.6,532.4,540.2,533.9,540.2,535.8z"
          />
          <path
            id="lul-jubilee_940gzzluwlo_3_"
            d="M533.1,535.8c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4 C531.5,532.3,533.1,533.9,533.1,535.8z"
          />
          <path
            id="lul-bakerloo_lul-jubilee_lul-northern_lul-waterloo-city_940gzzluwlo_7_"
            fill="#FFFFFF"
            d=" M545.8,535.8c0,1.3-1,2.3-2.3,2.3c-1.1,0-2-0.7-2.2-1.7h-2.4c-0.3,1-1.1,1.7-2.2,1.7c-1.1,0-1.9-0.7-2.2-1.7h-2.7 c-0.3,1-1.1,1.7-2.2,1.7c-1.3,0-2.3-1-2.3-2.3c0-1.3,1-2.3,2.3-2.3c1.1,0,1.9,0.7,2.2,1.7h2.7c0.3-1,1.1-1.7,2.2-1.7 c1.1,0,1.9,0.7,2.2,1.7h2.4c0.3-1,1.1-1.7,2.2-1.7C544.8,533.5,545.8,534.5,545.8,535.8z"
            className="white-fill"
          />
        </g>
      </g>
      <g id="lul-jubilee_940gzzluwlo_2_">
        <g>
          <path
            fill="#1C3F94"
            d="M529.7,532.4c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C526.2,533.9,527.8,532.4,529.7,532.4z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M528.5,536.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L528.5,536.8z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M529.7,533.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5 C529.9,533,529.7,533.2,529.7,533.5z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M530,534.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C530.1,534.2,530,534.4,530,534.4z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-central_lul-circle_lul-hammersmith-city_lul-metropolitan_940gzzlulvt_railo-overground_910glivst_tfl-rail_910glivst">
      <g id="lul-central_lul-circle_lul-hammersmith-city_lul-metropolitan_940gzzlulvt">
        <g>
          <rect x="659.5" y="410.7" width="3.4" height="10.4" />
        </g>
        <g>
          <path
            fill="#FFFFFF"
            d="M661.2,425.9c-1.6,0-3-1.3-3-3c0-1.6,1.3-3,3-3c1.6,0,3,1.3,3,3C664.2,424.6,662.8,425.9,661.2,425.9z"
            className="white-fill"
          />
          <path d="M661.2,426.5c-2,0-3.6-1.6-3.6-3.6c0-2,1.6-3.6,3.6-3.6c2,0,3.6,1.6,3.6,3.6C664.8,424.9,663.2,426.5,661.2,426.5z M658.8,422.9c0,1.3,1.1,2.4,2.4,2.4c1.3,0,2.4-1.1,2.4-2.4c0-1.3-1.1-2.4-2.4-2.4C659.9,420.5,658.8,421.6,658.8,422.9z" />
        </g>
        <rect
          x="660.6"
          y="410.5"
          fill="#FFFFFF"
          width="1.1"
          height="10.8"
          className="white-fill"
        />
      </g>
      <g id="railo-overground_910glivst_tfl-rail_910glivst">
        <g>
          <path
            fill="#1C3F94"
            d="M661.1,407.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C657.7,408.7,659.3,407.2,661.1,407.2z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M661.1,413.7c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C664.2,412.3,662.8,413.7,661.1,413.7L661.1,413.7z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M660,411.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L660,411.6z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M661.2,408.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C661.4,407.9,661.2,408.1,661.2,408.4z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M661.5,409.3l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C661.5,409,661.5,409.3,661.5,409.3z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-circle_lul-district_lul-hammersmith-city_lul-piccadilly_940gzzluhsd">
      <g id="lul-circle_lul-hammersmith-city_lul_piccadilly_940gzzluhsc_1_">
        <path
          fill="#FFFFFF"
          d="M332.1,508.8l0,2c-1,0.4-1.7,1.4-1.7,2.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8 c0-1.2-0.7-2.2-1.7-2.6v-2c1-0.4,1.7-1.4,1.7-2.6c0-1.6-1.3-2.8-2.8-2.8c-1.6,0-2.8,1.3-2.8,2.8 C330.4,507.4,331.1,508.4,332.1,508.8z"
          className="white-fill"
        />
        <path d="M331,506.2c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,1.1-0.7,1.9-1.7,2.2v2.8c1,0.3,1.7,1.1,1.7,2.2 c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.1,0.7-1.9,1.7-2.2l0-2.8C331.7,508.2,331,507.3,331,506.2z M331.5,509.2l0,1.3 c-1,0.6-1.7,1.7-1.7,3c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4c0-1.3-0.7-2.4-1.7-3v-1.3c1-0.6,1.7-1.7,1.7-3 c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4C329.8,507.5,330.5,508.6,331.5,509.2z" />
      </g>
      <g id="lul-circle_lul-hammersmith-city_940gzzluhsc_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M333.3,495.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C329.8,496.8,331.4,495.3,333.3,495.3z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M332.1,499.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L332.1,499.7z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M333.3,496.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C333.5,496,333.3,496.2,333.3,496.5z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M333.6,497.3l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H332c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C333.7,497.1,333.6,497.3,333.6,497.3z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="lul-piccadilly_940gzzluhsd">
        <g>
          <path
            fill="#1C3F94"
            d="M333.3,502.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C329.8,504.3,331.4,502.8,333.3,502.8z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M332.1,507.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L332.1,507.2z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M333.3,504c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C333.5,503.5,333.3,503.7,333.3,504z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M333.6,504.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H332c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C333.7,504.6,333.6,504.9,333.6,504.9z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="lul-district_940gzzluhsd">
        <g>
          <path
            fill="#1C3F94"
            d="M333.3,510.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C329.8,511.6,331.4,510.1,333.3,510.1z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M332.1,514.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L332.1,514.5z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M333.3,511.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C333.5,510.7,333.3,510.9,333.3,511.2z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M333.6,512.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H332c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1L331,515c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C333.7,511.9,333.6,512.1,333.6,512.1z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-district_lul-hammersmith-city_lul-jubilee_940gzzluwhm_dlr-dlr_940gzzdlwhm">
      <rect
        x="849.3"
        y="388.1"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -29.2244 717.0889)"
        width="3.4"
        height="11.4"
      />
      <rect
        x="850.3"
        y="388.2"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -29.1708 716.9601)"
        fill="#FFFFFF"
        width="1.1"
        height="11"
        className="white-fill"
      />
      <g id="lul-jubilee_940gzzluwhm">
        <g>
          <path
            fill="#1C3F94"
            d="M846.4,385.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C843,387.3,844.5,385.8,846.4,385.8z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M845.2,390.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L845.2,390.2z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M846.4,386.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C846.6,386.4,846.4,386.7,846.4,386.9z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M846.7,387.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C846.8,387.6,846.7,387.8,846.7,387.8z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="lul-district_lul-hammersmith-city_940gzzluwhm_dlr-dlr_940gzzdlwhm">
        <g>
          <path
            fill="#1C3F94"
            d="M855.2,394.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C851.8,396.2,853.3,394.6,855.2,394.6z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M854,399c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L854,399z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M855.3,395.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C855.5,395.3,855.3,395.5,855.3,395.8z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M855.5,396.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H854c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C855.6,396.4,855.5,396.7,855.5,396.7z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-910gemrspkh" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M974.4,246.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C971,248.4,972.6,246.8,974.4,246.8z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M974.4,253.3c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C977.5,251.9,976.1,253.3,974.4,253.3L974.4,253.3z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M973.3,251.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L973.3,251.3z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M974.5,248c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C974.7,247.5,974.5,247.7,974.5,248z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M974.8,248.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C974.8,248.7,974.8,248.9,974.8,248.9z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gchdwlht_x0a_" data-linestop="tfl-rail">
      <g>
        <path
          fill="#1C3F94"
          d="M939.4,242.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C936,243.7,937.5,242.2,939.4,242.2z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M939.4,248.7c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C942.5,247.3,941.1,248.7,939.4,248.7L939.4,248.7z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M938.3,246.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L938.3,246.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M939.5,243.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C939.7,242.9,939.5,243.1,939.5,243.4z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M939.8,244.3l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C939.8,244,939.8,244.3,939.8,244.3z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="railo-overground_910gromford_tfl-rail_910gromford">
      <g>
        <path
          fill="#1C3F94"
          d="M954.6,227c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C951.2,228.6,952.7,227,954.6,227z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M954.6,233.5c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C957.7,232.1,956.3,233.5,954.6,233.5L954.6,233.5z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M953.5,231.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L953.5,231.4z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M954.7,228.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C954.9,227.7,954.7,227.9,954.7,228.2z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M954.9,229.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C955,228.8,954.9,229.1,954.9,229.1z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-victoria_940gzzlusvs_raillo-overground_910sevnsis">
      <path
        fill="#FFFFFF"
        d="M716.1,265.6c-1.1,1.1-2.9,1.1-4,0c-1.1-1.1-1.1-2.9,0-4c1.1-1.1,2.9-1.1,4,0 C717.2,262.7,717.2,264.5,716.1,265.6z"
        className="white-fill"
      />
      <path d="M716.5,266c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0C717.9,262.5,717.9,264.7,716.5,266z M712.5,265.2c0.9,0.9,2.3,0.9,3.2,0c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0C711.6,262.9,711.6,264.3,712.5,265.2z" />
    </g>
    <g id="s-910gbrtwood" data-linestop="tfl-rail">
      <g>
        <path
          fill="#1C3F94"
          d="M1002,179.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C998.6,181.2,1000.1,179.6,1002,179.6z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M1002,186.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C1005.1,184.7,1003.7,186.1,1002,186.1L1002,186.1z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M1000.8,184c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L1000.8,184z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M1002.1,180.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C1002.3,180.3,1002.1,180.5,1002.1,180.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M1002.3,181.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l-0.1,0.3h-1.4 c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1 c0.1,0,0.1,0,0.1,0l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3 C1002.4,181.5,1002.3,181.7,1002.3,181.7z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gshenfld" data-linestop="tfl-rail">
      <g>
        <path
          fill="#1C3F94"
          d="M1019.8,161.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C1016.4,163.4,1017.9,161.9,1019.8,161.9z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M1019.8,168.4c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C1022.8,167,1021.4,168.4,1019.8,168.4L1019.8,168.4z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M1018.6,166.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L1018.6,166.3z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M1019.8,163c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C1020,162.5,1019.8,162.8,1019.8,163z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M1020.1,163.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l-0.1,0.3h-1.4 c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1 c0.1,0,0.1,0,0.1,0l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3 C1020.2,163.7,1020.1,163.9,1020.1,163.9z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gedmngrn" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M714.1,190.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C710.7,192,712.2,190.5,714.1,190.5z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M714.1,196.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C717.2,195.6,715.8,196.9,714.1,196.9L714.1,196.9z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M712.9,194.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L712.9,194.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M714.2,191.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C714.4,191.1,714.2,191.3,714.2,191.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M714.4,192.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C714.5,192.3,714.4,192.5,714.4,192.5z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910genfldtn" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M714.1,145.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C710.7,147.2,712.2,145.7,714.1,145.7z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M714.1,152.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C717.2,150.8,715.8,152.2,714.1,152.2L714.1,152.2z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M712.9,150.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L712.9,150.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M714.2,146.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C714.4,146.3,714.2,146.6,714.2,146.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M714.4,147.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C714.5,147.5,714.4,147.7,714.4,147.7z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gbhillpk" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M714.1,159.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C710.7,161,712.2,159.5,714.1,159.5z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M714.1,165.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C717.2,164.6,715.8,165.9,714.1,165.9L714.1,165.9z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M712.9,163.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L712.9,163.9z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M714.2,160.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C714.4,160.1,714.2,160.3,714.2,160.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M714.4,161.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C714.5,161.3,714.4,161.5,714.4,161.5z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gcheshnt" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M760,135.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C756.6,136.8,758.1,135.3,760,135.3z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M760,141.8c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C763.1,140.4,761.7,141.8,760,141.8L760,141.8z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M758.8,139.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L758.8,139.7z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M760,136.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C760.3,136,760,136.2,760,136.5z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M760.3,137.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C760.4,137.1,760.3,137.4,760.3,137.4z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910ghghmspk_x0a_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M786.8,201.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C783.4,203.2,784.9,201.7,786.8,201.7z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M786.8,208.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C789.8,206.7,788.5,208.1,786.8,208.1L786.8,208.1z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M785.6,206.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L785.6,206.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M786.8,202.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C787.1,202.3,786.8,202.5,786.8,202.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M787.1,203.7l-0.1,0.4H786c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C787.2,203.5,787.1,203.7,787.1,203.7z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gchingfd" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M786.8,177.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C783.4,178.7,784.9,177.2,786.8,177.2z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M786.8,183.7c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C789.8,182.3,788.5,183.7,786.8,183.7L786.8,183.7z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M785.6,181.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L785.6,181.6z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M786.8,178.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C787.1,177.9,786.8,178.1,786.8,178.4z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M787.1,179.3l-0.1,0.4H786c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C787.2,179,787.1,179.3,787.1,179.3z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlgal_2_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M991.6,537.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C988.2,539.1,989.8,537.5,991.6,537.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M990.5,542c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L990.5,542z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M991.7,538.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5s-0.2-0.5-0.5-0.5 C991.9,538.2,991.7,538.4,991.7,538.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M992,539.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C992,539.4,992,539.6,992,539.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzdlbec_2_" data-linestop="dlr">
      <g>
        <path
          fill="#1C3F94"
          d="M1004.1,550c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C1000.7,551.5,1002.2,550,1004.1,550z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M1002.9,554.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L1002.9,554.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M1004.2,551.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C1004.4,550.6,1004.2,550.9,1004.2,551.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M1004.4,552l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C1004.5,551.8,1004.4,552,1004.4,552z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul_victoria_940gzzluwwl_raillo-overground_910gwltwcen_raillo-overground_910gwlthqrd_2_">
      <g id="lul_victoria_940gzzluwwl_raillo-overground_910wltwcen_raillo-overground_910wlthqrd_2_">
        <rect
          x="771.2"
          y="270.1"
          transform="matrix(0.7072 -0.707 0.707 0.7072 37.49 634.0472)"
          width="26.1"
          height="3.4"
        />
      </g>
      <g id="lul-victoria_940gzzluwwl_1_">
        <path
          fill="#FFFFFF"
          d="M795.6,263.6c0,1.6-1.3,2.8-2.8,2.8c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8 C794.4,260.7,795.6,262,795.6,263.6z"
          className="white-fill"
        />
        <path d="M796.2,263.6c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C794.7,260.2,796.2,261.7,796.2,263.6z M792.8,265.8c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3c-1.3,0-2.3,1-2.3,2.3C790.5,264.8,791.5,265.8,792.8,265.8z" />
      </g>
      <rect
        id="lul_victoria_9100wltwcen_raillo-overground_910wltwcen_raillo-overground_910wlthqrd_2_"
        x="771.8"
        y="271.5"
        transform="matrix(0.707 -0.7072 0.7072 0.707 37.2546 634.0831)"
        fill="#FFFFFF"
        width="24.2"
        height="1.1"
        className="white-fill"
      />
      <g id="s-910gwltwcen_1_" data-linestop="london-overground">
        <g>
          <path
            fill="#1C3F94"
            d="M786.8,265.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C783.4,267.3,784.9,265.8,786.8,265.8z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M786.8,272.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C789.9,270.9,788.5,272.2,786.8,272.2L786.8,272.2z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M785.7,270.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L785.7,270.2z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M786.9,266.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C787.1,266.4,786.9,266.6,786.9,266.9z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M787.1,267.8l-0.1,0.4H786c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C787.2,267.6,787.1,267.8,787.1,267.8z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
      <g id="s-910gwlthqrd_1_" data-linestop="london-overground">
        <g>
          <path
            fill="#1C3F94"
            d="M776.7,275.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C773.3,277.4,774.8,275.9,776.7,275.9z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M776.7,282.4c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1 C779.7,281,778.4,282.4,776.7,282.4L776.7,282.4z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M775.5,280.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L775.5,280.3z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M776.7,277c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C777,276.5,776.7,276.8,776.7,277z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M777,277.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C777.1,277.7,777,277.9,777,277.9z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="raillo-overground_910gwnstdpk_tfl-rail_910gfrstgt">
      <g id="raillo-overground_910gwnstdpk_tfl-rail_910gfrstgt_2_">
        <rect
          x="873.2"
          y="291.6"
          transform="matrix(0.7072 -0.707 0.707 0.7072 44.5415 706.2791)"
          width="3.4"
          height="15.6"
        />
      </g>
      <g id="raillo-overground_910gwnstdpk">
        <path
          fill="#FFFFFF"
          d="M868.4,292.5c1.1-1.1,2.9-1.1,4,0c1.1,1.1,1.1,2.9,0,4c-1.1,1.1-2.9,1.1-4,0 C867.3,295.4,867.3,293.6,868.4,292.5z"
          className="white-fill"
        />
        <path d="M868,292.1c1.3-1.3,3.5-1.3,4.8,0c1.3,1.3,1.3,3.5,0,4.8c-1.3,1.3-3.5,1.3-4.8,0C866.7,295.6,866.7,293.4,868,292.1z M872.1,292.9c-0.9-0.9-2.3-0.9-3.2,0c-0.9,0.9-0.9,2.3,0,3.2c0.9,0.9,2.3,0.9,3.2,0C873,295.2,873,293.8,872.1,292.9z" />
      </g>
      <g id="tfl-rail_910gfrstgt">
        <path
          fill="#FFFFFF"
          d="M878.4,302.5c1.1-1.1,2.9-1.1,4,0c1.1,1.1,1.1,2.9,0,4c-1.1,1.1-2.9,1.1-4,0 C877.3,305.4,877.3,303.6,878.4,302.5z"
          className="white-fill"
        />
        <path d="M878,302.1c1.3-1.3,3.5-1.3,4.8,0c1.3,1.3,1.3,3.5,0,4.8c-1.3,1.3-3.5,1.3-4.8,0C876.7,305.6,876.7,303.4,878,302.1z M882.1,302.9c-0.9-0.9-2.3-0.9-3.2,0c-0.9,0.9-0.9,2.3,0,3.2c0.9,0.9,2.3,0.9,3.2,0C883,305.2,883,303.8,882.1,302.9z" />
      </g>
      <rect
        id="raillo-overground_910gwnstdpk_tfl-rail_910gfrstgt_1_"
        x="874.8"
        y="292.8"
        transform="matrix(0.707 -0.7072 0.7072 0.707 44.4602 706.8809)"
        fill="#FFFFFF"
        width="1.1"
        height="14"
        className="white-fill"
      />
    </g>
    <g id="raillo-overground_910ghaknynm_raillo-overground_910ghacknyc">
      <g id="raillo-overground_910ghaknynm_raillo-overground_910ghacknyc_2_">
        <rect
          x="750.9"
          y="328.8"
          transform="matrix(0.7072 -0.707 0.707 0.7072 -16.7535 630.3555)"
          width="3.4"
          height="13.2"
        />
      </g>
      <g id="s-910ghaknynm" data-linestop="london-overground">
        <path
          id="vic_940gzzluhai_2_"
          d="M750.3,333.1c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0 C751.6,329.6,751.6,331.7,750.3,333.1z"
        />
        <path
          id="s-940gzzluhai_3_"
          fill="#FFFFFF"
          d="M746.3,332.3c0.9,0.9,2.3,0.9,3.2,0c0.9-0.9,0.9-2.3,0-3.2 c-0.9-0.9-2.3-0.9-3.2,0C745.4,329.9,745.4,331.4,746.3,332.3z"
          className="white-fill"
          data-linestop="victoria"
        />
      </g>
      <rect
        id="raillo-overground_910ghaknynm_raillo-overground_910ghacknyc_1_"
        x="752.2"
        y="328.8"
        transform="matrix(0.7065 -0.7077 0.7077 0.7065 -16.5167 631.1343)"
        fill="#FFFFFF"
        width="1.1"
        height="13.3"
        className="white-fill"
      />
      <g id="s-910ghacknyc" data-linestop="london-overground">
        <g>
          <path
            fill="#1C3F94"
            d="M757.1,336.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C753.7,338,755.3,336.5,757.1,336.5z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M757.1,342.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C760.2,341.6,758.8,342.9,757.1,342.9L757.1,342.9z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M756,340.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L756,340.9z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M757.2,337.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C757.4,337.1,757.2,337.3,757.2,337.6z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M757.5,338.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C757.5,338.3,757.5,338.5,757.5,338.5z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-district_940gzzluupm_railo-overground_910gupmnstr">
      <g id="lul-district_940gzzluupm_railo-overground_910gupmnstr_2_">
        <rect x="994.9" y="262.9" width="3.4" height="9.8" />
      </g>
      <g id="railo-overground_910gupmnstr">
        <path
          fill="#FFFFFF"
          d="M996.6,266.1c-1.6,0-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8c1.6,0,2.8,1.3,2.8,2.8 C999.5,264.8,998.2,266.1,996.6,266.1z"
          className="white-fill"
        />
        <path d="M996.6,266.7c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4c1.9,0,3.4,1.5,3.4,3.4C1000,265.1,998.5,266.7,996.6,266.7z M994.3,263.3c0,1.3,1,2.3,2.3,2.3c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3C995.4,261,994.3,262,994.3,263.3z" />
      </g>
      <rect
        id="lul-district_940gzzluupm_railo-overground_910gupmnstr_1_"
        x="996"
        y="264.3"
        fill="#FFFFFF"
        width="1.1"
        height="7.9"
        className="white-fill"
      />
      <g id="lul-district_940gzzluupm">
        <g id="lul_district_940gzzluupm">
          <g>
            <path
              fill="#1C3F94"
              d="M996.6,267.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C993.2,268.6,994.7,267.1,996.6,267.1z"
              className="blue-fill"
            />
          </g>
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M995.5,271.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L995.5,271.5z"
                className="white-fill"
              />
              <path
                fill="#FFFFFF"
                d="M996.7,268.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C996.9,267.7,996.7,268,996.7,268.2z"
                className="white-fill"
              />
              <path
                fill="#FFFFFF"
                d="M996.9,269.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C997,268.9,996.9,269.1,996.9,269.1z"
                className="white-fill"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="s-940gzzlucar_2_" data-linestop="piccadilly">
      <g>
        <path
          fill="#1C3F94"
          d="M635.4,313.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C632,315.1,633.5,313.5,635.4,313.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M634.2,317.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L634.2,317.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M635.4,314.7c0,0.3,0.2,0.5,0.5,0.5s0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5S635.4,314.4,635.4,314.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M635.7,315.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l-0.1,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C635.8,315.3,635.7,315.6,635.7,315.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gkenr" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M342.9,325.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C339.5,327.2,341,325.6,342.9,325.6z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M342.9,332.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C346,330.7,344.6,332.1,342.9,332.1L342.9,332.1z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M341.8,330c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L341.8,330z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M343,326.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C343.2,326.3,343,326.5,343,326.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M343.2,327.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C343.3,327.4,343.2,327.7,343.2,327.7z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-910gnewxgte_2_" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M723.9,604c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C720.5,605.5,722.1,604,723.9,604z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M723.9,610.4c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C727,609.1,725.6,610.4,723.9,610.4L723.9,610.4z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M722.8,608.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L722.8,608.4z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M724,605.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C724.2,604.6,724,604.8,724,605.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M724.3,606l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C724.3,605.8,724.3,606,724.3,606z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-jubilee_940gzzlucyf_dlr-dlr_940gzzdlcan_1_">
      <g id="lul-jubilee_940gzzlucyf_dlr-dlr_940gzzdlcan">
        <rect
          x="788"
          y="509.1"
          transform="matrix(0.7072 -0.707 0.707 0.7072 -129.2496 709.4832)"
          width="7.9"
          height="3.4"
        />
        <rect
          x="787.8"
          y="510.2"
          transform="matrix(0.707 -0.7072 0.7072 0.707 -129.1953 709.7086)"
          fill="#FFFFFF"
          width="8.3"
          height="1.1"
          className="white-fill"
        />
      </g>
      <g id="lul-jubilee_940gzzlucyf_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M789.3,510c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C785.9,511.5,787.4,510,789.3,510z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M788.1,514.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L788.1,514.4z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M789.3,511.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C789.6,510.7,789.3,510.9,789.3,511.2z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M789.6,512.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C789.7,511.8,789.6,512.1,789.6,512.1z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="dlr-dlr_940gzzdlcan">
        <g>
          <path
            fill="#1C3F94"
            d="M795.5,503.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C792.1,505.3,793.6,503.8,795.5,503.8z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M794.4,508.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L794.4,508.2z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M795.6,504.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C795.8,504.4,795.6,504.6,795.6,504.9z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M795.9,505.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C795.9,505.6,795.9,505.8,795.9,505.8z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-910gcnnb" data-linestop="london-overground">
      <g>
        <path
          fill="#FFFFFF"
          d="M690.3,326.1l0,2c-1,0.4-1.7,1.4-1.7,2.6c0,1.6,1.3,2.8,2.8,2.8c1.6,0,2.8-1.3,2.8-2.8 c0-1.2-0.7-2.2-1.7-2.6v-2c1-0.4,1.7-1.4,1.7-2.6c0-1.6-1.3-2.8-2.8-2.8c-1.6,0-2.8,1.3-2.8,2.8 C688.6,324.7,689.3,325.7,690.3,326.1z"
          className="white-fill"
        />
        <path d="M689.1,323.5c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,1.1-0.7,1.9-1.7,2.2v2.8c1,0.3,1.7,1.1,1.7,2.2 c0,1.3-1,2.3-2.3,2.3c-1.3,0-2.3-1-2.3-2.3c0-1.1,0.7-1.9,1.7-2.2l0-2.8C689.9,325.5,689.1,324.6,689.1,323.5z M689.7,326.5 l0,1.3c-1,0.6-1.7,1.7-1.7,3c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4c0-1.3-0.7-2.4-1.7-3v-1.3c1-0.6,1.7-1.7,1.7-3 c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4C688,324.8,688.7,325.9,689.7,326.5z" />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M691.4,327.4c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C688,328.9,689.5,327.4,691.4,327.4z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M690.3,331.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L690.3,331.8z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M691.5,328.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C691.7,328,691.5,328.3,691.5,328.5z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M691.8,329.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C691.8,329.2,691.8,329.4,691.8,329.4z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M691.4,320.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C688,321.7,689.5,320.1,691.4,320.1z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M691.4,326.6c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C694.5,325.2,693.1,326.6,691.4,326.6L691.4,326.6z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M690.3,324.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L690.3,324.5z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M691.5,321.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C691.7,320.8,691.5,321,691.5,321.3z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M691.8,322.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C691.8,321.9,691.8,322.2,691.8,322.2z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-jubilee_lul-metropolitan_940gzzlufyr_1_">
      <path
        id="lul-jubilee_lul-metropolitan_940gzzlufyr_6_"
        d="M439.2,342.4l-0.8,0.8c-1.1-0.3-2.4,0-3.3,0.9 c-1.3,1.3-1.3,3.5,0,4.8c1.3,1.3,3.5,1.3,4.8,0c0.9-0.9,1.2-2.2,0.9-3.3l0.8-0.8c1.1,0.3,2.4,0,3.3-0.9c1.3-1.3,1.3-3.5,0-4.8 c-1.3-1.3-3.5-1.3-4.8,0C439.2,340,438.9,341.3,439.2,342.4z"
      />
      <g id="lul-metropolitan_940gzzlufyr_2_">
        <path d="M439.9,348.9c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.6-1.5,5-0.1C441.4,345.3,441.3,347.6,439.9,348.9 z" />
      </g>
      <g id="lul-jubilee_940gzzlufyr_2_">
        <path d="M444.9,343.9c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0C446.2,340.4,446.2,342.6,444.9,343.9 z" />
      </g>
      <path
        id="lul-jubilee_lul-metropolitan_940gzzlufyr_2_"
        fill="#FFFFFF"
        d="M440.9,339.9c0.9-0.9,2.3-0.9,3.2,0 c0.9,0.9,0.9,2.3,0,3.2c-0.8,0.8-1.9,0.9-2.8,0.3l-1.8,1.8c0.5,0.9,0.4,2-0.3,2.8c-0.9,0.9-2.3,0.9-3.2,0c-0.9-0.9-0.9-2.3,0-3.2 c0.8-0.8,1.9-0.9,2.8-0.3l1.8-1.8C440,341.8,440.1,340.7,440.9,339.9z"
        className="white-fill"
      />
    </g>
    <g id="s-910gstotnhm" data-linestop="london-overground">
      <g>
        <path
          fill="#1C3F94"
          d="M735.1,241.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C731.7,243.2,733.2,241.7,735.1,241.7z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M735.1,248.1c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1 C738.1,246.8,736.7,248.1,735.1,248.1L735.1,248.1z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M733.9,246.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L733.9,246.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M735.1,242.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C735.3,242.3,735.1,242.5,735.1,242.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M735.4,243.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C735.5,243.5,735.4,243.7,735.4,243.7z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-jubilee_940gzzluwhp_raillo-overground_910gwhmdstd_1_">
      <path
        id="lul-jubilee_940gzzluwhp_raillo-overground_910gwhmdstd_3_"
        d="M436.6,330l-0.8,0.8c-1.1-0.3-2.4,0-3.3,0.9 c-1.3,1.3-1.3,3.5,0,4.8c1.3,1.3,3.5,1.3,4.8,0c0.9-0.9,1.2-2.2,0.9-3.3l0.8-0.8c1.1,0.3,2.4,0,3.3-0.9c1.3-1.3,1.3-3.5,0-4.8 c-1.3-1.3-3.5-1.3-4.8,0C436.6,327.6,436.3,328.8,436.6,330z"
      />
      <g id="lul-jubilee_940gzzluwhp">
        <path d="M437.3,336.5c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.6-1.5,5-0.1C438.8,332.8,438.7,335.1,437.3,336.5 z" />
      </g>
      <g id="raillo-overground_910gwhmdstd">
        <path d="M442.3,331.5c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0C443.6,328,443.6,330.2,442.3,331.5z" />
      </g>
      <path
        id="lul-jubilee_940gzzluwhp_raillo-overground_910gwhmdstd_2_"
        fill="#FFFFFF"
        d="M438.3,327.5 c0.9-0.9,2.3-0.9,3.2,0c0.9,0.9,0.9,2.3,0,3.2c-0.8,0.8-1.9,0.9-2.8,0.3l-1.8,1.8c0.5,0.9,0.4,2-0.3,2.8c-0.9,0.9-2.3,0.9-3.2,0 c-0.9-0.9-0.9-2.3,0-3.2c0.8-0.8,1.9-0.9,2.8-0.3l1.8-1.8C437.4,329.4,437.5,328.2,438.3,327.5z"
        className="white-fill"
      />
    </g>
    <g id="lul_jubilee_lul_northern_940gzzlulnb_1_">
      <g>
        <path
          fill="#1C3F94"
          d="M636.7,510c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C633.3,511.5,634.8,510,636.7,510z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M635.5,514.4c0.2,0.5,0.7,0.9,1.2,0.9c0.4,0,0.7-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9 c0-0.4-0.2-0.7-0.4-1l0.1-0.6c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L635.5,514.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M636.7,511.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C637,510.6,636.7,510.9,636.7,511.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M637,512l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C637.1,511.8,637,512,637,512z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrddr" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M383.1,670.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C379.7,672.5,381.3,670.9,383.1,670.9z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M382,675.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L382,675.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M383.2,672.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C383.4,671.6,383.2,671.8,383.2,672.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M383.5,673l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C383.5,672.8,383.5,673,383.5,673z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrmtp_x0a_" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M405.6,706.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C402.2,708.2,403.7,706.7,405.6,706.7z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M404.5,711.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L404.5,711.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M405.7,707.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C405.9,707.3,405.7,707.6,405.7,707.8z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M405.9,708.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C406,708.5,405.9,708.7,405.9,708.7z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrmdn" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M451.2,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C447.7,743.1,449.3,741.5,451.2,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M450,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L450,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M451.2,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C451.4,742.2,451.2,742.4,451.2,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M451.5,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C451.6,743.4,451.5,743.6,451.5,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrphi" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M472.5,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C469.1,743.1,470.6,741.5,472.5,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M471.4,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L471.4,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M472.6,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C472.8,742.2,472.6,742.4,472.6,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M472.8,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C472.9,743.4,472.8,743.6,472.8,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrbgv" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M495.1,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C491.7,743.1,493.2,741.5,495.1,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M494,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L494,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M495.2,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C495.4,742.2,495.2,742.4,495.2,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M495.5,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C495.5,743.4,495.5,743.6,495.5,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrmch" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M520.5,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C517,743.1,518.6,741.5,520.5,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M519.3,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L519.3,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M520.5,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C520.7,742.2,520.5,742.4,520.5,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M520.8,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C520.9,743.4,520.8,743.6,520.8,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrmjt" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M545.2,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C541.8,743.1,543.3,741.5,545.2,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M544,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L544,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M545.2,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C545.5,742.2,545.2,742.4,545.2,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M545.5,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H544c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C545.6,743.4,545.5,743.6,545.5,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrbed_x0a_" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M575.5,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C572.1,743.1,573.6,741.5,575.5,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M574.3,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L574.3,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M575.5,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C575.8,742.2,575.5,742.4,575.5,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M575.8,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C575.9,743.4,575.8,743.6,575.8,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrtpa" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M601.6,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C598.2,743.1,599.7,741.5,601.6,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M600.4,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L600.4,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M601.6,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C601.9,742.2,601.6,742.4,601.6,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M601.9,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C602,743.4,601.9,743.6,601.9,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcramp" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M624.4,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C621,743.1,622.6,741.5,624.4,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M623.3,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L623.3,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M624.5,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C624.7,742.2,624.5,742.4,624.5,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M624.8,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C624.8,743.4,624.8,743.6,624.8,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrwad" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M646.9,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C643.5,743.1,645,741.5,646.9,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M645.8,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L645.8,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M647,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C647.2,742.2,647,742.4,647,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M647.3,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C647.3,743.4,647.3,743.6,647.3,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrwan" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M669.4,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C666,743.1,667.5,741.5,669.4,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M668.3,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L668.3,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M669.5,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C669.7,742.2,669.5,742.4,669.5,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M669.8,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C669.8,743.4,669.8,743.6,669.8,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrrvc" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M687.6,732c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C684.2,733.6,685.7,732,687.6,732z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M686.4,736.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L686.4,736.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M687.6,733.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C687.9,732.7,687.6,732.9,687.6,733.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M687.9,734.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C688,733.9,687.9,734.1,687.9,734.1z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrctr" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M709.3,720.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C705.9,722.4,707.4,720.8,709.3,720.8z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M708.1,725.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L708.1,725.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M709.3,722c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C709.6,721.5,709.3,721.7,709.3,722z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M709.6,722.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C709.7,722.6,709.6,722.9,709.6,722.9z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrchr" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M714.4,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C711,743.1,712.5,741.5,714.4,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M713.2,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L713.2,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M714.4,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C714.6,742.2,714.4,742.4,714.4,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M714.7,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C714.8,743.4,714.7,743.6,714.7,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrcen" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M745.7,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C742.3,743.1,743.8,741.5,745.7,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M744.6,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L744.6,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M745.8,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C746,742.2,745.8,742.4,745.8,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M746,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C746.1,743.4,746,743.6,746,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="raillo-overground_910gwcroydn_tram-tram_940gzzcrwcr">
      <g id="raillo-overground_910gwcroydn_tram-tram_940gzzcrwcr_2_">
        <path d="M724.7,720.5l2.9,2.9c-0.3,1.1,0,2.4,0.9,3.3c1.3,1.3,3.5,1.3,4.8,0c1.3-1.3,1.3-3.5,0-4.8c-0.9-0.9-2.2-1.2-3.3-0.9 l-2.9-2.9c0.3-1.1,0-2.4-0.9-3.3c-1.3-1.3-3.5-1.3-4.8,0c-1.3,1.3-1.3,3.5,0,4.8C722.3,720.5,723.6,720.8,724.7,720.5z" />
      </g>
      <path
        id="raillo-overground_910gwcroydn_tram-tram_940gzzcrwcr_1_"
        fill="#FFFFFF"
        d="M722.2,718.9 c-0.9-0.9-0.9-2.3,0-3.2c0.9-0.9,2.3-0.9,3.2,0c0.7,0.7,0.9,1.9,0.3,2.8l4,4c0.9-0.5,2-0.4,2.8,0.3c0.9,0.9,0.9,2.3,0,3.2 c-0.9,0.9-2.3,0.9-3.2,0c-0.8-0.8-0.9-1.9-0.3-2.8l-4-4C724.1,719.7,723,719.6,722.2,718.9z"
        className="white-fill"
      />
      <g id="s-910gwcroydn_1_" data-linestop="london-overground">
        <g>
          <path
            fill="#1C3F94"
            d="M723.9,713.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C720.5,715.4,722,713.8,723.9,713.8z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M723.9,720.3c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C726.9,718.9,725.6,720.3,723.9,720.3L723.9,720.3z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M722.7,718.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L722.7,718.2z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M723.9,715c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C724.1,714.5,723.9,714.7,723.9,715z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M724.2,715.9l0,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C724.3,715.7,724.2,715.9,724.2,715.9z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
      <g id="tram-tram_940gzzcrwcr">
        <g>
          <path
            fill="#1C3F94"
            d="M730.9,720.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C727.5,722.4,729,720.9,730.9,720.9z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M729.8,725.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L729.8,725.3z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M731,722.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C731.2,721.6,731,721.8,731,722.1z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M731.3,723l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C731.3,722.7,731.3,723,731.3,723z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-940gzzcrwel" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M748.2,720.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C744.7,722.4,746.3,720.8,748.2,720.8z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M747,725.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L747,725.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M748.2,722c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C748.4,721.5,748.2,721.7,748.2,722z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M748.5,722.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C748.6,722.6,748.5,722.9,748.5,722.9z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrecr" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M776.9,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C773.5,743.1,775,741.5,776.9,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M775.7,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L775.7,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M777,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C777.2,742.2,777,742.4,777,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M777.2,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C777.3,743.4,777.2,743.6,777.2,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrleb" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M793.2,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C789.8,743.1,791.3,741.5,793.2,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M792.1,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L792.1,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M793.3,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C793.5,742.2,793.3,742.4,793.3,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M793.5,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H792c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C793.6,743.4,793.5,743.6,793.5,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrsan" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M808.2,741.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C804.8,743.1,806.4,741.5,808.2,741.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M807.1,746c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L807.1,746z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M808.3,742.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C808.5,742.2,808.3,742.4,808.3,742.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M808.6,743.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H807c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C808.7,743.4,808.6,743.6,808.6,743.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcradd" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M825.2,730.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C821.8,732.3,823.3,730.7,825.2,730.7z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M824,735.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L824,735.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M825.2,731.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C825.5,731.4,825.2,731.6,825.2,731.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M825.5,732.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H824c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C825.6,732.6,825.5,732.8,825.5,732.8z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrbla" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M825.2,718.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C821.8,720.4,823.3,718.9,825.2,718.9z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M824,723.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L824,723.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M825.2,720c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C825.5,719.5,825.2,719.7,825.2,720z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M825.5,720.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H824c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C825.6,720.7,825.5,720.9,825.5,720.9z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrwod" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M825.2,707c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C821.8,708.5,823.3,707,825.2,707z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M824,711.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L824,711.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M825.2,708.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C825.5,707.6,825.2,707.9,825.2,708.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M825.5,709l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H824c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1L823,712c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C825.6,708.8,825.5,709,825.5,709z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrara" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M825.2,695.1c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C821.8,696.6,823.3,695.1,825.2,695.1z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M824,699.5c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L824,699.5z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M825.2,696.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C825.5,695.7,825.2,696,825.2,696.2z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M825.5,697.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H824c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C825.6,696.9,825.5,697.1,825.5,697.1z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrhar" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M825.2,672.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C821.8,673.7,823.3,672.2,825.2,672.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M824,676.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L824,676.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M825.2,673.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C825.5,672.9,825.2,673.1,825.2,673.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M825.5,674.3l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H824c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C825.6,674,825.5,674.3,825.5,674.3z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrelm" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M876.9,682.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C873.4,684.1,875,682.6,876.9,682.6z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M875.7,687c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L875.7,687z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M876.9,683.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C877.1,683.2,876.9,683.5,876.9,683.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M877.2,684.6l-0.1,0.4H876c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C877.3,684.4,877.2,684.6,877.2,684.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrbir" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M855.8,653.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C852.4,654.7,853.9,653.2,855.8,653.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M854.6,657.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L854.6,657.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M855.8,654.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C856.1,653.8,855.8,654,855.8,654.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M856.1,655.2l-0.1,0.4H855c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C856.2,655,856.1,655.2,856.1,655.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrave" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M884.3,653.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C880.9,654.7,882.4,653.2,884.3,653.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M883.1,657.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L883.1,657.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M884.3,654.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C884.6,653.8,884.3,654,884.3,654.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M884.6,655.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C884.7,655,884.6,655.2,884.6,655.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrbrd" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M912.4,653.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C909,654.7,910.5,653.2,912.4,653.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M911.2,657.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L911.2,657.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M912.5,654.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C912.7,653.8,912.5,654,912.5,654.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M912.7,655.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C912.8,655,912.7,655.2,912.7,655.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrbek" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M940.9,653.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C937.5,654.7,939,653.2,940.9,653.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M939.8,657.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L939.8,657.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M941,654.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C941.2,653.8,941,654,941,654.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M941.2,655.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C941.3,655,941.2,655.2,941.2,655.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrloy" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M827.8,748.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C824.4,750.2,825.9,748.7,827.8,748.7z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M826.6,753.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L826.6,753.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M827.8,749.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C828.1,749.3,827.8,749.6,827.8,749.8z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M828.1,750.7l-0.1,0.4H827c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C828.2,750.5,828.1,750.7,828.1,750.7z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrcoo" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M837.7,758.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C834.2,760.1,835.8,758.6,837.7,758.6z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M836.5,763c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L836.5,763z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M837.7,759.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C837.9,759.2,837.7,759.4,837.7,759.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M838,760.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C838.1,760.4,838,760.6,838,760.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrgra" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M847.5,768.4c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C844.1,770,845.6,768.4,847.5,768.4z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M846.4,772.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L846.4,772.8z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M847.6,769.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C847.8,769.1,847.6,769.3,847.6,769.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M847.9,770.5l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C847.9,770.2,847.9,770.5,847.9,770.5z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcradv" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M857.4,778.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C854,779.8,855.5,778.3,857.4,778.3z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M856.2,782.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L856.2,782.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M857.5,779.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C857.7,778.9,857.5,779.2,857.5,779.5z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M857.7,780.3l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C857.8,780.1,857.7,780.3,857.7,780.3z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrfld" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M867.3,788.2c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C863.9,789.7,865.4,788.2,867.3,788.2z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M866.1,792.6c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L866.1,792.6z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M867.3,789.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C867.6,788.8,867.3,789,867.3,789.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M867.6,790.2l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C867.7,790,867.6,790.2,867.6,790.2z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrkgh" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M894.8,802c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C891.4,803.5,893,802,894.8,802z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M893.7,806.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L893.7,806.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M894.9,803.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C895.1,802.6,894.9,802.9,894.9,803.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M895.2,804l-0.1,0.4H894c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C895.2,803.8,895.2,804,895.2,804z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzcrnwa" data-linestop="tram">
      <g>
        <path
          fill="#1C3F94"
          d="M924.2,802c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C920.8,803.5,922.3,802,924.2,802z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M923,806.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L923,806.4z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M924.2,803.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C924.5,802.6,924.2,802.9,924.2,803.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M924.5,804l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H923c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1L922,807c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C924.6,803.8,924.5,804,924.5,804z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzlugfd_1_" data-linestop="central">
      <g>
        <path
          fill="#1C3F94"
          d="M174.1,352.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C170.7,354.2,172.3,352.7,174.1,352.7z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M174.1,359.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C177.2,357.8,175.8,359.2,174.1,359.2L174.1,359.2z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M173,357.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L173,357.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M174.2,353.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C174.4,353.3,174.2,353.6,174.2,353.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M174.5,354.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C174.5,354.5,174.5,354.7,174.5,354.7z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-circle_lul-hammersmith-city_lul-metropolitan_lul-northern_lul-piccadilly_lul-victoria_940gzzluksx">
      <g id="lul-circle_lul-hammersmith-city_lul-metropolitan_lul-northern_lul-piccadilly_lul-victoria_940gzzluksx_4_">
        <rect x="576.2" y="367.8" width="3.4" height="13.5" />
      </g>
      <g id="lul-circle_lul-hammersmith-city_lul-metropolitan_940gzzluksx">
        <rect
          x="577.4"
          y="367.6"
          fill="#FFFFFF"
          width="1.1"
          height="13.9"
          className="white-fill"
        />
      </g>
      <g id="lul-victoria_940gzzluksx_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M577.9,362.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C574.5,364.3,576,362.7,577.9,362.7z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M576.8,367.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L576.8,367.1z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M578,363.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C578.2,363.4,578,363.6,578,363.9z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M578.2,364.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l-0.1,0.3h-1.4 c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1 c0.1,0,0.1,0,0.1,0l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3 C578.3,364.5,578.2,364.8,578.2,364.8z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="lul-northern_lul-piccadilly_940gzzluksx_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M577.9,370.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C574.5,372.2,576,370.7,577.9,370.7z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M576.8,375.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L576.8,375.1z"
              className="white-fill"
            />
            <circle
              fill="#FFFFFF"
              cx="578.5"
              cy="371.9"
              r="0.5"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M578.2,372.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l-0.1,0.3h-1.4 c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1 c0.1,0,0.1,0,0.1,0l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3 C578.3,372.5,578.2,372.8,578.2,372.8z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="lul-circle_lul-hammersmith-city_lul-metropolitan_940gzzluksx_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M577.9,377.8c2,0,3.6,1.6,3.6,3.6c0,2-1.6,3.6-3.6,3.6c-2,0-3.6-1.6-3.6-3.6 C574.3,379.4,575.9,377.8,577.9,377.8z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M576.7,382.4c0.2,0.6,0.7,1,1.3,1c0.8,0,1.4-0.6,1.4-1.4c0-0.4-0.2-0.8-0.4-1l0.1-0.6 c0.5,0.3,0.9,0.9,0.9,1.6c0,1-0.8,1.9-1.9,1.9c-0.7,0-1.3-0.4-1.6-0.9L576.7,382.4z"
              className="white-fill"
            />
            <circle
              fill="#FFFFFF"
              cx="578.5"
              cy="379"
              r="0.5"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M578.3,379.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.3c0,0.2,0.2,0.3,0.2,0.3h1.1l-0.1,0.4h-1.4 c0,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.3c0,0,0.1-0.1,0.1-0.1 c0.1,0,0.1,0,0.1,0l1.3,0c0,0,0.1,0,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3 C578.3,379.7,578.3,379.9,578.3,379.9z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-940gzzluvxl" data-linestop="victoria">
      <g>
        <path
          fill="#1C3F94"
          d="M481.8,608.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C478.3,610.2,479.9,608.7,481.8,608.7z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M480.6,613.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L480.6,613.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M481.8,609.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C482,609.3,481.8,609.5,481.8,609.8z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M482.1,610.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C482.2,610.5,482.1,610.7,482.1,610.7z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzluepk" data-linestop="district">
      <g>
        <path
          fill="#1C3F94"
          d="M967.2,296.5c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C963.8,298,965.3,296.5,967.2,296.5z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M966,300.9c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L966,300.9z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M967.3,297.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C967.5,297.2,967.3,297.4,967.3,297.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M967.5,298.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H966c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C967.6,298.3,967.5,298.6,967.5,298.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="s-940gzzludgy" data-linestop="district">
      <g>
        <path
          fill="#1C3F94"
          d="M939.8,323.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C936.4,325.4,937.9,323.9,939.8,323.9z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M938.6,328.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L938.6,328.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M939.8,325c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C940.1,324.5,939.8,324.8,939.8,325z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M940.1,325.9l-0.1,0.4H939c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C940.2,325.7,940.1,325.9,940.1,325.9z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-circle_lul-district_940gzzlubkf">
      <g>
        <path
          fill="#1C3F94"
          d="M595.7,495.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C592.3,497.1,593.9,495.6,595.7,495.6z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M594.6,500c0.2,0.5,0.7,0.9,1.2,0.9c0.4,0,0.7-0.1,0.9-0.4c0.2-0.2,0.4-0.6,0.4-0.9c0-0.4-0.2-0.7-0.4-1 l0.1-0.6c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L594.6,500z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M595.8,496.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C596,496.2,595.8,496.4,595.8,496.7z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M596.1,497.6L596,498h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C596.1,497.4,596.1,497.6,596.1,497.6z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-circle_lul-district_940gzzlutwh_dlr-dlr_940gzzdltwg_1_">
      <g id="lul-circle_lul-district_940gzzlutwh_dlr-dlr_940gzzdltwg_3_">
        <path d="M691,475.1l-5-5.1c0.3-1.1,0-2.4-0.9-3.3c-1.3-1.3-3.5-1.3-4.8,0c-1.3,1.3-1.3,3.5,0,4.8c0.9,0.9,2.2,1.2,3.3,0.9 l5.1,5.1c-0.3,1.1,0,2.4,0.9,3.3c1.3,1.3,3.5,1.3,4.8,0c1.3-1.3,1.3-3.5,0-4.8C693.4,475.1,692.2,474.8,691,475.1z" />
      </g>
      <path
        id="lul-circle_lul-district_940gzzlutwh_dlr-dlr_940gzzdltwg"
        fill="#FFFFFF"
        d="M693.5,476.8 c0.9,0.9,0.9,2.3,0,3.2c-0.9,0.9-2.3,0.9-3.2,0c-0.8-0.7-0.9-1.9-0.3-2.8l-6.1-6.1c-0.9,0.5-2,0.4-2.8-0.3c-0.9-0.9-0.9-2.3,0-3.2 c0.9-0.9,2.3-0.9,3.2,0c0.7,0.8,0.9,1.9,0.3,2.8l6.1,6.1C691.6,475.9,692.8,476,693.5,476.8z"
        className="white-fill"
      />
      <g id="dlr-dlr_940gzzdltwg_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M691.9,475c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C688.5,476.5,690,475,691.9,475z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M690.8,479.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L690.8,479.4z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M692,476.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C692.2,475.6,692,475.8,692,476.1z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M692.3,477l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 h1.3c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C692.3,476.8,692.3,477,692.3,477z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="lul-circle_lul-district_940gzzlutwh">
        <g>
          <path
            fill="#1C3F94"
            d="M682.7,465.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C679.3,467.3,680.8,465.7,682.7,465.7z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M682.7,472.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C685.8,470.8,684.4,472.2,682.7,472.2L682.7,472.2z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M681.5,470.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L681.5,470.1z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M682.8,466.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C683,466.4,682.8,466.6,682.8,466.9z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M683,467.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C683.1,467.5,683,467.8,683,467.8z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-district_lul-hammersmith-city_940gzzlubkg_raillo-overground_910gbarking_1_">
      <g id="lul-district_lul-hammersmith-city_940gzzlubkg_raillo-overground_910gbarking_2_">
        <g id="lul-district_lul-hammersmith-city_940gzzlubkg_3_">
          <rect
            x="904.8"
            y="349.2"
            transform="matrix(0.7072 -0.707 0.707 0.7072 15.4388 744.5109)"
            width="3.4"
            height="8.8"
          />
        </g>
        <rect
          id="lul-district_lul-hammersmith-city_940gzzlubkg_raillo-overground_910gbarking"
          x="906.4"
          y="350.4"
          transform="matrix(0.707 -0.7072 0.7072 0.707 15.3644 745.1011)"
          fill="#FFFFFF"
          width="1.1"
          height="7.1"
          className="white-fill"
        />
      </g>
      <g id="raillo-overground_910gbarking">
        <g>
          <path
            fill="#1C3F94"
            d="M903.7,347.4c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C900.3,348.9,901.8,347.4,903.7,347.4z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M903.7,353.8c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C906.8,352.5,905.4,353.8,903.7,353.8L903.7,353.8z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M902.6,351.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L902.6,351.8z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M903.8,348.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C904,348,903.8,348.2,903.8,348.5z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M904,349.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C904.1,349.2,904,349.4,904,349.4z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
      <g id="lul-district_lul-hammersmith-city_940gzzlubkg">
        <g>
          <path
            fill="#1C3F94"
            d="M909.4,353c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C906,354.6,907.5,353,909.4,353z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M908.2,357.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L908.2,357.4z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M909.4,354.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C909.7,353.7,909.4,353.9,909.4,354.2z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M909.7,355.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C909.8,354.8,909.7,355.1,909.7,355.1z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-district_lul-hammersmith-city_940gzzluehm">
      <g>
        <path
          fill="#1C3F94"
          d="M899,362.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C895.6,364.5,897.1,362.9,899,362.9z"
          className="blue-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#FFFFFF"
            d="M897.8,367.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L897.8,367.3z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M899,364.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C899.3,363.6,899,363.8,899,364.1z"
            className="white-fill"
          />
          <path
            fill="#FFFFFF"
            d="M899.3,365l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C899.4,364.7,899.3,365,899.3,365z"
            className="white-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-northern_940gzzluacy_railo-overground_910guprhlwy">
      <rect
        x="588"
        y="262"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.3363 495.7478)"
        width="3.4"
        height="13.7"
      />
      <g id="lul-northern_940gzzluacy">
        <path
          fill="#FFFFFF"
          d="M587,266.1c-1.1,1.1-2.9,1.1-4,0c-1.1-1.1-1.1-2.9,0-4c1.1-1.1,2.9-1.1,4,0 C588.1,263.1,588.1,264.9,587,266.1z"
          className="white-fill"
        />
        <path d="M587.4,266.5c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0C588.7,263,588.7,265.1,587.4,266.5z M583.4,265.7c0.9,0.9,2.3,0.9,3.2,0c0.9-0.9,0.9-2.3,0-3.2c-0.9-0.9-2.3-0.9-3.2,0C582.5,263.3,582.5,264.8,583.4,265.7z" />
      </g>
      <rect
        x="589.2"
        y="261.8"
        transform="matrix(0.7071 -0.7071 0.7071 0.7071 -17.3396 495.7538)"
        fill="#FFFFFF"
        width="1.1"
        height="14.1"
        className="white-fill"
      />
      <g id="s-910guprhlwy_1_" data-linestop="london-overground">
        <g>
          <path
            fill="#1C3F94"
            d="M594.2,269.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C590.8,271.4,592.4,269.9,594.2,269.9z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M594.2,276.3c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C597.3,275,595.9,276.3,594.2,276.3L594.2,276.3z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M593.1,274.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L593.1,274.3z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M594.3,271c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C594.5,270.5,594.3,270.7,594.3,271z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M594.6,271.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H593c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C594.7,271.7,594.6,271.9,594.6,271.9z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-circle_lul-district_lul-victoria_940gzzluvic_1_">
      <rect
        x="467.1"
        y="507.9"
        transform="matrix(0.7072 -0.707 0.707 0.7072 -222.191 482.6406)"
        width="8.9"
        height="3.4"
      />
      <g id="lul-circle_lul-district_940gzzluvic_1_">
        <path
          fill="#FFFFFF"
          d="M471.6,512.3c0,1.6-1.3,2.8-2.8,2.8s-2.8-1.3-2.8-2.8c0-1.6,1.3-2.8,2.8-2.8S471.6,510.7,471.6,512.3z"
          className="white-fill"
        />
        <path d="M472.2,512.3c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4c0-1.9,1.5-3.4,3.4-3.4C470.6,508.8,472.2,510.4,472.2,512.3z M468.8,514.5c1.3,0,2.3-1,2.3-2.3c0-1.3-1-2.3-2.3-2.3s-2.3,1-2.3,2.3C466.5,513.5,467.5,514.5,468.8,514.5z" />
      </g>
      <rect
        x="466.9"
        y="509"
        transform="matrix(0.707 -0.7072 0.7072 0.707 -222.2234 482.7921)"
        fill="#FFFFFF"
        width="9.3"
        height="1.1"
        className="white-fill"
      />
      <g id="lul-victoria_940gzzluvic_1_">
        <g>
          <path
            fill="#1C3F94"
            d="M475.9,501.6c1.9,0,3.5,1.6,3.5,3.5c0,1.9-1.6,3.5-3.5,3.5c-1.9,0-3.5-1.6-3.5-3.5 C472.4,503.2,473.9,501.6,475.9,501.6z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M474.7,506.1c0.2,0.6,0.7,1,1.3,1c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.9,0.9,0.9,1.6c0,1-0.8,1.8-1.8,1.8c-0.7,0-1.2-0.4-1.6-0.9L474.7,506.1z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M475.9,502.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C476.1,502.3,475.9,502.5,475.9,502.8z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M476.2,503.7l-0.1,0.4H475c0,0-0.2,0-0.2,0.3c0,0.2,0.2,0.2,0.2,0.2h1.1l-0.1,0.3h-1.4 c0,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.3c0,0,0.1-0.1,0.1-0.1 c0.1,0,0.1,0,0.1,0l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3 C476.3,503.5,476.2,503.7,476.2,503.7z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-central_lul-jubilee_940gzzlubnd">
      <g id="lul-central_lul-jubilee_940gzzlubnd_3_">
        <rect
          x="455.7"
          y="428"
          transform="matrix(0.7072 -0.707 0.707 0.7072 -169.097 451.2108)"
          width="8.9"
          height="3.4"
        />
        <rect
          x="455.5"
          y="429.2"
          transform="matrix(0.707 -0.7072 0.7072 0.707 -169.1181 451.3472)"
          fill="#FFFFFF"
          width="9.3"
          height="1.1"
          className="white-fill"
        />
      </g>
      <g id="lul-central_lul-jubilee_940gzzlubnd_2_">
        <g id="lul-jubilee_940gzzlubnd">
          <g>
            <path
              fill="#1C3F94"
              d="M463.3,423.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C459.8,424.8,461.4,423.3,463.3,423.3z"
              className="blue-fill"
            />
          </g>
          <g>
            <g>
              <path
                fill="#FFFFFF"
                d="M462.1,427.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L462.1,427.7z"
                className="white-fill"
              />
              <path
                fill="#FFFFFF"
                d="M463.3,424.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C463.5,423.9,463.3,424.1,463.3,424.4z"
                className="white-fill"
              />
              <path
                fill="#FFFFFF"
                d="M463.6,425.3l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H462c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C463.7,425.1,463.6,425.3,463.6,425.3z"
                className="white-fill"
              />
            </g>
          </g>
        </g>
        <g id="lul-central_940gzzlubnd">
          <g>
            <path
              fill="#1C3F94"
              d="M457.2,429.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C453.8,430.8,455.3,429.3,457.2,429.3z"
              className="blue-fill"
            />
            <path
              fill="#FFFFFF"
              d="M457.2,435.8c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C460.3,434.4,458.9,435.8,457.2,435.8L457.2,435.8z"
              className="white-fill"
            />
          </g>
          <g>
            <g>
              <path
                fill="#1C3F94"
                d="M456.1,433.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L456.1,433.7z"
                className="blue-fill"
              />
              <path
                fill="#1C3F94"
                d="M457.3,430.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C457.5,430,457.3,430.2,457.3,430.5z"
                className="blue-fill"
              />
              <path
                fill="#1C3F94"
                d="M457.5,431.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H456c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 h1.3c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C457.6,431.1,457.5,431.4,457.5,431.4z"
                className="blue-fill"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
    <g id="lul-central_lul-northern_940gzzlutcr_1_">
      <g id="lul-central_lul-northern_940gzzlutcr">
        <rect
          x="529.2"
          y="428"
          transform="matrix(0.7072 -0.707 0.707 0.7072 -147.5685 503.1928)"
          width="8.9"
          height="3.4"
        />
        <rect
          x="529"
          y="429.2"
          transform="matrix(0.707 -0.7072 0.7072 0.707 -147.5774 503.3413)"
          fill="#FFFFFF"
          width="9.3"
          height="1.1"
          className="white-fill"
        />
      </g>
      <g id="lul-northern_940gzzlutcr">
        <g>
          <path
            fill="#1C3F94"
            d="M536.8,423.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C533.4,424.8,534.9,423.3,536.8,423.3z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M535.6,427.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L535.6,427.7z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M536.8,424.4c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C537.1,423.9,536.8,424.1,536.8,424.4z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M537.1,425.3l-0.1,0.4H536c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C537.2,425.1,537.1,425.3,537.1,425.3z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="lul-central_940gzzlutcr">
        <g>
          <path
            fill="#1C3F94"
            d="M530.7,429.3c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C527.3,430.8,528.8,429.3,530.7,429.3z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M530.7,435.8c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C533.8,434.4,532.4,435.8,530.7,435.8L530.7,435.8z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M529.6,433.7c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L529.6,433.7z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M530.8,430.5c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C531,430,530.8,430.2,530.8,430.5z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M531.1,431.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 h1.3c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C531.1,431.1,531.1,431.4,531.1,431.4z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-bakerloo_lul-circle_lul-district_lul-hammersmith-city_940gzzlupac_tfl-rail_910gpadton">
      <path
        id="lul-circle_lul-hammersmith-city_940gzzlupac_tfl-rail_910gpadton"
        d="M380.2,381.1l-2.9,2.9 c-1.1-0.3-2.4,0-3.3,0.9c-1.3,1.3-1.3,3.5,0,4.8c1.3,1.3,3.5,1.3,4.8,0c0.9-0.9,1.2-2.2,0.9-3.3l2.9-2.9c1.1,0.3,2.4,0,3.3-0.9 c1.3-1.3,1.3-3.5,0-4.8c-1.3-1.3-3.5-1.3-4.8,0C380.2,378.7,379.9,379.9,380.2,381.1z"
      />
      <path
        id="lul-bakerloo_lul-circle_lul-district_lul-hammersmith-city_940gzzlupac_tfl-rail_910gpadton_1_"
        fill="#FFFFFF"
        d=" M381.9,378.6c0.9-0.9,2.3-0.9,3.2,0c0.9,0.9,0.9,2.3,0,3.2c-0.8,0.8-1.9,0.9-2.8,0.3l-4,4c0.5,0.9,0.4,2-0.3,2.8 c-0.9,0.9-2.3,0.9-3.2,0c-0.9-0.9-0.9-2.3,0-3.2c0.8-0.8,1.9-0.9,2.8-0.3l4-4C381,380.5,381.1,379.3,381.9,378.6z"
        className="white-fill"
      />
      <g id="lul-bakerloo_lul-circle_lul-district_940gzzlupac_lul-hammersmith-city_940gzzlupah_3_">
        <g id="lul-bakerloo_lul-circle_lul-district_940gzzlupac_lul-hammersmith-city_940gzzlupah_1_">
          <path
            id="lul-bakerloo_lul-circle_lul-district_lul-hammersmith_940gzzlupac_2_"
            d="M394.8,386.7 c-0.9-0.9-2.2-1.2-3.3-0.9l-4.7-4.7c0.3-1.1,0-2.4-0.9-3.3c-0.9-0.9-2.2-1.2-3.3-0.9l-2.2-2.2c0.3-1.1,0-2.4-0.9-3.3 c-1.3-1.3-3.5-1.3-4.8,0c-1.3,1.3-1.3,3.5,0,4.8c0.9,0.9,2.2,1.2,3.3,0.9l2.2,2.2c-0.3,1.1,0,2.4,0.9,3.3 c0.9,0.9,2.2,1.2,3.3,0.9l4.7,4.7c-0.3,1.1,0,2.4,0.9,3.3c1.3,1.3,3.5,1.3,4.8,0C396.1,390.2,396.1,388,394.8,386.7z"
          />
          <path
            id="lul-circle_lul-district_940gzzlupac_1_"
            d="M394.8,386.7c1.3,1.3,1.3,3.5,0,4.8c-1.3,1.3-3.5,1.3-4.8,0 c-1.3-1.3-1.3-3.5,0-4.8C391.3,385.3,393.4,385.3,394.8,386.7z"
          />
          <path
            id="lul-circle_940gzzlupah_lul-hammersmith_940gzzlupah_1_"
            d="M385.9,377.8c1.3,1.3,1.3,3.5,0,4.8 c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8C382.4,376.4,384.5,376.4,385.9,377.8z"
          />
          <path
            id="lul-bakerloo_940gzzlupac_1_"
            d="M379.5,371.4c1.3,1.3,1.3,3.5,0,4.8c-1.3,1.3-3.5,1.3-4.8,0 c-1.3-1.3-1.3-3.5,0-4.8C376,370.1,378.2,370.1,379.5,371.4z"
          />
          <path
            id="lul-bakerloo_lul-circle_lul-district_940gzzlupac_lul-hammersmith-city_940gzzlupah_5_"
            fill="#FFFFFF"
            d=" M394,390.7c-0.9,0.9-2.3,0.9-3.2,0c-0.8-0.8-0.9-1.9-0.3-2.8l-5.8-5.8c-0.9,0.5-2,0.4-2.8-0.3c-0.8-0.8-0.9-1.9-0.4-2.8 l-3.3-3.3c-0.9,0.5-2,0.4-2.8-0.3c-0.9-0.9-0.9-2.3,0-3.2c0.9-0.9,2.3-0.9,3.2,0c0.7,0.8,0.9,1.9,0.3,2.8l3.3,3.3 c0.9-0.5,2-0.4,2.8,0.3c0.8,0.8,0.9,1.9,0.3,2.8l5.8,5.8c0.9-0.5,2-0.4,2.8,0.4C394.9,388.4,394.9,389.8,394,390.7z"
            className="white-fill"
          />
        </g>
      </g>
      <g id="lul-circle_lul-hammersmith-city_940gzzlupah_2_">
        <g>
          <path
            fill="#1C3F94"
            d="M383.5,376.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C380.1,378.3,381.6,376.8,383.5,376.8z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M383.5,383.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C386.5,381.9,385.2,383.2,383.5,383.2L383.5,383.2z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M382.3,381.2c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L382.3,381.2z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M383.5,377.9c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C383.8,377.4,383.5,377.6,383.5,377.9z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M383.8,378.8l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C383.9,378.6,383.8,378.8,383.8,378.8z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
      <path
        id="lul-circle_940gzzlupah_lul-hammersmith_940gzzlupah_2_"
        d="M378.8,384.8c1.3,1.3,1.3,3.5,0,4.8 c-1.3,1.3-3.5,1.3-4.8,0c-1.3-1.3-1.3-3.5,0-4.8C375.3,383.5,377.5,383.5,378.8,384.8z"
      />
      <g id="tfl-rail_910gpadton">
        <g>
          <path
            fill="#1C3F94"
            d="M376.4,383.8c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C373,385.4,374.5,383.8,376.4,383.8z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M376.4,390.3c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C379.5,388.9,378.1,390.3,376.4,390.3L376.4,390.3z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M375.2,388.3c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L375.2,388.3z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M376.5,385c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C376.7,384.5,376.5,384.7,376.5,385z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M376.7,385.9l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C376.8,385.7,376.7,385.9,376.7,385.9z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="s-910ghayesah_x0a_" data-linestop="tfl-rail">
      <path
        fill="#FFFFFF"
        d="M141.9,469.4c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.8-1.2-2.8-2.7c0-1.5,1.2-2.8,2.8-2.8 C140.7,466.6,141.9,467.8,141.9,469.4z"
        className="white-fill"
      />
      <path d="M142.5,469.4c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3C141,466.1,142.5,467.5,142.5,469.4z M139.2,471.5c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C137,470.6,138,471.5,139.2,471.5z" />
    </g>
    <g id="s-910gwealing_2_" data-linestop="tfl-rail">
      <path
        fill="#FFFFFF"
        d="M199.7,444.8c0,1.5-1.2,2.7-2.7,2.7c-1.5,0-2.8-1.2-2.8-2.7c0-1.5,1.2-2.8,2.8-2.8 C198.5,442.1,199.7,443.3,199.7,444.8z"
        className="white-fill"
      />
      <path d="M200.3,444.8c0,1.8-1.5,3.3-3.3,3.3c-1.8,0-3.3-1.5-3.3-3.3c0-1.8,1.5-3.3,3.3-3.3C198.8,441.5,200.3,443,200.3,444.8z M197,447c1.2,0,2.2-1,2.2-2.2c0-1.2-1-2.2-2.2-2.2c-1.2,0-2.2,1-2.2,2.2C194.8,446,195.8,447,197,447z" />
    </g>
    <g id="lul-central_lul-district_940gzzlueby_tfl-rail_910gealingb_xa0__1_">
      <g id="lul-central_940gzzlusbc_raillo-overground_910gshpdsb_4_">
        <path d="M213,447.8l0,2.6c-1,0.6-1.7,1.7-1.7,3c0,1.9,1.5,3.4,3.4,3.4c1.9,0,3.4-1.5,3.4-3.4c0-1.3-0.7-2.4-1.7-3v-2.6 c1-0.6,1.7-1.7,1.7-3c0-1.9-1.5-3.4-3.4-3.4c-1.9,0-3.4,1.5-3.4,3.4C211.3,446.1,212,447.2,213,447.8z" />
      </g>
      <path
        id="tfl-rail_910gealingb_xa0_"
        d="M214.7,441.4c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C211.3,442.9,212.9,441.4,214.7,441.4z"
      />
      <path
        id="lul-central_lul-district_940gzzlueby"
        d="M214.7,449.9c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4 c-1.9,0-3.4-1.5-3.4-3.4C211.3,451.4,212.9,449.9,214.7,449.9z"
      />
      <path
        id="lul-central_lul-district_940gzzlueby_tfl-rail_910gealingb_xa0_"
        fill="#FFFFFF"
        d="M212.5,444.8 c0-1.3,1-2.3,2.3-2.3c1.3,0,2.3,1,2.3,2.3c0,1.1-0.7,1.9-1.7,2.2v4.1c1,0.3,1.7,1.1,1.7,2.2c0,1.3-1,2.3-2.3,2.3 c-1.3,0-2.3-1-2.3-2.3c0-1.1,0.7-1.9,1.7-2.1l0-4.1C213.2,446.8,212.5,445.9,212.5,444.8z"
        className="white-fill"
      />
    </g>
    <g id="lul-victoria_940gzzlublr_raillo-overground_910gblchsrd_1_">
      <g id="lul-victoria_940gzzlublr_raillo-overground_910gblchsrd_2_">
        <g id="lul-victoria_940gzzlublr_raillo-overground_910gblchsrd_8_">
          <path d="M753.9,259.7l-0.7,0.7c-1.1-0.3-2.4,0-3.3,0.9c-1.3,1.3-1.3,3.5,0,4.8c1.3,1.3,3.5,1.3,4.8,0c0.9-0.9,1.2-2.2,0.9-3.3 l0.7-0.7c1.1,0.3,2.4,0,3.3-0.9c1.3-1.3,1.3-3.5,0-4.8c-1.3-1.3-3.5-1.3-4.8,0C753.9,257.3,753.6,258.6,753.9,259.7z" />
        </g>
        <path
          id="lul-victoria_940gzzlublr_2_"
          d="M749.9,266.1c-1.3-1.3-1.3-3.5,0-4.8c1.3-1.3,3.5-1.3,4.8,0c1.3,1.3,1.3,3.5,0,4.8 C753.4,267.4,751.3,267.4,749.9,266.1z"
        />
        <path
          id="lul-victoria_940gzzlublr_raillo-overground_910gblchsrd_3_"
          fill="#FFFFFF"
          d="M755.6,257.2 c0.9-0.9,2.3-0.9,3.2,0c0.9,0.9,0.9,2.3,0,3.2c-0.8,0.8-1.9,0.9-2.8,0.3l-1.7,1.7c0.5,0.9,0.4,2-0.3,2.8c-0.9,0.9-2.3,0.9-3.2,0 c-0.9-0.9-0.9-2.3,0-3.2c0.8-0.8,1.9-0.9,2.8-0.3l1.7-1.7C754.7,259.1,754.8,258,755.6,257.2z"
          className="white-fill"
        />
      </g>
      <g id="s-910gblchsrd_2_" data-linestop="london-overground">
        <g>
          <path
            fill="#1C3F94"
            d="M757.2,255.4c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C753.8,256.9,755.3,255.4,757.2,255.4z"
            className="blue-fill"
          />
          <path
            fill="#FFFFFF"
            d="M757.2,261.9c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1 C760.3,260.5,758.9,261.9,757.2,261.9L757.2,261.9z"
            className="white-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#1C3F94"
              d="M756.1,259.8c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L756.1,259.8z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M757.3,256.6c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C757.5,256,757.3,256.3,757.3,256.6z"
              className="blue-fill"
            />
            <path
              fill="#1C3F94"
              d="M757.5,257.4l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H756c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C757.6,257.2,757.5,257.4,757.5,257.4z"
              className="blue-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-district_940gzzluwim_tram-tram_940gzzcrwmb">
      <path
        id="lul-district_940gzzluwim_tram-tram_940gzzcrwmb_2_"
        d="M382.3,641.7l-2.4-2.4c0.3-1.1,0-2.4-0.9-3.3 c-1.3-1.3-3.5-1.3-4.8,0c-1.3,1.3-1.3,3.5,0,4.8c0.9,0.9,2.2,1.2,3.3,0.9l2.4,2.4c-0.3,1.1,0,2.4,0.9,3.3c1.3,1.3,3.5,1.3,4.8,0 c1.3-1.3,1.3-3.5,0-4.8C384.7,641.7,383.4,641.4,382.3,641.7z"
      />
      <path
        id="lul-district_940gzzluwim_tram-tram_940gzzcrwmb_1_"
        fill="#FFFFFF"
        d="M384.8,643.4c0.9,0.9,0.9,2.3,0,3.2 c-0.9,0.9-2.3,0.9-3.2,0c-0.7-0.7-0.9-1.9-0.3-2.8l-3.4-3.4c-0.9,0.5-2,0.4-2.8-0.3c-0.9-0.9-0.9-2.3,0-3.2c0.9-0.9,2.3-0.9,3.2,0 c0.7,0.7,0.9,1.9,0.3,2.8l3.4,3.4C382.9,642.5,384,642.6,384.8,643.4z"
        className="white-fill"
      />
      <g id="s-940gzzcrwmb" data-linestop="tram">
        <g>
          <path
            fill="#1C3F94"
            d="M383.2,641.6c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C379.8,643.1,381.3,641.6,383.2,641.6z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M382,646c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L382,646z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M383.2,642.7c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C383.4,642.2,383.2,642.4,383.2,642.7z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M383.5,643.6l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C383.6,643.4,383.5,643.6,383.5,643.6z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
      <g id="s-940gzzluwim" data-linestop="district">
        <g>
          <path
            fill="#1C3F94"
            d="M376.6,635c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C373.2,636.6,374.8,635,376.6,635z"
            className="blue-fill"
          />
        </g>
        <g>
          <g>
            <path
              fill="#FFFFFF"
              d="M375.5,639.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.8L375.5,639.4z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M376.7,636.2c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C376.9,635.7,376.7,635.9,376.7,636.2z"
              className="white-fill"
            />
            <path
              fill="#FFFFFF"
              d="M377,637.1l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C377,636.9,377,637.1,377,637.1z"
              className="white-fill"
            />
          </g>
        </g>
      </g>
    </g>
    <g id="lul-district_lul-hammersmith-city_940gzzlubbb">
      <g>
        <path
          fill="#1C3F94"
          d="M833.1,394.7c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C829.7,396.2,831.2,394.7,833.1,394.7z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M833.1,401.2c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C836.2,399.8,834.8,401.2,833.1,401.2L833.1,401.2z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M832,399.1c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L832,399.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M833.2,395.8c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C833.4,395.3,833.2,395.6,833.2,395.8z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M833.4,396.7l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3h-1.4c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0 l1.3,0c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C833.5,396.5,833.4,396.7,833.4,396.7z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
    <g id="lul-central_940gzzlubkh">
      <g>
        <path
          fill="#1C3F94"
          d="M843.2,177c1.9,0,3.4,1.5,3.4,3.4c0,1.9-1.5,3.4-3.4,3.4c-1.9,0-3.4-1.5-3.4-3.4 C839.8,178.5,841.3,177,843.2,177z"
          className="blue-fill"
        />
        <path
          fill="#FFFFFF"
          d="M843.2,183.4c-1.7,0-3.1-1.4-3.1-3.1c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1 C846.3,182.1,844.9,183.4,843.2,183.4L843.2,183.4z"
          className="white-fill"
        />
      </g>
      <g>
        <g>
          <path
            fill="#1C3F94"
            d="M842,181.4c0.2,0.5,0.7,0.9,1.2,0.9c0.7,0,1.3-0.6,1.3-1.3c0-0.4-0.2-0.7-0.4-1l0.1-0.6 c0.5,0.3,0.8,0.9,0.8,1.5c0,1-0.8,1.8-1.8,1.8c-0.6,0-1.2-0.3-1.5-0.9L842,181.4z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M843.3,178.1c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5 C843.5,177.6,843.3,177.8,843.3,178.1z"
            className="blue-fill"
          />
          <path
            fill="#1C3F94"
            d="M843.5,179l-0.1,0.4h-1.1c0,0-0.2,0-0.2,0.2c0,0.2,0.2,0.2,0.2,0.2h1l0,0.3H842c0,0-0.1,0-0.1,0.1 c0,0-0.1,0.1-0.1,0.1l-0.8,1.6c0,0-0.1,0.2,0.1,0.3c0.2,0.1,0.4-0.1,0.4-0.1l0.6-1.2c0,0,0-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0l1.3,0 c0,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.2,0.1-0.2l0.2-1.4c0,0,0-0.3-0.3-0.3C843.6,178.8,843.5,179,843.5,179z"
            className="blue-fill"
          />
        </g>
      </g>
    </g>
  </g>
)

export default interchangeCircles

import React from "react"

export default () => (
  <g id="river">
    <g id="river_1_">
      <path
        fill="#C7EAFB"
        d="M64.3,645.3l165.9,0c3,0,5.4-2.4,5.4-5.4l0-77.2c0-5.8,4.7-10.4,10.4-10.4l52.1,0c2.9,0,5.5,1.2,7.4,3 c1.9,1.9,34.8,34.8,35.8,35.7c1,1,2.3,1.6,3.8,1.6l130.4,0c2.2,0,3.9-1.8,3.9-3.9v-53.6c0-5.8,4.7-10.4,10.4-10.4l92,0 c1.1,0,2.1-0.5,2.8-1.2l27.1-26.3l1-1c2-2,4.7-3.2,7.6-3.2l119.7,0c6,0,10.9,4.9,10.9,10.9l0,69.6c0,1.8,1.4,3.2,3.2,3.2l47.7,0 c1.8,0,3.3-1.5,3.3-3.3l0-66.3c0-6.3,5.1-11.5,11.5-11.5l54.2,0c7,0,12.6,5.7,12.6,12.6v55.2c0,1.6,1.3,2.8,2.8,2.8l163,0v9.1 l-160.9,0c-7.3,0-13.3-5.9-13.3-13.3l0-55.1c0-2-1.6-3.7-3.7-3.7h-55.2c-1.8,0-3.3,1.5-3.3,3.3l0,66.3c0,6.3-5.1,11.5-11.5,11.5 l-47.3,0c-6,0-10.9-4.9-10.9-10.9v-69.9c0-2.3-1.9-4.2-4.1-4.2l-118.8,0c-1.1,0-2.1,0.5-2.8,1.2l-28,27.3c-2,2-4.7,3.2-7.6,3.2 l-91.8,0c-2.3,0-4.2,1.9-4.2,4.2l0,52.1c0,5.8-4.7,10.4-10.4,10.4l-130.6,0c-2.9,0-5.5-1.2-7.4-3.1c-1.9-1.9-34.8-34.8-35.8-35.8 c-1-1-2.3-1.6-3.8-1.6l-51.8,0.1c-3,0-5.4,2.4-5.4,5.4l0,77.2c0,5.8-4.7,10.4-10.4,10.4h-166V645.3"
      />
      <path
        fill="none"
        stroke="#00AEEF"
        strokeWidth="0.26"
        d="M1049.4,575.2l-160.8,0c-7.3,0-13.3-5.9-13.3-13.3l0-55.1 c0-2-1.6-3.7-3.7-3.7h-55.2c-1.8,0-3.3,1.5-3.3,3.3l0,66.3c0,6.3-5.1,11.5-11.5,11.5l-47.3,0c-6,0-10.9-4.9-10.9-10.9v-69.9 c0-2.3-1.9-4.2-4.1-4.2l-118.8,0c-1.1,0-2.1,0.5-2.8,1.2l-28,27.3c-2,2-4.7,3.2-7.6,3.2l-91.8,0c-2.3,0-4.2,1.9-4.2,4.2l0,52.1 c0,5.8-4.7,10.4-10.4,10.4l-130.6,0c-2.9,0-5.5-1.2-7.4-3.1c-1.9-1.9-34.8-34.8-35.8-35.8c-1-1-2.3-1.6-3.8-1.6l-51.8,0.1 c-3,0-5.4,2.4-5.4,5.4l0,77.2c0,5.8-4.7,10.4-10.4,10.4H64.3"
      />
      <path
        fill="none"
        stroke="#00AEEF"
        strokeWidth="0.26"
        d="M64.3,645.3l165.9,0c3,0,5.4-2.4,5.4-5.4l0-77.2 c0-5.8,4.7-10.4,10.4-10.4l52.1,0c2.9,0,5.5,1.2,7.4,3c1.9,1.9,34.8,34.8,35.8,35.7c1,1,2.3,1.6,3.8,1.6l130.4,0 c2.2,0,3.9-1.8,3.9-3.9v-53.6c0-5.8,4.7-10.4,10.4-10.4l92,0c1.1,0,2.1-0.5,2.8-1.2l27.1-26.3l1-1c2-2,4.7-3.2,7.6-3.2l119.7,0 c6,0,10.9,4.9,10.9,10.9l0,69.6c0,1.8,1.4,3.2,3.2,3.2l47.7,0c1.8,0,3.3-1.5,3.3-3.3l0-66.3c0-6.3,5.1-11.5,11.5-11.5l54.2,0 c7,0,12.6,5.7,12.6,12.6v55.2c0,1.6,1.3,2.8,2.8,2.8l162.9,0"
      />
      <text transform="matrix(0.9995 0 0 1 657.1689 497.4649)">
        <tspan x="0" y="0" fill="#00B3F0">
          Ri
        </tspan>
        <tspan x="3.4" y="0" fill="#00B3F0">
          v
        </tspan>
        <tspan x="5.4" y="0" fill="#00B3F0">
          er Thames
        </tspan>
      </text>
    </g>
  </g>
)
